import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "../sidebar/Sidebar";
// styles
import { useUserState } from "../../context/userContext";
import styles from "./styles";
import classNames from "classnames";
import Header from "../header/Header";

function Layout(props) {
  var classes = styles();

  // global
  const isSidebarOpened = useSelector(state => state.sidebarToggleState);
  var { isAuthenticated } = useUserState();

  const mainContent = isAuthenticated ? (
    <main className={classNames(classes.content, {
      [classes.contentShift]: isSidebarOpened
    })}>
      <div className={classes.fakeToolbar} />
      {props.children}
    </main>) : (
    <main className={classes.content}>
      {props.children}
    </main>
  );
  return (
    <div className={classes.root}>
      <>
        {isAuthenticated &&
          <>
            <Header />
            <Sidebar />
          </>
        }
        {mainContent}
      </>
    </div>
  );
}

export default Layout;
