import { CreditCard, Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { CASTE_CODES, FEMALE, MALE, NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { AppMisc } from "../../../../../misc/appMisc";
import { NEW_PATIENT, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";
import InfiniteScroll from "react-infinite-scroll-component";

const TherapeuticYogaSewaRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef}  >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={3}>क्र.सं.</TableCell>
                <TableCell rowSpan={3}>दर्ता मिति</TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={3}>जाती कोड</TableCell>
                </Tooltip>
                <TableCell colSpan={2}>उमेर</TableCell>
                <TableCell colSpan={3}>ठेगाना</TableCell>
                <TableCell rowSpan={3}>सम्पर्क नम्बर</TableCell>
                <TableCell colSpan={2}>प्रेषण भई</TableCell>
                <TableCell colSpan={6}>स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={18} >योग </TableCell>
                <TableCell rowSpan={3} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>पहिलो पटक</TableCell>
                <TableCell rowSpan={2}>थप पटक</TableCell>
                <TableCell rowSpan={2}>नाम</TableCell>
                <TableCell rowSpan={2}>थर</TableCell>
                <TableCell rowSpan={2}>महिला</TableCell>
                <TableCell rowSpan={2}>पुरुष</TableCell>
                <TableCell rowSpan={2}>जिल्ला</TableCell>
                <TableCell rowSpan={2}>नगर/गाउँपालिका</TableCell>
                <TableCell rowSpan={2}>वडा नं.</TableCell>
                <TableCell rowSpan={2}>आएको संस्थाको नाम</TableCell>
                <TableCell rowSpan={2}>गएको संस्थाको नाम</TableCell>
                <TableCell rowSpan={2}>रक्तचाप</TableCell>
                <TableCell rowSpan={2}>प्रकृति</TableCell>
                <TableCell rowSpan={2}>तौल</TableCell>
                <TableCell rowSpan={2}>रोग</TableCell>
                <TableCell rowSpan={2}>FBS</TableCell>
                <TableCell rowSpan={2}>परीक्षण</TableCell>
                <TableCell colSpan={3}>सुक्ष्म व्यायाम</TableCell>
                <TableCell colSpan={3}>आसन</TableCell>
                <TableCell colSpan={3}>प्राणायाम</TableCell>
                <TableCell colSpan={3}>षट्कर्म</TableCell>
                <TableCell colSpan={3}>ध्यान</TableCell>
                <TableCell colSpan={3}>अन्य</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>सुरू मिति</TableCell>
                <TableCell>पूरा गरेको मिति</TableCell>
                <TableCell>जटिलता</TableCell>
                <TableCell>सुरू मिति</TableCell>
                <TableCell>पूरा गरेको मिति</TableCell>
                <TableCell>जटिलता</TableCell>
                <TableCell>सुरू मिति</TableCell>
                <TableCell>पूरा गरेको मिति</TableCell>
                <TableCell>जटिलता</TableCell>
                <TableCell>सुरू मिति</TableCell>
                <TableCell>पूरा गरेको मिति</TableCell>
                <TableCell>जटिलता</TableCell>
                <TableCell>सुरू मिति</TableCell>
                <TableCell>पूरा गरेको मिति</TableCell>
                <TableCell>जटिलता</TableCell>
                <TableCell>सुरू मिति</TableCell>
                <TableCell>पूरा गरेको मिति</TableCell>
                <TableCell>जटिलता</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {
                mainRegisterData?.length > 0 ? (
                  mainRegisterData?.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                      <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                      <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                      <TableCell>{row?.patientFirstName || "-"}</TableCell>
                      <TableCell>{row?.patientLastName || "-"}</TableCell>
                      <TableCell>{row?.castCode && AppUtils.replaceWithNepaliDigit(row.castCode) || "-"}</TableCell>
                      <TableCell>{row?.gender === FEMALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                      <TableCell>{row?.gender === MALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                      <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                      <TableCell>{row?.palikaName && AppMisc.getMunicipalityName(row?.palikaName)}</TableCell>
                      <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                      <TableCell>{row?.phoneNumber && AppUtils.replaceWithNepaliDigit(row?.phoneNumber)}</TableCell>
                      <TableCell>{row.sentFromOrganizationName || "-"}</TableCell>
                      <TableCell>{row.nameInstitutionAttended || "-"}</TableCell>
                      <TableCell>{row.bloodPressure || "-"}</TableCell>
                      <TableCell>{row.nature || "-"}</TableCell>
                      <TableCell>{row.weight || "-"}</TableCell>
                      <TableCell>{row.disease || "-"}</TableCell>
                      <TableCell>{row.fbs || "-"}</TableCell>
                      <TableCell>{row.test || "-"}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.gentleExerciseStartDate)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.gentleExerciseEndDate)}</TableCell>
                      <TableCell>{row.gentleExerciseComplexity || "-"}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.postureStartDate)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.postureEndDate)}</TableCell>
                      <TableCell>{row.postureComplexity || "-"}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.pranayamaStartDate)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.pranayamaEndDate)}</TableCell>
                      <TableCell>{row.pranayamaComplexity || "-"}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.shatkarmaStartDate)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.shatkarmaEndDate)}</TableCell>
                      <TableCell>{row.shatkarmaComplexity || "-"}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.dhyanStartDate)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.dhyanEndDate)}</TableCell>
                      <TableCell>{row.complexityDhyan || "-"}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.otherStartDate)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row.otherendDate)}</TableCell>
                      <TableCell>{row.complexityOther || "-"}</TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <IconButton aria-label="edit">
                            <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                          </IconButton>
                          <Tooltip title="स्वास्थ्य सेवा कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <CreditCard fontSize="small" />
                          </Tooltip>
                          <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <IconButton >
                              <Delete fontSize="small" color="secondary" onClick={() => props.deleteRow(row.id)} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="41" size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer >
      </InfiniteScroll>
    </>
  )
}

export default TherapeuticYogaSewaRegister;