import { Card } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const ChartDataBySewaType = () => {
  const chartOptions = {
    chart: {
      type: "pie",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    labels: ["सामान्य उपचार", "पञ्चकर्म (पूर्वकर्म/उपक्रम) सेवा", "शल्य सेवा ", "योग सेवा", "जेष्ठ नागरिक सेवा", "स्तनपायी आमा तथा मातृशिशु सेवा", "फिजियोथेरापी सेवा", "होमियोप्याथी सेवा", "प्राकृतिक चिकित्सा सेवा"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        monochrome: {
          enabled: true,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
        fontSize: '15px',
      },
    },
    tooltip: {
      enabled: true,
    },
    title: {
      text: "सेवाको प्रकारअनुसार चालु आ. व. को जम्मा सेवाग्राहिहरु",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: "bottom",
        }
      }
    }],
  };

  const seriesData = [10, 10, 10, 9, 11, 10, 4, 16, 10];

  return (
    <>
      <Card>
        <Chart
          options={chartOptions}
          series={seriesData}
          type="pie"
          height={700}
        />
      </Card>
    </>
  );
};

export default ChartDataBySewaType;