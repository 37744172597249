
import { makeStyles } from '@mui/styles'

const styles = makeStyles(theme => ({
  pendingPatient: {
    borderRadius: "20px !important",
    margin: "5px !important",
    textTransform: "lowercase!important"
  },
  showMore: {
    position: "absolute",
    zIndex: "2",
    display: "flex",
    backgroundColor: "#FFFFFF",
    width: "84.8%",
  },
  pendingPatientType: {
    color: "#000000 !important",
    borderRadius: "20px !important",
    margin: "10px !important",
    textTransform: "lowercase!important"
  }
}));
export default styles;