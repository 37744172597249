import { Box, Button } from '@mui/material';
import React, { useState } from 'react'
import { AppUtils } from '../../utils/appUtils';
import { Add } from '@mui/icons-material';
import styles from "./style";

const PendingPatientList = ({ pendingPatientList, showModal, modalDefaultValues }) => {

  const classes = styles()
  const [viewAll, setViewAll] = useState(false);

  const handleShowClick = () => {
    setViewAll(!viewAll);
  }

  const handleApprovedStatus = (id) => {
    showModal(true)
    let patientDetail = { ...pendingPatientList.objectList?.find(obj => obj.id === id) };
    delete patientDetail.id;
    modalDefaultValues(patientDetail);
  }

  //TODO-Krishna: Dynamically assign displaySize based on screen size
  const displaySize = 8;
  const displayValue = `(${AppUtils.replaceWithNepaliDigit(pendingPatientList.size - displaySize > 0 ? pendingPatientList.size - displaySize : 0)})`;
  return (
    <>
      {
        pendingPatientList.objectList?.slice(0, displaySize).map((pendingPatient, index) =>
          <>
            <Button variant="outlined" sx={{ color: "#000000" }} className={classes?.pendingPatient} onClick={() => handleApprovedStatus(pendingPatient.id)} key={index}>
              <Box>
                {pendingPatient.patientFirstName + " " + pendingPatient.patientLastName + " (" + AppUtils.replaceWithNepaliDigit(pendingPatient.mulDartaaNumber) + ")"}
              </Box>
            </Button>
          </>)
      }
      {
        displayValue > 0 && <Button variant="outlined" sx={{ color: "#000000" }} startIcon={<Add />} onClick={handleShowClick}>
          More:{displayValue}
        </Button>
      }
      {
        viewAll && (
          <Box className={classes.showMore}>
            {pendingPatientList.objectList?.slice(displaySize).map((pendingPatient, index) => (
              <Button
                variant="outlined"
                className={classes.pendingPatientType}
                onClick={() => handleApprovedStatus(pendingPatient.id)}
                key={index}
              >
                {pendingPatient.patientFirstName + " " + pendingPatient.patientLastName + " (" + AppUtils.replaceWithNepaliDigit(pendingPatient.mulDartaaNumber) + ")"}
              </Button>
            ))}
          </Box>
        )
      }
    </>
  )
}

export default PendingPatientList;