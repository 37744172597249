import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { HEALTH_ORGANIZATION_TYPES, REQUIRED_FIELD } from "../../../../utils/constants/index";
import styles from "./style";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import CustomReactSelect from "../../../../components/custom-react-select/CustomReactSelect";
import CustomModal from "../../../../components/modal/CustomModal";

export default function AddHealthClient({ pharmacyOptions, showAddHealthClientModal, handleAddHealthClientModalClose, onHealthClientModalSubmit, ...props }) {
  const classes = styles();
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  function closeAddHealthClientModal() {
    reset();
    handleAddHealthClientModalClose();
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    register("healthOrganizationType", { required: true });
  }, [register]);

  useEffect(() => {
    if (showAddHealthClientModal) {
      reset();
    }
  }, [showAddHealthClientModal, reset]);

  return (
    <>
      <CustomModal
        title="नयाँ स्वास्थ्य संस्था थप्नुहोस्।"
        onModalSubmit={handleSubmit(onHealthClientModalSubmit)}
        showModal={showAddHealthClientModal}
        onModalClose={closeAddHealthClientModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center" className={classes.row}>
          <Grid item xs={4}>
            <CustomReactSelect
              label="स्वास्थ्य संस्थाको प्रकार"
              size="small"
              name="healthOrganizationType"
              variant="outlined"
              options={HEALTH_ORGANIZATION_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={props.defaultValues?.healthOrganizationType}
              disabledOptionSelectable
              fullWidth
            />
            {errors?.healthOrganizationType && errors.healthOrganizationType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="स्वास्थ्य संस्थाको नाम"
              type="text"
              variant="outlined"
              size="small"
              name="name"
              defaultValue={props.defaultValues?.name}
              {...register("name", { required: true })}
              fullWidth
            />
            {errors.name && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="ठेगाना"
              type="text"
              size="small"
              variant="outlined"
              name="address"
              {...register("address", { required: true })}
              defaultValue={props.defaultValues?.address}
              fullWidth
            />
            {errors.address && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="ई-मेल"
              type="email"
              size="small"
              variant="outlined"
              name="email"
              {...register("email", { pattern: /\S+@\S+\.\S+/ })}
              defaultValue={props.defaultValues?.email}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="फोन नम्बर"
              type="text"
              variant="outlined"
              name="phoneNumber"
              size="small"
              {...register("phoneNumber")}
              defaultValue={props.defaultValues?.phoneNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              label="Enable SMS alert"
              control={
                <Checkbox
                  name="enableSMSAlert"
                  variant="outlined"
                  {...register("enableSMSAlert")}
                  color="primary"
                  defaultChecked={props.defaultValues?.enableSMSAlert}
                />
              }
            />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};