import { Box, Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import NepaliCalender from "../../../components/nepali-calender"
import GaugharSahariClinicNasarneRogaVyavasthapanSewaRegister from "../../users/components/register/gaughar-sahari-clinic-nasarne-roga-vyavasthapan-sewa-register/GaugharSahariClinicNasarneRogaVyavasthapanSewaRegister"

const GaugharSahariClinicNasarneRogaVyavasthapanSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("गाउँघर/शहरी नसर्ने रोग व्यवस्थापन सेवा रजिष्टर")
  const [showGaugharSahariClinicNasarneRogVyavasthapan, setshowGaugharSahariClinicNasarneRogVyavasthapan] = useState(false)

  const openGaugharSahariClinicNasarneRogVyavasthapanModal = () => {
    setshowGaugharSahariClinicNasarneRogVyavasthapan(true)
  }

  const closeGaugharSahariClinicNasarneRogVyavasthapanModal = () => {
    setshowGaugharSahariClinicNasarneRogVyavasthapan(false)
  }

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="गाउँघर/शहरी नसर्ने रोग व्यवस्थापन सेवा रजिष्टर"
          handleModalOpen={openGaugharSahariClinicNasarneRogVyavasthapanModal}
          // getRegisterData={getMulDartaaData}
          // onHeaderParamChange={onHeaderParamChange}
          // getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showGaugharSahariClinicNasarneRogVyavasthapan}
        // onModalSubmit={handleSubmit}
        onModalClose={closeGaugharSahariClinicNasarneRogVyavasthapanModal}
        maxWidth="lg"
        fullScreen>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                showDefaultDate
                label="दर्ता मिति"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवाग्राहीको किसिम"
              name="patientType"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <CustomReactSelect
                label="दर्ता नम्बर"
                name="dartaaNumber"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="patientFirstName"
              autoComplete="on"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको थर"
              type="text"
              size="small"
              variant="outlined"
              name="patientLastName"
              autoComplete="on"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="date-of-birth-of-child"
                label="जन्म मिति"
                name="dateOfBirth"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="जाती"
              size="small"
              name="casteCode"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="जिल्ला"
              name="district"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="नगर/गाउँपालिका"
              name="palikaName"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="वडा नं"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="gaunOrTole"
              label="गाँउ/टोल"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सम्पर्क नम्बर"
              size="small"
              variant="outlined"
              name="phoneNumber"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="तौल केजी"
              size="serviceStart"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="रक्तचाप"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <NepaliCalender
              name="BS"
              showDefaultDate
              label="BS"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रकृती"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="समस्या"
              size="small"
              variant="outlined"
              name="causes"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="सेवन गरिरहेको औषधी"
              size="small"
              variant="outlined"
              name="edicinestaking"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="रोग/निदान"
              size="small"
              variant="outlined"
              name="disease"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="वितरण गरेको औषधी"
              size="small"
              variant="outlined"
              name="medicinesdispensed"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="परामर्श/ रेफर"
              size="small"
              variant="outlined"
              name="consult"
              autoComplete="true"
              fullWidth
            />
          </Grid>
        </Grid>
      </CustomModal>
      <GaugharSahariClinicNasarneRogaVyavasthapanSewaRegister />
    </>
  )
}

export default GaugharSahariClinicNasarneRogaVyavasthapanSewaForm