import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const AvailableServices = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Box mt={4}>
        <Typography variant="body1" className="report-heading">उपलब्ध सेवा</Typography>
        </Box>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Available-Services">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell>क्रसं</TableCell>
              <TableCell>उपलब्ध सेवा</TableCell>
              <TableCell>कोड</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>पञ्चकर्म .(पूर्वकर्म/उपक्रम) सेवा</TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>पञ्चकर्म (प्रधानकर्म) सेवा</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>क्षारसुत्र सेवा</TableCell>
              <TableCell>3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4</TableCell>
              <TableCell>उपचारात्मक योग  सेवा</TableCell>
              <TableCell>4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell>होमियोप्याथी  सेवा</TableCell>
              <TableCell>5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6</TableCell>
              <TableCell>एकुपञ्चर  सेवा</TableCell>
              <TableCell>6</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>7</TableCell>
              <TableCell>नेचुरोप्याथी</TableCell>
              <TableCell>7</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>8</TableCell>
              <TableCell>सोरप्पा (आम्ची)</TableCell>
              <TableCell>8</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AvailableServices;