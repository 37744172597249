import { makeStyles } from "@mui/styles";
import { LEFT_SIDEBAR_WIDTH as drawerWidth } from "../../utils/constants/index";

const styles = makeStyles(theme => ({
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(4.5) + 2,
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(4.5) + 2,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
  }
}));

export default styles;