import { LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_USER_PERMISSIONS } from "../utils/constants/redux";

const reducer = (permissions = null, action) => {
  switch (action.type) {
    case SAVE_USER_PERMISSIONS:
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return permissions;
  }
}

export default reducer;