import React, { useEffect, useState } from "react";
import { ExitToApp, Visibility, VisibilityOff } from "@mui/icons-material";
import Spinner from "../../../components/loader/Loader";
// context
import { useUserDispatch } from "../../../context/userContext";
import styles from "./style";
import AddAlertMessage from "../../../components/alert/Alert";
import { ENTER_VALID_EMAIL, ERROR, IS_SESSION_EXPIRED, LOGIN_FAILURE, LOGIN_SUCCESS, PERMISSIONS, REQUIRED_FIELD, SESSION_EXPIRED, SOMETHING_WENT_WRONG, SUCCESS, USER_ROLE } from "../../../utils/constants";
import AHMIS, { API_URL } from "../../../api/api";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import { Box, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, IconButton, InputAdornment, Link, TextField } from "@mui/material";
import { Cookies } from "../../../utils/storage/cookies";
import { LocalStorage } from "../../../utils/storage/localStorage";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";

export default function LoginForm(props) {
  const classes = styles();
  const navigate = useNavigate()
  //  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false)

  // global
  var userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (SessionStorage.getItem(IS_SESSION_EXPIRED) === "true") {
      AddAlertMessage({
        type: ERROR,
        message: SESSION_EXPIRED
      });
      SessionStorage.removeItem(IS_SESSION_EXPIRED);
    }
  }, []);

  function afterLoginResponse(response) {
    let jsondata = response.data;
    if (jsondata.type === SUCCESS) {
      let userRole = Cookies.readCookie(USER_ROLE);
      if (jsondata.data) {
        !userRole && Cookies.writeCookie(USER_ROLE, jsondata.data.role, 6 * 24);
        LocalStorage.setItem(PERMISSIONS, jsondata.data.permissions);
        // dispatch({ type: SAVE_USER_PERMISSIONS, payload: jsondata.data.permissions });
      }
      userDispatch({ type: LOGIN_SUCCESS });
      navigate("/")
    } else {
      userDispatch({ type: LOGIN_FAILURE });
      AddAlertMessage({ type: jsondata.type, message: jsondata.message });
    }
  }

  const onSubmit = data => {
    setIsLoading(true);
    data.userEmail = data?.userEmail?.toLowerCase().trim();
    AHMIS.post(API_URL.login, data)
      .then(response => {
        setIsLoading(false);
        afterLoginResponse(response);
      })
      .catch(error => {
        setIsLoading(false);
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        userDispatch({ type: LOGIN_FAILURE });
      });
  };

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box textAlign="center" my={3}>
        {/* <img src={logo} alt="HMIS Logo" width="124" /> */}
        <Box fontSize="h5.fontSize">AAMIS-NEPAL</Box>
        <Box component="small">Ayurveda and Alternative Medicine Information System</Box>
      </Box>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="userEmail"
                label="ईमेल"
                margin="normal"
                variant="outlined"
                name="userEmail"
                // inputRef={register({
                //   required: true,
                //   pattern: /\S+@\S+\.\S+/
                // })}
                {...register("userEmail", { required: true, pattern: /\S+@\S+\.\S+/ })}
              />
              {errors.userEmail && errors.userEmail.type === "required" && (<span className="error-message">{REQUIRED_FIELD}</span>)}
              {errors.userEmail && errors.userEmail.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                label="पासवर्ड"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                name="password"
                {...register("password", { required: true })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.password && (
                <span className="error-message">{REQUIRED_FIELD}</span>
              )}
            </Grid>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="मलाई सम्झनुहोस्।"
              name="rememberMe"
            // inputRef={register}
            />
            <Box className={classes.loginBtnContainer}>
              <Grid item xs={12}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    endIcon={<ExitToApp />}
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    साइन इन गर्नुहोस्
                  </Button>
                )}
              </Grid>
            </Box>
          </form>
        </CardContent>
      </Card>
      <Box align="right" pt={1}>
        <Link
          href="/forget-password"
          underline="none"
          color="textSecondary"
          className={classes["forget-password-label"]}
        >
          पासवर्ड बिर्सनु भयो ?
        </Link>
      </Box>
    </Container>
  );
}