import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import AHMIS, { API_URL } from '../../../api/api';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, YES_NO_OPTIONS } from '../../../utils/constants';
import { useForm } from 'react-hook-form';
import { AppMisc } from '../../../misc/appMisc';
import { DateUtils } from '../../../utils/dateUtils';
import CustomModal from '../../../components/modal/CustomModal';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import JesthaNagrikHealthSewaRegister from '../../users/components/register/jestha-nagrik-health-sewa-register/JesthaNagrikHealthSewaRegister';

const JesthaNagrikHealthSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("बहिरङ्ग सेवा रजिष्टरमा नयाँ रेकर्ड थप्नुहोस्।");
  const [showJesthNagrikHealthSewaModal, setShowJesthNagrikHealthSewaModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState();
  const [jesthaNagrikHealthSewaDataTable, setJesthaNagrikHealthSewaDataTable] = useState([]);
  const [showJesthaNagrikHealthSewaDeleteModal, setShowJesthaNagrikHealthSewaDeleteModal] = useState(false);
  const [jesthaNagrikHealthSewaDeleteId, setJesthaNagrikHealthSewaDeleteId] = useState(null);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);

  useEffect(() => {
    showJesthNagrikHealthSewaModal && reset();
  }, [reset, showJesthNagrikHealthSewaModal])

  const openJesthNagrikHealthSewaModal = () => {
    setShowJesthNagrikHealthSewaModal(true);
  }

  const closeJesthNagrikHealthSewaModal = () => {
    reset();
    setShowJesthNagrikHealthSewaModal(false);
    setModalDefaultValues()
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    // setValue(name, dateInMilli);  
  }

  const submitJesthaNagrikHealthSewaForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.outPatient, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeJesthNagrikHealthSewaModal()
          setGetTableData(true)
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const getJesthNagrikHealthSewaData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.outPatient}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setJesthaNagrikHealthSewaDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const jesthaNagrikHealthSewaEdit = (id) => {
    AHMIS.get(API_URL.outPatient + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data);
        setShowJesthNagrikHealthSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeJesthaNagrikHealthSewaDeleteModal = () => {
    setShowJesthaNagrikHealthSewaDeleteModal(false);
    setJesthaNagrikHealthSewaDeleteId(null);
  }

  const onDeleteJesthaNagrikHealthSewaRow = (id) => {
    setShowJesthaNagrikHealthSewaDeleteModal(true);
    setJesthaNagrikHealthSewaDeleteId(id);
  }

  const deleteJesthNagrikHealthSewaRow = (data) => {
    AHMIS.delete(API_URL.outPatient + "/" + jesthaNagrikHealthSewaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowJesthaNagrikHealthSewaDeleteModal(false);
          setJesthaNagrikHealthSewaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getJesthNagrikHealthSewaData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])
  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="ज्येष्ठ नागरिक स्वास्थ्य सेवा कार्यक्रम रजिष्टर"
          handleModalOpen={openJesthNagrikHealthSewaModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getJesthNagrikHealthSewaData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showJesthNagrikHealthSewaModal}
        onModalSubmit={handleSubmit(submitJesthaNagrikHealthSewaForm)}
        onModalClose={closeJesthNagrikHealthSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="परिचय पत्र"
              name="parichayaPatra"
              onChange={handleCustomReactSelectChange}
              options={YES_NO_OPTIONS}
              defaultValue={modalDefaultValues?.parichayaPatra}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="रोकको किसिम "
              name="rokkoKisim"
              onChange={handleCustomReactSelectChange}
              options={YES_NO_OPTIONS}
              defaultValue={modalDefaultValues?.rokkoKisim}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="विभाक"
              name="vibhaak"
              onChange={handleCustomReactSelectChange}
              options={YES_NO_OPTIONS}
              defaultValue={modalDefaultValues?.vibhaak}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवा"
              name="sewa"
              onChange={handleCustomReactSelectChange}
              options={YES_NO_OPTIONS}
              defaultValue={modalDefaultValues?.sewa}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="औषधी"
              size="small"
              variant="outlined"
              name="medicine"
              autoComplete="true"
              defaultValue={modalDefaultValues?.medicine}
              fullWidth
              {...register("medicine", { required: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              defaultValue={modalDefaultValues?.test}
              fullWidth
              {...register("test")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="सल्यकिरिया"
              size="small"
              variant="outlined"
              name="salyakiriyaa"
              autoComplete="true"
              defaultValue={modalDefaultValues?.salyakiriyaa}
              fullWidth
              {...register("salyakiriyaa")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="अन्य खुलाहुने "
              size="small"
              variant="outlined"
              name="anyaKhulaahune"
              autoComplete="true"
              defaultValue={modalDefaultValues?.anyaKhulaahune}
              fullWidth
              {...register("anyaKhulaahune")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="नि सुल्क "
              size="small"
              variant="outlined"
              name="niSulka"
              autoComplete="true"
              defaultValue={modalDefaultValues?.niSulka}
              fullWidth
              {...register("niSulka")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="छुट रु"
              size="small"
              variant="outlined"
              name="chhuTa"
              autoComplete="true"
              defaultValue={modalDefaultValues?.chhuTa}
              fullWidth
              {...register("chhuTa")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="स्वास्थ्य बिमा"
              size="small"
              variant="outlined"
              name="healthInsurance"
              autoComplete="true"
              defaultValue={modalDefaultValues?.healthInsurance}
              fullWidth
              {...register("healthInsurance")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="जेष्ठ नगरिक  कार्यक्रम"
              size="small"
              variant="outlined"
              name="jesthaNagarikProgram"
              autoComplete="true"
              defaultValue={modalDefaultValues?.jesthaNagarikProgram}
              fullWidth
              {...register("jesthaNagarikProgram")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="अन्य खुलाहुने"
              size="small"
              variant="outlined"
              name="anyaKhulaahunee"
              autoComplete="true"
              defaultValue={modalDefaultValues?.anyaKhulaahunee}
              fullWidth
              {...register("anyaKhulaahunee")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="माथिलो तहको अस्पताल"
              size="small"
              variant="outlined"
              name="maathiloTahakoHospital "
              autoComplete="true"
              defaultValue={modalDefaultValues?.maathiloTahakoHospital}
              fullWidth
              {...register("maathiloTahakoHospital")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="तल्लो  तहको अस्पताल "
              size="small"
              variant="outlined"
              name="talloTahakoHospital "
              autoComplete="true"
              defaultValue={modalDefaultValues?.talloTahakoHospital}
              fullWidth
              {...register("talloTahakoHospital")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="पुनर्स्थापना केन्द्र"
              size="small"
              variant="outlined"
              name="punarsthapanaKendra"
              autoComplete="true"
              defaultValue={modalDefaultValues?.punarsthapanaKendra}
              fullWidth
              {...register("punarsthapanaKendra")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="जेरियाट्रिक सेवा केन्द्र "
              size="small"
              variant="outlined"
              name="geriatricSewaKendra"
              autoComplete="true"
              defaultValue={modalDefaultValues?.geriatricSewaKendra}
              fullWidth
              {...register("geriatricSewaKendra")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showJesthaNagrikHealthSewaDeleteModal}
        closeDeleteModel={closeJesthaNagrikHealthSewaDeleteModal}
        deleteRecord={deleteJesthNagrikHealthSewaRow}
      />
      <JesthaNagrikHealthSewaRegister
        onEditRow={jesthaNagrikHealthSewaEdit}
        tableData={jesthaNagrikHealthSewaDataTable}
        deleteRow={onDeleteJesthaNagrikHealthSewaRow}
        handleChangePage={handleChangePage}
        page={page}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default JesthaNagrikHealthSewaForm
