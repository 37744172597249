import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { DateUtils } from '../../../utils/dateUtils';

const ChartDataByDailyPatient = () => {
  const [chartLabelsOfDailyPatient, setChartLabelsOfDailyPatient] = useState([]);

  const buildLastThirtyDaysChartData = () => {
    const labels = [];
    const values = [];
    for (let i = 29; i >= 0; i--) {
      const dateBeforeDays = DateUtils.getDateMilliseconds(DateUtils.getDaysBeforeBSDate(i));
      labels.push(DateUtils.getDateFromMilliseconds(dateBeforeDays));
      values.push([dateBeforeDays] || 0);
    }
    setChartLabelsOfDailyPatient(labels);
  }

  useEffect(() => {
    buildLastThirtyDaysChartData()
  }, [])

  const chartOptions = {
    chart: {
      type: 'area',
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'category',
      categories: chartLabelsOfDailyPatient,
      title: {
        text: "सेवाग्राही आएको मिति ",
      }
    },
    yaxis: {
      title: {
        text: "जम्मा सेवाग्राही संख्या"
      }
    },
    title: {
      text: "अन्तिम ३० दिनमा आएका सेवाग्राहीहरुको विवरण ",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    tooltip: {
      x: {

      },
    },
  };

  const seriesData = [
    {
      name: 'सेवाग्राहीहरुको',
      data: [31, 40, 28, 51, 42, 109, 100, 31, 40, 28, 51, 42, 109, 100, 31, 40, 28, 51, 42, 109, 100, 31, 40, 28, 51, 42, 109, 100, 3, 4],
    },
  ];

  return (
    <>
      <Card>
        <Chart
          options={chartOptions}
          series={seriesData}
          type="area"
          height={350}
        />
      </Card>
    </>
  );
};

export default ChartDataByDailyPatient;
