import { Add, Cancel } from "@mui/icons-material";
import { Box, Button, Divider, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import AHMIS, { API_URL } from "../../../api/api";
import AddUserModal from "../../../components/add-user/AddUserModal";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect";
import { AppUtils } from "../../../utils/appUtils";
import { ERROR, FILE_SIZE_EXCEED, FISCAL_YEARS, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import { SERVICES_AVAILABLE } from "../../../utils/constants/forms";
import { SAVE_FISCAL_YEAR } from "../../../utils/constants/redux";
import styles from "./style";

export default function UserSetting() {
  const classes = styles();
  const dispatch = useDispatch();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [defaultSetting, setDefaultSetting] = useState({});
  const { register, setValue, handleSubmit, errors, reset } = useForm();
  const [servicesAvailableValue, setServicesAvailableValue] = useState([]);
  const [aimedCountList, setAimedCountList] = useState([]);
  const [customError, setCustomError] = useState({});
  const [fiscalYearRepeatError, setFiscalYearRepeatError] = useState({});
  const role = AppUtils.getUserRole();

  const closeOpenAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  useEffect(() => {
    register("currentFiscalYear")
    register("availableServices")
  }, [register])

  const submitAddUserModal = data => {
    AHMIS.post(API_URL.user, data)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          closeOpenAddUserModal();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  useEffect(() => {
    reset(defaultSetting);
    if (defaultSetting && defaultSetting.availableServices) {
      setServicesAvailableValue(defaultSetting.availableServices);
    }
  }, [defaultSetting]);

  const drawFiscalYear = () => {
    AHMIS.get(API_URL.applicationSetting)
      .then(response => {
        let jsondata = response.data
        if (jsondata.type === SUCCESS) {
          setDefaultSetting(jsondata.data);
          dispatch({ type: SAVE_FISCAL_YEAR, payload: jsondata.data?.currentFiscalYear })
          buildAimedCountDetailsFromEdit(jsondata.data?.targetedServices || []);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const onFiscalYearSubmit = data => {
    data.id = defaultSetting?.id;
    let errorObject = {};
    aimedCountList.map((aimedCount, index) => {
      if (!Boolean(aimedCount[`fiscalYear~${index}`])) {
        Object.assign(errorObject, { [`fiscalYear~${index}`]: true })
      }
    })
    setCustomError(errorObject);
    if ((JSON.stringify(errorObject) === "{}") && JSON.stringify(fiscalYearRepeatError) === "{}") {
      data.availableServices = servicesAvailableValue;
      data.targetedServices = buildAimedCountDetailsForSubmit();

      AHMIS.post(API_URL.applicationSetting, data)
        .then(response => {
          AddAlertMessage({
            type: response.data.type,
            message: response.data.message
          });
          buildAimedCountDetailsFromEdit(data.targetedServices || [])
        })
        .catch(error => {
          AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        });
    }
  };

  const handleOnFiscalYearChange = currentFiscalYear => {
    setValue("currentFiscalYear", currentFiscalYear);
  };

  const handleAvailableServicesChange = (name, value) => {
    setServicesAvailableValue(value);
  };

  const handleAddAimedCountDetails = () => {
    const values = [...aimedCountList];
    values.push({});
    setAimedCountList(values);
  }

  const handleAimedCountDetailsChange = (index, event) => {
    let value = [...aimedCountList];
    value[index][event.target.name] = event.target.value;
    setAimedCountList(value);
  }

  const handleAimedCountFiscalYearChange = (index, fiscalYear) => {
    let value = [...aimedCountList];
    let fiscalYearRepeat = {};
    for (let countDetail in value) {
      if (index !== Number(countDetail)) {
        if (value[countDetail][`fiscalYear~${countDetail}`] === fiscalYear) {
          Object.assign(fiscalYearRepeat, { [`fiscalYear~${index}`]: true });
        }
      }
    }
    setFiscalYearRepeatError(fiscalYearRepeat);
    value[index][`fiscalYear~${index}`] = fiscalYear;
  }

  const handleRemoveAimedCountDetails = index => {
    const values = [...aimedCountList];
    values.splice(index, 1);
    setAimedCountList(values);
  }

  useEffect(() => {
    drawFiscalYear();
  }, []);

  const buildAimedCountDetailsForSubmit = () => {
    let aimedCountDetails = [];
    aimedCountList.map((aimedCount, index) => {
      aimedCount["fiscalYear"] = aimedCount[`fiscalYear~${index}`];
      delete aimedCount[`fiscalYear~${index}`];
      aimedCountDetails.push(aimedCount);
      return true;
    })
    return aimedCountDetails;
  }

  const buildAimedCountDetailsFromEdit = aimedCountDetails => {
    let aimedCountInfo = [];
    aimedCountDetails.map((aimedCount, index) => {
      aimedCount[`fiscalYear~${index}`] = aimedCount["fiscalYear"];
      delete aimedCount["fiscalYear"];
      aimedCountInfo.push(aimedCount);
      return true;
    })
    setAimedCountList(aimedCountInfo);
  }

  const removeLogo = () => {
    let postdata = {
      filePath: defaultSetting?.logoUrl?.path,
      fileName: defaultSetting?.logoUrl?.name,
      id: defaultSetting?.id
    }
    AHMIS.post(API_URL.applicationSetting + "/delete-logo", postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          drawFiscalYear();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  function handleLogoChange(e) {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (2 * 1024 * 1024);
      if (fileSizeValid) {
        var postdata = new FormData();
        postdata.append("files", file);
        postdata.append("id", "1")
        AHMIS.post(API_URL.applicationSetting + "/upload-logo", postdata, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              drawFiscalYear();
            }
            AddAlertMessage({
              type: jsondata.type,
              message: jsondata.message,
            });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  return (
    <>
      <Box className={classes.setting}>
        <Box borderBottom={1} mb={3} pb={1}>
          <Typography variant="h6">
            आयुर्वेद स्वास्थ्य व्यवस्थापन सूचना प्रणाली सेटिङ सम्पादन गर्नुहोस् ।
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onFiscalYearSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs>
              <CustomReactSelect
                label="आर्थिक वर्ष"
                name="currentFiscalYear"
                options={FISCAL_YEARS}
                onChange={handleOnFiscalYearChange}
                defaultValue={defaultSetting?.currentFiscalYear}
              />
              {/* {errors.currentFiscalYear && <span className="error-message">{REQUIRED_FIELD}</span>} */}
            </Grid>
            <Grid item xs>
              <TextField
                label="स्वास्थ्य संस्था कोड"
                type="text"
                size="small"
                variant="outlined"
                name="healthInstitutionCode"
                {...register("healthInstitutionCode")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <CustomReactSelect
                label="उपलब्ध सेवाहरु"
                isMulti
                defaultValue={servicesAvailableValue}
                options={SERVICES_AVAILABLE}
                onChange={handleAvailableServicesChange}
                name="availableServices"
                creatable
              />
            </Grid>
          </Grid>
          <Box textAlign="right" className={classes.btnContainer}>
            <Box mr={1}>
              <Button
                variant="contained"
                color="secondary"
                type="reset"
              >
                रद्द गर्नुहोस
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                सुरक्षित गर्नुहोस
              </Button>
            </Box>
          </Box>
        </form>
        <Box borderBottom={1} mb={3} pb={1} mt={3}>
          <Typography variant="h6">
            नयाँ प्रयोगकर्ता थप गर्नुहोस्।
          </Typography>
        </Box>
        <Box textAlign="center">
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => {
            setOpenAddUserModal(true);
          }}>नयाँ प्रयोगकर्ता थप गर्नुहोस्</Button>
        </Box>
      </Box>
      <AddUserModal showAddUserModal={openAddUserModal} handleAddUserModalClose={closeOpenAddUserModal} onSubmit={submitAddUserModal.bind(this)} defaultSetting={defaultSetting} />
    </>
  );
}