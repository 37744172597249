import { makeStyles } from "@mui/styles";
import { APP_BAR_HEIGHT } from "../../utils/constants";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    height: APP_BAR_HEIGHT,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  brand: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  userProfileMenu: {
    height: "100%"
  },
  healthClientName: {
    marginRight: theme.spacing(3),
    fontWeight: "500",
    fontSize: ".8rem",
  },
  usernameContainer: {
    cursor: "pointer",
  },
  username: {
    paddingRight: theme.spacing(1),
  },
  profileMenu: {
    marginTop: theme.spacing(2),
    border: '1px solid #d3d4d5',
    right: "15px",
    left: "auto !important",
  },
  profileMenuIcon: {
    paddingRight: theme.spacing(1)
  },
  profileMenuLink: {
    display: "inline-flex",
    verticalAlign: "middle",
    color: theme.palette.text.secondary + " !important",
  },
  subScript: {
    fontSize: "0.7rem"
  },
  supportTextContainer: {
    display: "flex",
    justifyContent: "center",
    marginRight: theme.spacing(2),
  },
  supportText: {
    fontSize: ".8rem",
  },
}));
export default styles;