import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import CustomModal from "../../../../components/modal/CustomModal";
import { ENTER_VALID_EMAIL, PALIKA_TYPES, PROVINCE_DISTRICT_PALIKA_LIST, REQUIRED_FIELD } from "../../../../utils/constants";
import styles from "./style";
import CustomReactSelect from "../../../../components/custom-react-select/CustomReactSelect";

export default function AddMunicipalityModal({ showAddMunicipalityModal, handleAddMunicipalityModalClose, handleModalSubmit, ...props }) {
  const classes = styles();
  const [districtOptions, setDistrictOptions] = useState();
  const [palikaOptions, setPalikaOptions] = useState();
  const [provinceLabel, setProvinceLabel] = useState();
  const [districtLabel, setDistrictLabel] = useState();
  const [palikaNameLabel, setPalikaNameLabel] = useState();
  const [provinceSelected, setProvinceSelected] = useState(false);
  const [districtSelected, setDistrictSelected] = useState(false);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [selectedPalikaName, setSelectedPalikaName] = useState();
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  useEffect(() => {
    register("province", { required: true });
    register("district", { required: true });
    register("palikaName", { required: true });
    register("palikaType", { required: true });
  }, [register]);

  useEffect(() => {
    if (showAddMunicipalityModal) {
      reset();
    }
  }, [showAddMunicipalityModal, reset]);

  useEffect(() => {
    if (JSON.stringify(props.defaultValues !== "{}")) {
      handleProvinceChange(PROVINCE_DISTRICT_PALIKA_LIST.find(option => option.value === props.defaultValues.province));
      setSelectedDistrictName(props.defaultValues.district);
      setSelectedPalikaName(props.defaultValues.palikaName);
    }
  }, [props.defaultValues]);

  useEffect(() => {
    selectedDistrictName && districtOptions && handleDistrictChange(districtOptions.find(option => option.value === selectedDistrictName));
  }, [selectedDistrictName, districtOptions]);

  useEffect(() => {
    selectedPalikaName && palikaOptions && handlePalikaNameChange(palikaOptions.find(option => option.value === selectedPalikaName));
  }, [selectedPalikaName, palikaOptions]);

  const handleProvinceChange = provinceOption => {
    provinceOption ? setProvinceSelected(true) : setProvinceSelected(false);
    setValue("province", provinceOption ? provinceOption.value : null);
    setValue("district", null);
    setValue("palikaName", null);
    setProvinceLabel(provinceOption ? provinceOption : null);
    setDistrictLabel(null);
    setPalikaNameLabel(null);
    provinceOption &&
      setDistrictOptions(provinceOption.districts);
  }

  const handleDistrictChange = (districtOption) => {
    districtOption ? setDistrictSelected(true) : setDistrictSelected(false);
    setValue("palikaName", null);
    setValue("district", districtOption ? districtOption.value : null);
    setPalikaNameLabel(null);
    setDistrictLabel(districtOption ? districtOption : "");
    districtOption &&
      setPalikaOptions(districtOption.palikas);
  }

  const handlePalikaNameChange = palikaOption => {
    setValue("palikaName", palikaOption ? palikaOption.value : null);
    setPalikaNameLabel(palikaOption ? palikaOption : "");
  }

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handleModalClose = () => {
    reset()
    handleAddMunicipalityModalClose();
    setProvinceLabel(null);
    setDistrictLabel(null);
    setPalikaNameLabel(null);
  }

  const onSubmit = data => {
    handleModalSubmit(data);
  };

  return (
    <CustomModal
      title="स्वास्थ्य व्यवस्थापन सूचना प्रणालीमा नयाँ पालिका थप्नुहोस।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddMunicipalityModal}
      onModalClose={handleModalClose}
      maxWidth="lg"
    >
      <Grid container spacing={2} alignItems="center" className={classes.row}>
        <Grid item xs={12} sm={6} md>
          <Select
            className="select-sm"
            classNamePrefix="react-select"
            placeholder="कृपया, प्रदेशको नाम चयन गर्नुहोस्।"
            name="province"
            size="small"
            variant="outlined"
            options={PROVINCE_DISTRICT_PALIKA_LIST}
            value={provinceLabel}
            onChange={handleProvinceChange}
            isClearable
          />
          {errors?.province && errors.province?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs={12} sm={6} md>
          <Select
            className="select-sm"
            classNamePrefix="react-select"
            placeholder="कृपया जिल्लाको नाम चयन गर्नुहोस्।"
            name="district"
            size="small"
            variant="outlined"
            options={districtOptions}
            value={districtLabel}
            onChange={handleDistrictChange}
            isDisabled={!provinceSelected}
            isClearable
          />
          {errors?.district && errors.district?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs={12} sm={6} md>
          <Select
            className="select-sm"
            classNamePrefix="react-select"
            placeholder="कृपया, पालिकाको नाम चयन गर्नुहोस्।"
            name="palikaName"
            size="small"
            variant="outlined"
            options={palikaOptions}
            value={palikaNameLabel}
            onChange={handlePalikaNameChange}
            isDisabled={!districtSelected || !provinceSelected}
            isClearable
          />
          {errors?.palikaName && errors.palikaName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs={12} sm={6} md>
          <CustomReactSelect
            label="पालिकाको प्रकार"
            name="palikaType"
            defaultValue={props?.defaultValues?.palikaType}
            options={PALIKA_TYPES}
            onChange={handleCustomSelectChange}
            fullWidth
          />
          {errors?.palikaType && errors.palikaType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.row}>
        <Grid item xs>
          <TextField
            label="गाउँ/नगरपालिकाको ठेगाना"
            type="text"
            size="small"
            variant="outlined"
            name="address"
            {...register("address")}
            defaultValue={props?.defaultValues?.address}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="गाउँ/नगरपालिकाको ई-मेल"
            type="email"
            size="small"
            variant="outlined"
            name="email"
            defaultValue={props?.defaultValues?.email}
            {...register("email", { pattern: /\S+@\S+\.\S+/ })}
            fullWidth
          />
          {errors?.email && errors.email?.type === "pattern" && <span className="error-message">{ENTER_VALID_EMAIL}</span>}
        </Grid>
        <Grid item xs>
          <TextField
            label="गाउँ/नगरपालिकाको फोन नम्बर"
            type="text"
            size="small"
            variant="outlined"
            name="phoneNumber"
            defaultValue={props?.defaultValues?.phoneNumber}
            {...register("phoneNumber")}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="गाउँ/नगरपालिकाको फ्याक्स नम्बर"
            type="text"
            variant="outlined"
            size="small"
            name="faxNumber"
            {...register("faxNumber")}
            defaultValue={props?.defaultValues?.faxNumber}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.row}>
        <Grid item xs>
          <TextField
            label="गाउँ/नगरपालिकाको वेबसाइट"
            type="text"
            variant="outlined"
            size="small"
            name="website"
            {...register("website", { pattern: /^(http:\/\/|https:\/\/)/ })}
            defaultValue={props?.defaultValues?.website}
            fullWidth
          />
          {errors?.website && errors.website?.type === "pattern" && <span className="error-message">Please Enter a valid URL.</span>}
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs></Grid>
        <Grid item xs></Grid>
      </Grid>
    </CustomModal>
  );
}