import { makeStyles } from '@mui/styles'

const styles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  loginBtnContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  },
  subScript: {
    fontSize: "0.7rem"
  },
  icons: {
    color: "#888"
  }
}));
export default styles;