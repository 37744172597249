import { makeStyles } from "@mui/styles";

const styles = makeStyles(theme => ({
  registerHeaderContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  registerTitle: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
      marginBottom: theme.spacing(2)
    },
  },
  title: {
    borderBottom: "1px solid #999"
  },
  CardContent: {
    padding: theme.spacing(1.5, 3) + " !important",
  },
  countValue: {
    marginRight: theme.spacing(1),
  }
}));
export default styles;