import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const TypeOfHealthFacility = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Box mt={4}>
        <Typography variant="body1" className="report-heading">स्वास्थ्य संस्थाको प्रकार</Typography>
        </Box>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Type-Of-Health-Facility">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell>क्रसं</TableCell>
              <TableCell>स्वास्थ्य संस्थाको प्रकार</TableCell>
              <TableCell>कोड</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>चिकित्सालय</TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>स्वास्थ्य केन्द्र</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>औषधालय</TableCell>
              <TableCell>3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4</TableCell>
              <TableCell>पोलिक्लिनिक</TableCell>
              <TableCell>4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell>क्लिनिक</TableCell>
              <TableCell>5</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TypeOfHealthFacility;