import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const GauGharClinicSewaReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Box mt={4}>
        <Typography variant="body1" className="report-heading">गाउँघर /शहरी क्लिनिक सेवा</Typography>
        </Box>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Gau-Ghar-Clinic-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={4}>गाउँघर /शहरी क्लिनिक सेवा</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>संचालन हुनुपर्ने</TableCell>
              <TableCell rowSpan={2}>संचालन भएको</TableCell>
              <TableCell colSpan={2}>जम्मा सेवा पाउनेको संख्या</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>महिला</TableCell>
              <TableCell>पुरुष </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default GauGharClinicSewaReport;
