import { SIDEBAR_OPENED, TOGGLE_SIDEBAR } from "../utils/constants";

const reducer = (isSidebarOpened = localStorage.getItem(SIDEBAR_OPENED), action) => {
  const width = window.innerWidth;
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      localStorage.setItem(SIDEBAR_OPENED, !isSidebarOpened)
      return !isSidebarOpened;
    default:
      return width <= 768 ? false : isSidebarOpened !== "false";
  }
}

export default reducer;