import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react"

const FreeHealthServicesAndSocialSecurityProgramme = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Box mt={4}>
        <Typography variant="body1" className="report-heading">Free Health Services and Social Security Programme</Typography>
        </Box>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Free-Health-Services-And-Social-Security-Programme">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell>Patients at</TableCell>
              <TableCell>Ultra Poor/ Poor</TableCell>
              <TableCell>Helpless/ Destitute</TableCell>
              <TableCell>Disabled</TableCell>
              <TableCell>Sr. Citizens  &gt; 60 Years</TableCell>
              <TableCell>FCHV</TableCell>
              <TableCell>Gender Based Voilence</TableCell>
              <TableCell>Others</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>Outpatients</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inpatients</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Emergency</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Referred Out</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default FreeHealthServicesAndSocialSecurityProgramme;