import { LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_REGISTER_SHOW_HIDE_INFO } from "../utils/constants/redux";

const reducer = (registerShowHideInfo = null, action) => {
  switch (action.type) {
    case SAVE_REGISTER_SHOW_HIDE_INFO:
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return registerShowHideInfo;
  }
}

export default reducer;