import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AHMIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { AppUtils } from "../../../utils/appUtils";
import { PASSWORD_DO_NOT_MATCHES, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants/index";
import styles from "./style";
import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function ResetPassword(props) {
  const navigate = useNavigate()
  const classes = styles();
  const { register, watch, handleSubmit, formState:{errors} } = useForm();
  const [showPassword, setShowPassword] = useState(false)

  const  handleClickShowPassword=()=> {
    setShowPassword(!showPassword)
  }

  const onSubmit = (data) => {
    let token = AppUtils.getUrlParam("token");
    if (token) {
      let postdata = {
        password: data.password,
        token: token,
      }
      AHMIS.post(API_URL.resetPassword, postdata)
        .then(response => {
          AddAlertMessage({
            type: response.data.type,
            message: response.data.message
          });
          if (response.data.type === SUCCESS){
            navigate("/");
          }
        })
        .catch(error => {
          AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
        });
    }
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <h2 className="border-bottom-heading">तपाईंको पासवर्ड रिसेट गर्नुहोस्।</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="outlined-user-password"
              label="पासवर्ड:"
              size="small"
              variant="outlined"
              name="password"
              {...register("password", { required: true })}
              type={showPassword ? "text" : "password"}
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
            />
            {errors.password && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="outlined-user-confirm-password"
              label="पासवर्ड सुनिश्चित गर्नुहोस:"
              size="small"
              variant="outlined"
              name="confirmPassword"
              {...register("confirmPassword", { required: true, validate: value => value === watch("password") })}
              type={showPassword ? "text" : "password"}
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
            />
            {errors.confirmPassword && errors.confirmPassword.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            {errors.confirmPassword && errors.confirmPassword.type === "validate" && <span className="error-message">{PASSWORD_DO_NOT_MATCHES} </span>}
          </Grid>
        </Grid>
        <Box textAlign="right" className={classes.btnContainer}>
         <Box mr={1}>
         <Button 
            variant="contained"
            color="secondary"
            type="reset"
          >
            रद्द गर्नुहोस
          </Button>
         </Box>
         <Box>
         <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            सुरक्षित गर्नुहोस
          </Button>
         </Box>
        </Box>
      </form>
      <Box textAlign="center" mt={5}>
        <Button
          color="primary"
          onClick={() => {
            navigate("/")
          }}
          className={classes.button}
          startIcon={<ArrowBack />}
        >
          Back To Home
        </Button>
      </Box>
    </Container>
  );
}