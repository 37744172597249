import { makeStyles } from "@mui/styles";

const styles = makeStyles(theme => ({
  registerHeaderContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  dateRangeContainer: {
    "& .MuiTypography-subtitle2": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    }
  },
  registerTitle: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
      marginBottom: theme.spacing(2)
    },
  },
  registerHeaderContent: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  }
}));
export default styles;