import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSIONS } from "../../utils/constants";
import { SAVE_USER_PERMISSIONS } from "../../utils/constants/redux/index";
import { LocalStorage } from "../../utils/storage/localStorage";
import { SIDEBAR_LINKS } from "./helpers/SidebarItems";
import SidebarLink from "./helpers/SidebarLink/SidebarLink";
import styles from "./style";
import { Drawer, List } from "@mui/material";

function Sidebar({ location }) {
  const classes = styles();
  const dispatch = useDispatch();
  const permissions = useSelector(state => state.permissions);
  const [sidebarItem, setSidebarItem] = useState([]);

  useEffect(() => {
    !permissions && dispatch({ type: SAVE_USER_PERMISSIONS, payload: LocalStorage.getItem(PERMISSIONS) })
    if (permissions) {
      setSidebarItem(findSidebarItem(SIDEBAR_LINKS));
    }
  }, [permissions, SIDEBAR_LINKS])

  const findSidebarItem = (sidebarLink) => {
    let items = [];
    sidebarLink.forEach((item) => {
      if (containCommonElement(item.permissions)) {
        if (item.children && !containEveryElement(item.permissions)) {
          let containedElement = [];
          item.children.forEach(element => {
            if (containCommonElement(element.permissions)) containedElement.push(element)
          });
          item.children = containedElement;
        }
        items.push(item);
      }
    })
    return items;
  }

  const containCommonElement = (array) => {
    return array.some(item => permissions.includes(item));
  }

  const containEveryElement = array => {
    return array.every(item => permissions.includes(item));
  }
  // global
  const isSidebarOpened = useSelector(state => state.sidebarToggleState);

  return (
    // TODO: Sandeep - Get links from components/left-sidebar/helpers/sidebarItems.js and attach them dynamically.
    // TODO: Sandeep - Add dynamic variant based on the device width. i.e, for mobile devices use variant="temporary"
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <List className={classes.sidebarList}>
        {sidebarItem.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;
