
import { Box, Tooltip } from "@mui/material";
import AHMIS, { API_URL } from "../api/api";
import { AppUtils } from "../utils/appUtils";
import { AGE_UNITS, ICD_CODE_OPTIONS, MAJOR_CLASSIFICATIONS_FOR_ABOVE_TWO_MONTH, MAJOR_CLASSIFICATIONS_FOR_BELOW_TWO_MONTH, MEDICINE_TIMING, MEDICINE_UNIT_OPTIONS } from "../utils/constants/forms";
import { DateUtils } from '../utils/dateUtils';
import { ALL_ROLES, CASTE_CODES, DAY, GENDER_OPTIONS, MONTH, PALIKA_TYPES, PROVINCE_DISTRICT_PALIKA_LIST, RADIAN, YEAR, } from "../utils/constants";

export const AppMisc = {
  getAgeAndAgeUnitByMilliseconds(birthDate, refDate) {
    if (birthDate || (parseInt(birthDate) === 0)) {
      // TODO: Sandeep - Calculate age in months and days exact.
      let presentDate = refDate ? (refDate) : Date.now();
      let timeDiff = presentDate - birthDate;
      const ageInDays = Math.floor(timeDiff / (1000 * 3600 * 24));
      const ageInMonths = Math.floor(ageInDays / 30);
      const ageInYears = Math.floor(ageInDays / 365);

      if (ageInYears > 0) {
        return { age: ageInYears, ageUnit: YEAR }
      } else if (ageInMonths > 0) {
        return { age: ageInMonths, ageUnit: MONTH }
      } else {
        return { age: ageInDays, ageUnit: DAY }
      }
    }
    return {};
  },
  getProvinceName(provinceValue) {
    return provinceValue ? PROVINCE_DISTRICT_PALIKA_LIST.find(obj => obj.value === provinceValue).label : "";
  },
  getDistrictName(districtValue) {
    let value = districtValue;
    PROVINCE_DISTRICT_PALIKA_LIST.find(province =>
      province.districts.find(district => {
        if (district.value === districtValue) {
          value = district.label;
          return
        }
      })
    )
    return value
  },
  getMunicipalityName(municipalityValue) {
    for (const province of PROVINCE_DISTRICT_PALIKA_LIST) {
      const palika = province.districts.flatMap(district => district.palikas).find(palika => palika.value === municipalityValue);
      if (palika) {
        return palika.label;
      }
    }
    return municipalityValue;
  },
  getMunicipalityValueFromLabel(municipalityName) {
    let result = municipalityName;
    PROVINCE_DISTRICT_PALIKA_LIST.find(province =>
      province.districts.find(district =>
        district.palikas.find(palika => {
          if (palika.label === municipalityName) {
            result = palika.value
            return
          }
        })
      )
    )
    return result;
  },
  getPalikaTypeName(palikaTypeValue) {
    return palikaTypeValue ? PALIKA_TYPES.find(obj => obj.value === palikaTypeValue).label : "";
  },
  getMunicipalityInfo(municipalityValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value === municipalityValue) {
            return {
              municipalityName: PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label,
              District: PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].label,
              Province: PROVINCE_DISTRICT_PALIKA_LIST[i].label,
            }
          }
        }
      }
    }
  },
  getProvinceLabelFromDistrictValue(districtValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        if (districtValue === PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].value) {
          return PROVINCE_DISTRICT_PALIKA_LIST[i].label;
        }
      }
    }
  },
  getUserRole(userRoleValue) {
    return userRoleValue ? ALL_ROLES.find(obj => obj.value === userRoleValue)?.label : "";
  },

  getAllDartaaNumberOptions(showPatientName) {
    var mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaRegister + "/mul-dartaa-numbers")
      .then(response => {
        var data = response.data;
        data.forEach(item => {
          let label = item.dartaaNumber;
          if (showPatientName)
            label += " (" + item.patientFirstName + " " + item.patientLastName + ")";
          mulDartaaOptions.push({ "value": item.dartaaNumber, "label": label });
        });
      })
    return mulDartaaOptions;
  },
  getDistrictOptions() {
    let districts = [];
    PROVINCE_DISTRICT_PALIKA_LIST.forEach(province =>
      province.districts.forEach(district => {
        districts.push(district)
      })
    )
    return districts;
  },
  getPALIKA_TYPESByDistrict(district) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].value === district) {
          return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas;
        }
      }
    }
  },
  getFiscalYearLabel(fiscalYear) {
    return AppUtils.replaceWithNepaliDigit(fiscalYear.replace("_", "/"))
  },
  getIcdCodeObject(icdCode) {
    for (let i = 0; i < ICD_CODE_OPTIONS.length; i++) {
      for (let j = 0; j < ICD_CODE_OPTIONS[i].options.length; j++) {
        if (ICD_CODE_OPTIONS[i].options[j].value === icdCode) {
          return ICD_CODE_OPTIONS[i].options[j];
        }
      }
    }
    return "";
  },
  getMajorClassificationOptions(classifications) {
    let classificationOptions = [];
    for (let i = 0; i < classifications.length; i++) {
      for (let j = 0; j < MAJOR_CLASSIFICATIONS_FOR_BELOW_TWO_MONTH.length; j++) {
        for (let k = 0; k < MAJOR_CLASSIFICATIONS_FOR_BELOW_TWO_MONTH[j].options.length; k++) {
          if (MAJOR_CLASSIFICATIONS_FOR_BELOW_TWO_MONTH[j].options[k].value === classifications[i]) {
            classificationOptions.push(MAJOR_CLASSIFICATIONS_FOR_BELOW_TWO_MONTH[j].options[k])
          }
        }
      }
    }
    return classificationOptions;
  },
  getMajorClassificationOptionsForTwoMonthsAbove(classifications) {
    let classificationOptions = [];
    for (let i = 0; i < classifications.length; i++) {
      for (let j = 0; j < MAJOR_CLASSIFICATIONS_FOR_ABOVE_TWO_MONTH.length; j++) {
        for (let k = 0; k < MAJOR_CLASSIFICATIONS_FOR_ABOVE_TWO_MONTH[j].options.length; k++) {
          if (MAJOR_CLASSIFICATIONS_FOR_ABOVE_TWO_MONTH[j].options[k].value === classifications[i]) {
            classificationOptions.push(MAJOR_CLASSIFICATIONS_FOR_ABOVE_TWO_MONTH[j].options[k])
          }
        }
      }
    }
    return classificationOptions;
  },
  getAgeUnitLabel(ageUnit) {
    return ageUnit ? AGE_UNITS.find(obj => obj.value === ageUnit).label : "";
  },
  getGenderLabel(gender) {
    return gender ? GENDER_OPTIONS.find(obj => obj.value === gender).label : "-";
  },
  getCasteCodeLabelWithTooltip(code) {
    return (
      <Tooltip title={CASTE_CODES.find(obj => obj.value === code).label} placement="top" arrow>
        <Box>{AppUtils.replaceWithNepaliDigit(code)}</Box>
      </Tooltip>
    );
  },
  getMajorClassificationsLabel(majorClassifications, majorClassificationOptions) {
    let majorClassificationLabels = [];
    majorClassifications &&
      majorClassifications.map(value => {
        for (let i = 0; i < majorClassificationOptions.length; i++) {
          for (let j = 0; j < majorClassificationOptions[i].options.length; j++) {
            if (majorClassificationOptions[i].options[j].value === value) {
              majorClassificationLabels.push(majorClassificationOptions[i].options[j].label)
            }
          }
        }
      })
    return majorClassificationLabels.join(", ");
  },
  getTimeFormat(time) {
    if (time) {
      let isoDate = new Date(time);
      let hours = isoDate.getHours();
      let minutes = isoDate.getMinutes();
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (hours === 0) {
        return 12 + ":" + minutes + " AM";
      }
      return hours > 12 ? (hours - 12 + ":" + minutes + " PM") : hours == 12 ? (hours + ":" + minutes + " PM") : (hours + ":" + minutes + " AM");
    }
  },
  searchByPatientName(patientFirstName, patientLastName, searchText) {
    let patientFullName = patientFirstName + " " + patientLastName;
    return Boolean(patientFullName?.toLowerCase().includes(searchText?.toLowerCase() || ""));
  },
  getDateLabelFromMillisecond(dateInMilli) {
    if (dateInMilli || (dateInMilli !== null && Number(dateInMilli) === 0)) {
      return AppUtils.replaceWithNepaliDigit(DateUtils.getDateFromMilliseconds(dateInMilli));
    }
    return "-";
  },
  findLabelFromValue(options, value) {
    return options.find(obj => obj.value === value)?.label || value;
  },
  hideFullBodyLoader(url, method) {
    if (method !== "get") return false;
    // for (let api of API_WITHOUT_LOADER) {
    //   if (url.includes(api)) return true
    // }
  },
  getMedicineNameLabel(options, medicineName) {
    for (let obj of options) {
      if (obj.value === medicineName) {
        if (obj.unit) {
          return medicineName
        }
        return obj.label;
      }
    }
    return medicineName;
  },
  getMedicineUnitLabel(unit) {
    return `${MEDICINE_UNIT_OPTIONS.find(obj => obj.value === unit)?.code || ''}`
  },
  getReadableFormatFromMedicineObject(obj, medicineOption) {
    return (obj.numberOfMedicine || "") + " " + (obj.unit ? this.getMedicineUnitLabel(obj.unit) : "") + " " + this.getMedicineNameLabel(medicineOption, obj.name) + ' ' + (obj.dose || '') + " " + (obj.medicineRoute ? ("(" + obj.medicineRoute + ")") : "") + (obj.frequency ? (" (" + obj.frequency + ")") : "") + (obj.medicineDuration ? (" for " + obj.medicineDuration) : "") + " " + (obj.timing ? this.findLabelFromValue(MEDICINE_TIMING, obj.timing) : '')
  },
  getReadableMedicineDetailsFromList(list, options) {
    list = list || [];
    return list.map(item => { return <div>{this.getReadableFormatFromMedicineObject(item, options)}</div> });
  },
  buildMedicineOptions(list) {
    return list?.map((item) => { return { value: item.name || item.medicineName, label: `${this.getMedicineUnitLabel(item.unit)} ${item.name || item.medicineName} ${item.dose || ''}`, unit: item.unit, dose: item.dose, medicineId: item.medicineId } }) || [];
  },
  getLabelsFromArrayValue(list, options) {
    let arrayList = list ? [...list] : [];
    let labels = [];
    options?.forEach(obj => {
      if (arrayList.includes(obj.value)) {
        arrayList = arrayList.filter(item => item !== obj.value)
        labels.push(obj.label);
      }
    });
    return [...labels, ...arrayList].join(", ");
  },
  buildLabTestOptions(list) {
    return list.map(item => { return { value: item.name, label: item.name, labTestId: item.labTestId } });
  },
  //generate the random color
  getRandomColor() {
    const red = Math.floor(Math.random() * 255);
    const green = Math.floor(Math.random() * 255);
    const blue = Math.floor(Math.random() * 255);
    return `rgb(${red}, ${green}, ${blue})`;
  },
  renderCustomizedPercentageLabel(obj) {
    const radius = obj?.innerRadius + (obj?.outerRadius - obj?.innerRadius) * 0.5;
    const paddingAtRight = -13;
    const x = obj?.cx + radius * Math.cos(-obj?.midAngle * RADIAN) + paddingAtRight
    const y = obj?.cy + radius * Math.sin(-obj?.midAngle * RADIAN)
    return (
      <text x={x} y={y} fill="white" dominantBaseline="central" >
        {`${(obj?.percent * 100).toFixed(1)}%`}
      </text>
    );
  }
};