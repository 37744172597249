import React from "react";
import { AppUtils } from "../../../../utils/appUtils";
import { HEALTH_ORGANIZATION_TYPES, NO_RECORDS_FOUND } from "../../../../utils/constants";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";

function getHealthOrganizationType(healthOrganizationType) {
  return HEALTH_ORGANIZATION_TYPES.find(obj => obj.value === healthOrganizationType).label;
}

export default function HealthClientList(props) {

  function editRow(id) {
    props.editRow(id);
  }

  return (
    <TableContainer>
      <Table classes={{ root: "table-bordered" }} size="small" aria-label="Health Client List">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>क्र. सं.</TableCell>
            <TableCell>स्वास्थ्य संस्थाको नाम</TableCell>
            <TableCell>स्वास्थ्य संस्थाको प्रकार</TableCell>
            <TableCell>ठेगाना</TableCell>
            <TableCell>फोन नम्बर</TableCell>
            <TableCell>ईमेल</TableCell>
            {props.tableData?.length !== 0 && <TableCell>सम्पादन</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {props.tableData?.length === 0 ?
            (
              <TableRow>
                <TableCell colSpan={6} align="center" size="medium">{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (
              props.tableData?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                  <TableCell><a href={`health-client-home?id=${row.id}&clientType=${row.healthOrganizationType}`}>{row.name}</a></TableCell>
                  <TableCell>{row.healthOrganizationType ? getHealthOrganizationType(row.healthOrganizationType) : "-"}</TableCell>
                  <TableCell>{row.address || "-"}</TableCell>
                  <TableCell>{row.phoneNumber || "-"}</TableCell>
                  <TableCell>{row.email || "-"}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => editRow(row?.id)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
