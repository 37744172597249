import { Box, Card } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { AppMisc } from "../../misc/appMisc";
import { PROGRAM_NAME } from "../../utils/constants/forms";

const ChartDataByByBittayaBibaran = (props) => {
  const programNames = props.chartData.map(item => AppMisc.findLabelFromValue(PROGRAM_NAME, item.programName));
  const biniyojitBajet = props.chartData.map(item => parseFloat(item.biniyojitBajet));
  const remainingMoney = props.chartData.map(item => parseFloat(item.remainingMoney));
  const karchaRakam = props.chartData.map(item => parseFloat(item.karchaRakam));

  const chartData = [
    {
      name: "विनियोजित बजेट",
      data: biniyojitBajet
    },
    {
      name: "खर्च",
      data: karchaRakam
    },
    {
      name: "बाकी",
      data: remainingMoney
    }
  ];

  const chartOptions = {
    chart: {
      type: "bar",
      height: 430
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "center",
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: programNames,
    },
  };

  return (
    <>
      <Box mt={2}>
        <Card>
          <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="bar"
            height={430}
          />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataByByBittayaBibaran;