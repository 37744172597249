import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./style";
import { Box, Tooltip } from "@mui/material";
import CustomReactSelect from "../custom-react-select/CustomReactSelect";
import { ERROR, ROLE_USER_MUNICIPALITY, ROLE_USER_MUNICIPALITY_BILLING, ROLE_USER_MUNICIPALITY_KHOP, SOMETHING_WENT_WRONG } from "../../utils/constants";
import AHMIS, { API_URL } from "../../api/api";
import AddAlertMessage from "../alert/Alert";

export default function HealthClientSelect({ handleHealthClientChange, ...props }) {
  const classes = styles();
  const [healthClientOptions, setHealthClientOptions] = useState([]);
  const userInfo = useSelector(state => state.userInfo);

  const onHealthClientChangeHandler = (id) => {
    handleHealthClientChange(id)
  }

  useEffect(() => {
    (userInfo?.role === ROLE_USER_MUNICIPALITY || userInfo?.role === ROLE_USER_MUNICIPALITY_BILLING || userInfo?.role === ROLE_USER_MUNICIPALITY_KHOP) &&
      getMunicipalityHealthClients();
  }, [userInfo])

  const getMunicipalityHealthClients = () => {
    if (userInfo.palikaId) {
      AHMIS.get(API_URL.municipalityHealthClients + "/" + userInfo.palikaId).then(response => {
        let healthClientOptions = [];
        response.data.forEach(item => {
          healthClientOptions.push({ "value": item.id, "label": item.name + " (" + item.address + ")" });
        });
        setHealthClientOptions(healthClientOptions);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
    }
  };

  return (
    <>
      <Tooltip title="कृपया रिपोर्ट हेर्नुपर्ने स्वास्थ्य संस्था छनौट गर्नुहोस्।" placement="top" arrow>
        <Box className={classes.healthClientSelectContainer} pl={1} mr={1} mb={2}>
          <CustomReactSelect
            label="स्वास्थ्य संस्था"
            name="healthClient"
            options={healthClientOptions}
            onChange={(...params) => onHealthClientChangeHandler(params[1])}
            isClearable={false}
            isMulti={props.isMulti}
          />
        </Box>
      </Tooltip>
    </>
  );
};