
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { FEMALE, MALE, NO_RECORDS_FOUND, SEWA_TYPES } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { AppMisc } from "../../../../../misc/appMisc";
import { NEW_PATIENT, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";
import { CreditCard, Delete, Edit } from "@mui/icons-material";

const StanpaiAamaTathaShishuSewaRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }
  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef}>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Stanpai-Aama-Tatha-Shishu">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>क्र.सं.</TableCell>
                <TableCell rowSpan={2}>दर्ता मिति</TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <TableCell rowSpan={2}>जाती कोड</TableCell>
                <TableCell colSpan={2}>उमेर</TableCell>
                <TableCell colSpan={3}>ठेगाना</TableCell>
                <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
                <TableCell rowSpan={2}>सेवाको किसिम</TableCell>
                <TableCell rowSpan={2}>नि:शुल्क/शुल्क रु.</TableCell>
                <TableCell rowSpan={2}>प्रेषण भई आएको संस्थाको नाम</TableCell>
                <TableCell rowSpan={2}>Date of Delivery</TableCell>
                <TableCell rowSpan={2}> Place of Delivery</TableCell>
                <TableCell rowSpan={2}>Types of Delivery</TableCell>
                <TableCell colSpan={5}>स्वास्थ्य अवस्था</TableCell>
                <TableCell rowSpan={2}>परीक्षण तथा सल्लाह</TableCell>
                <TableCell colSpan={3}>पहिलो महिनामा आमा र शिशुको  स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={3}>दोस्रो महिनामा आमा र शिशुको  स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={3}>तेस्रो महिनामा आमा र शिशुको  स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={3}>चौथो  महिनामा आमा र शिशुको  स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={3}>पाँचौं महिनामा आमा र शिशुको  स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={3}>छैठौं महिनामा आमा र शिशुको  स्वास्थ्य अवस्था</TableCell>
                {/* <TableCell rowSpan={3}>तेस्रो महिनाको सेवा पश्चात आमाको दुध र शिशुको तौलमा सुधार भए नभएको</TableCell>
                <TableCell rowSpan={3}>छैठौं महिनाको सेवा पश्चात आमाको दुध र शिशुको तौलमा सुधार भए नभएको</TableCell>
                <TableCell rowSpan={3}>औषधी  वितरण गरिएको महिना</TableCell>
                <TableCell rowSpan={3}>औषधी वितरण गरिएको अन्तिम मिति</TableCell> */}
                <TableCell rowSpan={3} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पहिलो पटक</TableCell>
                <TableCell>थप पटक</TableCell>
                <TableCell>नाम</TableCell>
                <TableCell>थर</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>पुरुष</TableCell>
                <TableCell>जिल्ला</TableCell>
                <TableCell>नगर/गाउँपालिका</TableCell>
                <TableCell>वडा नं.</TableCell>
                <TableCell>रक्तचाप</TableCell>
                <TableCell>तौल</TableCell>
                <TableCell>BS</TableCell>
                <TableCell>प्रकृति</TableCell>
                <TableCell>प्रोग /निदान</TableCell>
                <TableCell>शिशुको तौल</TableCell>
                <TableCell>आमाको  दुधकोअवस्था</TableCell>
                <TableCell>मिति</TableCell>
                <TableCell>शिशुको तौल</TableCell>
                <TableCell>आमाको  दुधकोअवस्था</TableCell>
                <TableCell>मिति</TableCell>
                <TableCell>शिशुको तौल</TableCell>
                <TableCell>आमाको  दुधकोअवस्था</TableCell>
                <TableCell>मिति</TableCell>
                <TableCell>शिशुको तौल</TableCell>
                <TableCell>आमाको  दुधकोअवस्था</TableCell>
                <TableCell>मिति</TableCell>
                <TableCell>शिशुको तौल</TableCell>
                <TableCell>आमाको  दुधकोअवस्था</TableCell>
                <TableCell>मिति</TableCell>
                <TableCell>शिशुको तौल</TableCell>
                <TableCell>आमाको  दुधकोअवस्था</TableCell>
                <TableCell>मिति</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData.length > 0 ? (
                mainRegisterData.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                    <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientFirstName || "-"}</TableCell>
                    <TableCell>{row?.patientLastName || "-"}</TableCell>
                    <TableCell>{row?.casteCode && AppUtils.replaceWithNepaliDigit(row.casteCode) || "-"}</TableCell>
                    <TableCell>{row?.gender === FEMALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                    <TableCell>{row?.gender === MALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                    <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                    <TableCell>{row?.palikaName && AppMisc.getMunicipalityName(row?.palikaName)}</TableCell>
                    <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                    <TableCell>{row?.phoneNumber && AppUtils.replaceWithNepaliDigit(row?.phoneNumber)}</TableCell>
                    <TableCell>{row.serviceType && AppMisc.findLabelFromValue(SEWA_TYPES, row?.serviceType)}</TableCell>
                    <TableCell>{row?.cost || "-"}</TableCell>
                    <TableCell>{row?.sentFromOrganizationName || "-"}</TableCell>
                    <TableCell>{row?.dateOfDelivery && AppMisc.getDateLabelFromMillisecond(row?.dateOfDelivery)}</TableCell>
                    <TableCell>{row?.placeOfDelivery}</TableCell>
                    <TableCell>{row?.typesOfDelivery || "-"}</TableCell>
                    <TableCell>{row?.bloodPressure || "-"}</TableCell>
                    <TableCell>{row?.weight || "-"}</TableCell>
                    <TableCell>{row?.bs || "-"}</TableCell>
                    <TableCell>{row?.nature || "-"}</TableCell>
                    <TableCell>{row?.diagnosis || "-"}</TableCell>
                    <TableCell>{row?.test || "-"}</TableCell>
                    <TableCell>{row?.firstMonthChildWeight || "-"}</TableCell>
                    <TableCell>{row?.firstMonthMotherSitutation || "-"}</TableCell>
                    <TableCell>{row?.firstMonthHealthSitutation && AppMisc.getDateLabelFromMillisecond(row?.firstMonthHealthSitutation)}</TableCell>
                    <TableCell>{row?.secondMonthChildWeight || "-"}</TableCell>
                    <TableCell>{row?.secondMonthMotherSitutation || "-"}</TableCell>
                    <TableCell>{row?.secondMonthHealthSitutation && AppMisc.getDateLabelFromMillisecond(row?.secondMonthHealthSitutation)}</TableCell>
                    <TableCell>{row?.thirdMonthChildWeight || "-"}</TableCell>
                    <TableCell>{row?.thirdMonthMotherSitutation || "-"}</TableCell>
                    <TableCell>{row?.thirdMonthHealthSitutation && AppMisc.getDateLabelFromMillisecond(row?.thirdMonthHealthSitutation)}</TableCell>
                    <TableCell>{row?.fourthMonthChildWeight || "-"}</TableCell>
                    <TableCell>{row?.fourthMonthMotherSitutation || "-"}</TableCell>
                    <TableCell>{row?.fourthMonthHealthSitutation && AppMisc.getDateLabelFromMillisecond(row?.fourthMonthHealthSitutation)}</TableCell>
                    <TableCell>{row?.fifthMonthChildWeight || "-"}</TableCell>
                    <TableCell>{row?.fifthMonthMotherSitutation || "-"}</TableCell>
                    <TableCell>{row?.fourthMonthHealthSitutation && AppMisc.getDateLabelFromMillisecond(row?.fourthMonthHealthSitutation)}</TableCell>
                    <TableCell>{row?.sixthMonthChildWeight || "-"}</TableCell>
                    <TableCell>{row?.sixthMonthMotherSitutation || "-"}</TableCell>
                    <TableCell>{row?.sixthMonthHealthSitutation && AppMisc.getDateLabelFromMillisecond(row?.sixthMonthHealthSitutation)}</TableCell>
                    {/* <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell> */}
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        <Tooltip title="स्वास्थ्य सेवा कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <CreditCard fontSize="small" />
                        </Tooltip>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color="secondary" onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>

                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="41" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll >
    </>
  )
}

export default StanpaiAamaTathaShishuSewaRegister;