import { Box, Card, CardContent, Divider, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppMisc } from "../../../../../../misc/appMisc";
import { Delete, Edit } from "@mui/icons-material";
import { GENDER_OPTIONS, NO_RECORDS_FOUND } from "../../../../../../utils/constants";
import { API_URL } from "../../../../../../api/api";
import { AppUtils } from "../../../../../../utils/appUtils";
import { getDataFromServer } from "../../../../../../utils/apiUtils";

const PanchaKarmaBabaranRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();
  const id = AppUtils.getUrlParam("id");
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData];
        });
      } else {
        tableRef.current.scrollTo(0, 0);
        setMainRegisterData(props.tableData);
      }
    }
  }, [props.tableData]);

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true);
  };


  const getCardData = async () => {
    let url = API_URL.panchakarmaRegister + "?id=" + id
    let response = await getDataFromServer(url)
    if (response) {
      setCardData(response)
    }
  }

  useEffect(() => {
    getCardData()
  }, [])

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">सेवाग्राहीको सामान्य विवरण</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              दर्ता मिति:{AppMisc.getDateLabelFromMillisecond(cardData[0]?.dartaaMiti)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              नाम:{cardData[0]?.patientFirstName}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              थर:{cardData[0]?.patientLastName}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              उमेर:{cardData[0]?.age && AppUtils.replaceWithNepaliDigit(cardData[0].age)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              लिङ्ग:{cardData[0]?.gender && AppMisc.findLabelFromValue(GENDER_OPTIONS, cardData[0].gender)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              जिल्ला:{cardData[0]?.district && AppMisc.getDistrictName(cardData[0].district)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              नगर/गाउँपालिका:{cardData[0]?.palikaName}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              वडा नं:{cardData[0]?.wardNumber && AppUtils.replaceWithNepaliDigit(cardData[0].wardNumber)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              टोल:{cardData[0]?.gaunOrTole}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              सम्पर्क नं.:{cardData[0]?.phoneNumber && AppUtils.replaceWithNepaliDigit(cardData[0]?.phoneNumber)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef}>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>मिति</TableCell>
                <TableCell rowSpan={2}>थेरापी</TableCell>
                <TableCell rowSpan={2}>विवरण</TableCell>
                <TableCell rowSpan={2}>सेवा</TableCell>
                <TableCell rowSpan={2}>जटिलता</TableCell>
                <TableCell rowSpan={2}>परीक्षण /सल्लाह</TableCell>
                <TableCell rowSpan={2}>सम्पादन</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData?.length > 0 ? (
                mainRegisterData?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.date)}</TableCell>
                    <TableCell>{row?.therapy}</TableCell>
                    <TableCell>{row.vivarana || "-"}</TableCell>
                    <TableCell>{row.service || "-"}</TableCell>
                    <TableCell>{row.complexity || "-"}</TableCell>
                    <TableCell>{row.testing || "-"}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton aria-label="edit" onClick={() => props.onEditRow(row.id)}>
                          <Edit fontSize="small" />
                        </IconButton>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton color="secondary" onClick={() => props.deleteRow(row.id)}>
                            <Delete fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="8" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};

export default PanchaKarmaBabaranRegister;