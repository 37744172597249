import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  dashboardContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  }
}));
export default styles;