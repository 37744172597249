
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import CountUp from 'react-countup';
import styles from "../style";

const ChartDataForTotalDisease = () => {
  const classes = styles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'primary.main', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={77}
                    duration={5}
                    className={classes.countValue}
                  />
                  वटा
                </Typography>
                <Typography variant="body1">जम्मा जिल्ला</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'info.main', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={753}
                    duration={5}
                    className={classes.countValue}
                  />
                  वटा
                </Typography>
                <Typography variant="body1">जम्मा स्थानीय तह</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'success.main', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={285}
                    duration={5}
                    className={classes.countValue}
                  />
                  वटा
                </Typography>
                <Typography variant="body1">जम्मा आयुर्वेद केन्द्र</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'text.primary', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={54985}
                    duration={5}
                    className={classes.countValue}
                  />
                  जना
                </Typography>
                <Typography variant="body1">जम्मा सेवाग्राहि</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartDataForTotalDisease;