import React, { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import { Box, Grid, TextField } from "@mui/material"
import CustomModal from "../../../components/modal/CustomModal"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import NepaliCalender from "../../../components/nepali-calender"
import MedicineDistributionRegister from "../../users/components/register/medicine-distribution-register/MedicineDistributionRegister"
import { useForm } from "react-hook-form"
import { ERROR, GENDER_OPTIONS, PATIENT_TYPES, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG } from "../../../utils/constants"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"

const MedicineDistributionForm = () => {
  const [modalTitle, setModelTitle] = useState("औषधी वितरण ")
  const [showMedicineDistributionFormModal, setshowMedicineDistributionFormModal] = useState(false)
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [getTableData, setGetTableData] = useState(false);
  const [medicineDistributionData, setMedicineDistributionData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [medicineOptions, setMedicineOptions] = useState([]);

  const openMedicineDistributionFormModal = () => {
    setshowMedicineDistributionFormModal(true)
  }

  const closeMedicineDistributionFormModal = () => {
    reset()
    setshowMedicineDistributionFormModal(false);
    setMedicineOptions([]);
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  useEffect(() => {
    register("gender", { required: true });
    register("patientType", { required: true });
    register("dartaaMiti", { required: true });
    register("medicineName");
  }, [register]);

  useEffect(() => {
    getMedicineOptions();
  }, []);

  const submitMedicineDistribution = (data) => {
    AHMIS.post(API_URL.medicineDistribution, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeMedicineDistributionFormModal()
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getMedicineDistributionData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.medicineDistribution}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setMedicineDistributionData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const medicineDistributionEdit = (id) => {
    AHMIS.get(API_URL.medicineDistribution + "/" + id)
      .then(response => {
        getMedicineOptions();
        setModalDefaultValues(response?.data);
        setshowMedicineDistributionFormModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getMedicineOptions = () => {
    AHMIS.get(API_URL.medicineList)
      .then((response) => {
        let medicineOption = [];
        medicineOption = response.data?.map((medicineInfo) => ({ label: `${medicineInfo.medicineName} (${medicineInfo.dose} ${medicineInfo.medicineUnit})`, value: medicineInfo.id }))
        setMedicineOptions(medicineOption)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }
  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getMedicineDistributionData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="औषधी वितरण रजिष्टर"
          handleModalOpen={openMedicineDistributionFormModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getMedicineDistributionData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showMedicineDistributionFormModal}
        onModalSubmit={handleSubmit(submitMedicineDistribution)}
        onModalClose={closeMedicineDistributionFormModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                showDefaultDate
                label="दर्ता मिति"
                required
                defaultValue={modalDefaultValues?.dartaaMiti}
                onChange={handleDateChange}
              />
            </Box>
            {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवाग्राहीको किसिम"
              name="patientType"
              required
              options={PATIENT_TYPES}
              defaultValue={modalDefaultValues?.patientType}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.patientType && errors.patientType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              required
              defaultValue={modalDefaultValues?.dartaaNumber}
              {...register("dartaaNumber", { required: true })}
            />
            {errors?.dartaaNumber && errors.dartaaNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="patientFirstName"
              autoComplete="on"
              fullWidth
              defaultValue={modalDefaultValues?.patientFirstName}
              required
              {...register("patientFirstName", { required: true })}
            />
            {errors?.patientFirstName && errors.patientFirstName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको थर"
              type="text"
              size="small"
              variant="outlined"
              name="patientLastName"
              autoComplete="on"
              fullWidth
              required
              defaultValue={modalDefaultValues?.patientLastName}
              {...register("patientLastName", { required: true })}
            />
            {errors?.patientLastName && errors.patientLastName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.gender}
              options={GENDER_OPTIONS}
              required
            />
            {errors?.gender && errors.gender?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="औषधिको नाम"
              size="small"
              variant="outlined"
              name="medicineName"
              autoComplete="on"
              onChange={handleCustomReactSelectChange}
              options={medicineOptions}
              fullWidth
              required
              defaultValue={modalDefaultValues?.medicineName}
              isMulti
            />
            {errors?.medicineName && errors.medicineName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
      </CustomModal>
      <MedicineDistributionRegister
        onEditRow={medicineDistributionEdit}
        tableData={medicineDistributionData}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default MedicineDistributionForm;