import { Box, Card } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';

const ChartDataBySewaByMonth = () => {
  const options = {
    chart: {
      height: 328,
      type: 'line',
      zoom: {
        enabled: false
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9
      }
    },
    grid: {
      show: true,
      padding: {
        bottom: 0
      }
    },
    title: {
      text: "चालु आ. व. को सेवाको प्रकार अनुसार सेवाग्राही विवरण",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    xaxis: {
      categories: ["साउन", "भदौ", "आश्विन", "कार्तिक", "मंसिर", "पौष", "माघ", "फागुन", "चैत", "बैशाख", "जेष्ठ",],
      title: {
        text: "महिना"
      }
    },
    yaxis: {
      title: {
        text: "सेवा लिएको सख्या "
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: -20,
    }
  };

  const series = [
    {
      name: "सामान्य उपचार",
      data: [800, 200, 200, 100, 1000, 2500, 1300, 900, 800, 400, 700]
    },
    {
      name: "पञ्चकर्म (पूर्वकर्म/उपक्रम) सेवा",
      data: [600, 1700, 1800, 400, 3000, 2200, 700, 1000, 3000, 1500, 1200]
    },
    {
      name: "शल्य सेवा ",
      data: [400, 1500, 1500, 500, 2500, 700, 1200, 1400, 1300, 1100]
    },
    {
      name: "योग सेवा",
      data: [1500, 1200, 1300, 700, 2000, 1400, 100, 200, 1200, 1000, 1800]
    },
    {
      name: "जेष्ठ नागरिक सेवा",
      data: [1300, 900, 1100, 1000, 1500, 500, 1500, 1800, 1300, 1500]
    },
    {
      name: "स्तनपायी आमा तथा मातृशिशु सेवा",
      data: [1200, 600, 800, 1200, 1200, 500, 100, 600, 100, 500, 800]
    },
    {
      name: "फिजियोथेरापी सेवा",
      data: [200, 500, 700, 1500, 1000, 100, 1900, 1400, 1200, 1000, 1800]
    },
    {
      name: "होमियोप्याथी सेवा",
      data: [1500, 300, 200, 1600, 800, 700, 1200, 100, 1800, 200, 100]
    },
    {
      name: "प्राकृतिक चिकित्सा सेवा",
      data: [1000, 100, 1100, 1800, 500, 300, 1400, 500, 1500, 600, 1800]
    }
  ];

  return (
    <>
      <Box mt={2}>
        <Card>
          <Chart
            options={options}
            series={series}
            type="line"
            height={328}
          />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataBySewaByMonth;
