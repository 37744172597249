import { Box, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal"
import TherapeuticYogaSewaRegister from "../../users/components/register/therapeutic-yoga-sewa-register/TherapeuticYogaSewaRegister"
import NepaliCalender from "../../../components/nepali-calender"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import { useForm } from "react-hook-form"
import { CASTE_CODES, ERROR, GENDER_OPTIONS, PATIENT_TYPES, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import { AGE_UNITS } from "../../../utils/constants/forms"
import { AppMisc } from "../../../misc/appMisc"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"

const TherapeuticYogaSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("उपचारात्मक योग सेवा रजिष्टर")
  const [showTherapeuticYogaModal, setShowTherapeuticYogaModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [getTableData, setGetTableData] = useState(false);
  const [therapeuticTableData, setTherapeuticTableData] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [showtherapeuticSewaDeleteModal, setShowtherapeuticSewaDeleteModal] = useState(false);
  const [therapeuticSewaDeleteId, setTherapeuticSewaDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  useEffect(() => {
    register("district", { required: true });
    register("palikaName", { required: true });
    register("gender", { required: true });
    register("castCode", { required: true });
    register("ageUnit", { required: true });
    register("sewaType", { required: true });
    register("patientType", { required: true });
    register("dateOfBirth", { required: true });
    register("dartaaMiti", { required: true });
    register("gentleExerciseStartDate");
    register("gentleExerciseEndDate");
    register("postureStartDate");
    register("postureEndDate");
    register("pranayamaStartDate");
    register("pranayamaEndDate");
    register("shatkarmaStartDate");
    register("shatkarmaEndDate");
    register("dhyanStartDate");
    register("dhyanEndDate");
    register("otherStartDate");
    register("otherendDate");
  }, [register]);

  // useEffect(() => {
  //   getTherapeuticData();
  // }, []);

  useEffect(() => {
    showTherapeuticYogaModal && reset();
  }, [reset, showTherapeuticYogaModal])

  const openTherapeuticYogaModal = () => {
    setShowTherapeuticYogaModal(true);
  }

  const closeTherapeuticYogaModal = () => {
    setShowTherapeuticYogaModal(false)
    setModalDefaultValues(true);
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const submitTherapeuticYogSewa = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.therapeuticSewaRegister, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeTherapeuticYogaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getTherapeuticData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.therapeuticSewaRegister}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setTherapeuticTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const therapeuticSewaEdit = (id) => {
    AHMIS.get(API_URL.therapeuticSewaRegister + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowTherapeuticYogaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closetherapeuticSewaDeleteModal = () => {
    setShowtherapeuticSewaDeleteModal(false);
    setTherapeuticSewaDeleteId(null);
    reset();
  }

  const onDeletetherapeuticSewaRow = (id) => {
    setShowtherapeuticSewaDeleteModal(true);
    setTherapeuticSewaDeleteId(id);
  }

  const deletetherapeuticSewaRow = (data) => {
    AHMIS.delete(API_URL.therapeuticSewaRegister + "/" + therapeuticSewaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowtherapeuticSewaDeleteModal(false);
          setTherapeuticSewaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getTherapeuticData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="उपचारात्मक योग सेवा रजिष्टर"
          handleModalOpen={openTherapeuticYogaModal}
          getRegisterData={getTherapeuticData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showTherapeuticYogaModal}
        onModalSubmit={handleSubmit(submitTherapeuticYogSewa)}
        onModalClose={closeTherapeuticYogaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                showDefaultDate
                label="दर्ता मिति"
                onChange={handleDateChange}
                defaultDate={modalDefaultValues?.dartaaMiti}
              />
              {errors?.dartaaMiti && errors.dartaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवाग्राहीको किसिम"
              name="patientType"
              options={PATIENT_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.patientType}
            />
            {errors?.patientType && errors.patientType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.dartaaNumber}
              {...register("dartaaNumber", { required: true })}
            />
            {errors?.dartaaNumber && errors.dartaaNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="patientFirstName"
              autoComplete="on"
              fullWidth
              defaultValue={modalDefaultValues?.patientFirstName}
              {...register("patientFirstName", { required: true })}
            />
            {errors?.patientFirstName && errors.patientFirstName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको थर"
              type="text"
              size="small"
              variant="outlined"
              name="patientLastName"
              autoComplete="on"
              fullWidth
              defaultValue={modalDefaultValues?.patientLastName}
              {...register("patientLastName", { required: true })}
            />
            {errors?.patientLastName && errors.patientLastName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              options={GENDER_OPTIONS}
              defaultValue={modalDefaultValues?.gender}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.gender && errors.gender?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="date-of-birth"
                label="जन्म मिति"
                name="dateOfBirth"
                defaultDate={modalDefaultValues?.dateOfBirth}
                onChange={handleDateChange}
              />
            </Box>
            {errors?.dateOfBirth && errors.dateOfBirth?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.age}
              {...register("age", { render: true })}
            />
            {errors?.age && errors.age?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              options={AGE_UNITS}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.ageUnit}
            />
            {errors?.ageUnit && errors.ageUnit?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="जाती"
              size="small"
              name="castCode"
              options={CASTE_CODES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.castCode}
            />
            {errors?.casteCode && errors.castCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="जिल्ला"
              name="district"
              variant="outlined"
              onChange={handleDistrictChange}
              options={districtOptions}
              defaultValue={modalDefaultValues?.district}
            />
            {errors?.district && errors.district?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="नगर/गाउँपालिका"
              name="palikaName"
              variant="outlined"
              options={palikaOptions}
              onChange={handlePalikaNameChange}
              defaultValue={modalDefaultValues?.palikaName}
            />
            {errors?.palikaName && errors.palikaName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="वडा नं"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.wardNumber}
              {...register("wardNumber", { required: true })}
            />
            {errors?.wardNumber && errors.wardNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सम्पर्क नम्बर"
              size="small"
              variant="outlined"
              name="phoneNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.phoneNumber}
              {...register("phoneNumber", { required: true })}
            />
            {errors?.phoneNumber && errors.phoneNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रेषण भई आएको संस्था"
              size="small"
              variant="outlined"
              name="sentFromOrganizationName"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.sentFromOrganizationName}
              {...register("sentFromOrganizationName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रेषण भई आएको संस्था"
              size="small"
              variant="outlined"
              name="nameInstitutionAttended"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.sentFromOrganizationName}
              {...register("nameInstitutionAttended")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रक्तचाप"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure", { required: true })}
            />
            {errors?.nature && errors.nature?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature", { required: true })}
            />
            {errors?.nature && errors.nature?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight", { required: true })}
            />
            {errors?.weight && errors.weight?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रोग"
              size="small"
              variant="outlined"
              name="disease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.disease}
              {...register("disease", { required: true })}
            />
            {errors?.disease && errors.disease?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="FBS"
              size="small"
              variant="outlined"
              name="fbs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("fbs", { required: true })}
            />
            {errors?.sequenceNumber && errors.sequenceNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.test}
              {...register("test", { required: true })}
            />
            {errors?.test && errors.test?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="gentle-exercise-start-date"
              name="gentleExerciseStartDate"
              label="सुरू मिति"
              defaultDate={modalDefaultValues?.gentleExerciseStartDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="gentle-exercise-end-date"
              name="gentleExerciseEndDate"
              label="पूरा गरेको मिति"
              defaultDate={modalDefaultValues?.gentleExerciseEndDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="gentleExerciseComplexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.gentleExerciseComplexity}
              {...register("gentleExerciseComplexity",)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="posture-start-date"
              name="postureStartDate"
              label="सुरू मिति"
              defaultDate={modalDefaultValues?.postureStartDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="posture-end-date"
              name="postureEndDate"
              label="पूरा गरेको मिति"
              defaultDate={modalDefaultValues?.postureEndDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="postureComplexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("postureComplexity",)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="pranayama-start-date"
              name="pranayamaStartDate"
              label="सुरू मिति"
              defaultDate={modalDefaultValues?.pranayamaStartDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="pranayama-end-date"
              name="pranayamaEndDate"
              label="पूरा गरेको मिति"
              defaultDate={modalDefaultValues?.pranayamaEndDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="pranayamaComplexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.pranayamaComplexity}
              {...register("pranayamaComplexity",)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="shat-karma-start-date"
              name="shatkarmaStartDate"
              label="सुरू मिति"
              defaultDate={modalDefaultValues?.startDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="shat-karma-end-date"
              name="shatkarmaEndDate"
              label="पूरा गरेको मिति"
              defaultDate={modalDefaultValues?.shatkarmaEndDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="shatkarmaComplexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.shatkarmaComplexity}
              {...register("shatkarmaComplexity",)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="dhyan-start-date"
              name="dhyanStartDate"
              label="सुरू मिति"
              defaultDate={modalDefaultValues?.dhayanStartDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="dhyan-end-date"
              name="dhyanEndDate"
              label="पूरा गरेको मिति"
              defaultDate={modalDefaultValues?.dhyanEndDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complexityDhyan"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexityDhyan}
              {...register("complexityDhyan",)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="other-start-date"
              name="otherStartDate"
              label="सुरू मिति"
              defaultDate={modalDefaultValues?.otherStartDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="other-end-date"
              name="otherendDate"
              label="पूरा गरेको मिति"
              defaultDate={modalDefaultValues?.otherendDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complexityOther"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexityOther}
              {...register("complexityOther",)}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showtherapeuticSewaDeleteModal}
        closeDeleteModel={closetherapeuticSewaDeleteModal}
        deleteRecord={deletetherapeuticSewaRow}
      />
      <TherapeuticYogaSewaRegister
        onEditRow={therapeuticSewaEdit}
        tableData={therapeuticTableData}
        deleteRow={onDeletetherapeuticSewaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default TherapeuticYogaSewaForm;