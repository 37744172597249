import { LOGOUT_SUCCESS } from "../utils/constants";
import { ADD_MEDICINE_DETAIL, SAVE_MEDICINE_INFO } from "../utils/constants/redux";

const reducer = (medicineInfo = null, action) => {
  switch (action.type) {
    case SAVE_MEDICINE_INFO:
      return action.payload;
    case ADD_MEDICINE_DETAIL:
      return [...medicineInfo, action.payload];
    case LOGOUT_SUCCESS:
      return null;
    default:
      return medicineInfo;
  }
}

export default reducer;