import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  btnContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderColor: "rgba(48, 48, 48, 0.5)",
    display:"flex" ,
    justifyContent:"flex-end",
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  removeAimedCountDetailsContainer: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    color: theme.palette.secondary.main,
  },
  addAimedCountDetailsBtnContainer: {
    paddingTop: theme.spacing(1),
  },
  aimedCountDetailContainer: {
    paddingTop: theme.spacing(2),
    position: "relative"
  },
  hiddenInput: {
    display: "none",
  },
  imageContainer: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.divider,
    borderRadius: theme.spacing(.5),
    minHeight: theme.spacing(13),
    minWidth: theme.spacing(19)
  },
  logo: {
    maxWidth: theme.spacing(16)
  }
}));
export default styles;