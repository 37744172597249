import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { CASTE_CODES, FEMALE, MALE, NO_RECORDS_FOUND } from '../../../../../utils/constants';
import { AppUtils } from '../../../../../utils/appUtils';
import { Delete, Edit } from '@mui/icons-material';

const NcdRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }}  >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="NCD  Register" ref={tableRef}>
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={3}>क्र.सं.</TableCell>
                <TableCell colSpan={6}>सेवा सम्बन्धि जानकारी</TableCell>
                <TableCell colSpan={5}>सेवाग्राहीको व्यक्तिगत जानकारी</TableCell>
                <TableCell colSpan={5}>ठेगाना/ सम्पर्क नं.</TableCell>
                <TableCell rowSpan={3}>Investigation</TableCell>
                <TableCell colSpan={2}>निदान (Diagnosis)</TableCell>
                <TableCell colSpan={5}>सेवाको प्रकार</TableCell>
                <TableCell rowSpan={3}>मृत्यु भएको </TableCell>
                <TableCell colSpan={2}>पुराना सेवाग्राहीहरुको लागि मात्र</TableCell>
                <TableCell colSpan={2}>प्रेषण</TableCell>
                <TableCell rowSpan={3} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>मूल दर्ता नम्बर</TableCell>
                <TableCell rowSpan={2}>अन्य सेवा दर्ता नम्बर कोड</TableCell>
                <TableCell rowSpan={2}>मिति</TableCell>
                <TableCell rowSpan={2}>NCD/MH/ Injury/Disability</TableCell>
                <TableCell rowSpan={2}>नयाँ/ पुरानो</TableCell>
                <TableCell rowSpan={2}>सेवा दर्ता नम्बर </TableCell>
                <TableCell rowSpan={2}>सेवाग्राहीको नाम/थर</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={2}>जाती कोड</TableCell>
                </Tooltip>
                <TableCell rowSpan={2}>उमेर</TableCell>
                <TableCell colSpan={2}>लिङ्ग</TableCell>
                <TableCell rowSpan={2}>जिल्ला</TableCell>
                <TableCell rowSpan={2}>नगर/गाउँपालिका</TableCell>
                <TableCell rowSpan={2}>वडा नं.</TableCell>
                <TableCell rowSpan={2}>टोल</TableCell>
                <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
                <TableCell rowSpan={2}>Diagnosis</TableCell>
                <TableCell rowSpan={2}>ICD Code</TableCell>
                <TableCell rowSpan={2}>औषधि</TableCell>
                <TableCell rowSpan={2}>मनोसामाजिक परामर्श </TableCell>
                <TableCell rowSpan={2}>परामर्श</TableCell>
                <TableCell rowSpan={2}>अन्य</TableCell>
                <TableCell rowSpan={2}>औषधि, उपचार र परामर्श सेवाको बिस्तृत जानकारी</TableCell>
                <TableCell rowSpan={2}>उपचार नियमितता(Rx compliance)</TableCell>
                <TableCell rowSpan={2}>सुधारको अवस्था</TableCell>
                <TableCell rowSpan={2}>प्रेषण भइ आएको संस्था/ व्यक्तिको नाम  र पेशा</TableCell>
                <TableCell rowSpan={2}>प्रेषण गरिएको संस्थाको नाम</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>महिला</TableCell>
                <TableCell>पुरुष</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {
                mainRegisterData?.length > 0 ? (
                  mainRegisterData.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                      <TableCell>{row.muldartaaNumber || "-"}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{row.sewaDartaaNumber || "-"}</TableCell>
                      <TableCell>{row.patientFullName || "-"}</TableCell>
                      <TableCell>{row.casteCode || "-"}</TableCell>
                      <TableCell>{row.age || "-"}</TableCell>
                      <TableCell>{row?.gender === FEMALE ? (row?.female) : "-"}</TableCell>
                      <TableCell>{row?.gender === MALE ? (row?.male) : "-"}</TableCell>
                      <TableCell>{row.district || "-"}</TableCell>
                      <TableCell>{row.palikaName || "-"}</TableCell>
                      <TableCell>{row.wardNumber || "-"}</TableCell>
                      <TableCell>{row.gaunOrTole || "-"}</TableCell>
                      <TableCell>{row.phoneNumber || "-"}</TableCell>
                      <TableCell>{row.investigation || "-"}</TableCell>
                      <TableCell>{row.diagnosis || "-"}</TableCell>
                      <TableCell>{row.icdCode || "-"}</TableCell>
                      <TableCell>{row.medicine || "-"}</TableCell>
                      <TableCell>{row.manosamajikaParamarsa || "-"}</TableCell>
                      <TableCell>{row.paramarsa || "-"}</TableCell>
                      <TableCell>{row.others || "-"}</TableCell>
                      <TableCell>{row.ausadhiUpacaraRaParamarsaSevakobistrrtaJanakari || "-"}</TableCell>
                      <TableCell>{row.mrrtyuBhaeko || "-"}</TableCell>
                      <TableCell>{row.upacaraNiyamitata || "-"}</TableCell>
                      <TableCell>{row.sudharakoAvastha || "-"}</TableCell>
                      <TableCell>{row.sendFromOtherOrganization || "-"}</TableCell>
                      <TableCell>{row.presanagariEkoSansthakoNama || "-"}</TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <IconButton aria-label="edit" onClick={() => props.onEditRow(row.id)}>
                            <Edit fontSize="small" />
                          </IconButton>
                          <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <IconButton >
                              <Delete fontSize="small" color='secondary' onClick={() => props.deleteRow(row.id)} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="22" size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default NcdRegister;