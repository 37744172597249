import { Delete } from "@mui/icons-material";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NO_RECORDS_FOUND } from "../../../../../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";

const AddMedicine = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true);
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }}>
          <Table
            classes={{ root: "table-bordered normal-spacing" }}
            size="small"
            aria-label="Add-Medicine"
          >
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell>औषधि सूची</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Dose</TableCell>
                <TableCell>सम्पादन</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }} ref={tableRef}>
              {
                mainRegisterData?.length > 0 ? (
                  mainRegisterData?.map((row, index) => (
                    <TableRow key={row?.id}>
                      <TableCell>{row?.medicineName}</TableCell>
                      <TableCell>{row?.medicineUnit}</TableCell>
                      <TableCell>{row?.dose}</TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Tooltip
                            title="औषधि विवरण हटाउन यहा क्लिक गर्नुहोस"
                            placement="top"
                            arrow
                          >
                            <IconButton>
                              <Delete fontSize="small" color="secondary" onClick={() => props.onDeleteRow(row?.id)} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="22" size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};

export default AddMedicine;