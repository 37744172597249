import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"

const DeathInformation = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Death-Information">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2} colSpan={2}>Death Information</TableCell>
              <TableCell>Male</TableCell>
              <TableCell>Female</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>Hospital Death</TableCell>
              <TableCell>Early Neonatal</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hospital Death</TableCell>
              <TableCell>Late Neonatal</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hospital Death</TableCell>
              <TableCell>Maternal (All)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hospital Death</TableCell>
              <TableCell>Post-Therapy</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hospital Death</TableCell>
              <TableCell>Other</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>Brought Dead</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default DeathInformation;