import React, { useEffect, useState } from 'react'
import PhysiotherapySewaBibaranRegister from '../../../users/components/register/physiotherapy-register/helper/PhysiotherapySewaBibaranRegister';
import { Box, Grid, TextField } from '@mui/material';
import RegisterHeader from '../../../../components/register-header/RegisterHeader';
import { AppUtils } from '../../../../utils/appUtils';
import { ERROR, ID, PHYSIO_THERAPY_CODE, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../../utils/constants';
import { useForm } from 'react-hook-form';
import AHMIS, { API_URL } from '../../../../api/api';
import AddAlertMessage from '../../../../components/alert/Alert';
import CustomModal from '../../../../components/modal/CustomModal';
import CustomReactSelect from '../../../../components/custom-react-select/CustomReactSelect';
import NepaliCalender from '../../../../components/nepali-calender';
import DeleteRegisterRecordConformation from '../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';

const PhysiotherapySewaBibaranForm = () => {
  const [modalTitle, setModalTitle] = useState("अकुपञ्चर सेवा सेवा विवरण रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showPhysiotherapyBibaranModal, setShowPhysiotherapyBibaranModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [physiotherapyBibaranSewaBibaranTableData, setPhysiotherapyBibaranSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [showPhysiotherapyBibaranDeleteModal, setShowPhysiotherapyBibaranDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [physiotherapyBibaranDeleteId, setPhysiotherapyBibaranDeleteId] = useState(null);
  const physiotherapyRegisterId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    showPhysiotherapyBibaranModal && reset();
  }, [reset, showPhysiotherapyBibaranModal]);

  const openPhysiotherapyBibaranModal = () => {
    setShowPhysiotherapyBibaranModal(true);
  };

  const closePhysiotherapyBibaranModal = () => {
    setShowPhysiotherapyBibaranModal(false);
    setModalDefaultValues();
    reset();
  }


  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitPhysiotherapyBibaranRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    data.physiotherapyServiceRegistrationId = physiotherapyRegisterId;
    AHMIS.post(API_URL.physiotherapySewaService, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closePhysiotherapyBibaranModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getPhysiotherapyBibaranSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.shalyaChikitsa}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&physiotherapyServiceRegistrationId=${physiotherapyRegisterId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setPhysiotherapyBibaranSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const physiotherapyBibaranSewaEdit = (id) => {
    AHMIS.get(API_URL.physiotherapySewaService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowPhysiotherapyBibaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closePhysiotherapyBibaranDeleteModal = () => {
    setShowPhysiotherapyBibaranDeleteModal(false);
    setPhysiotherapyBibaranDeleteId(null);
  }

  const onDeletePhysiotherapyBibaranRow = (id) => {
    setShowPhysiotherapyBibaranDeleteModal(true);
    setPhysiotherapyBibaranDeleteId(id);
  }

  const deletePhysiotherapyBibaranSewaRow = (data) => {
    AHMIS.delete(API_URL.physiotherapySewaService + "/" + physiotherapyBibaranDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowPhysiotherapyBibaranDeleteModal(false);
          setPhysiotherapyBibaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getPhysiotherapyBibaranSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="फिजियोथेरापी सेवा रजिष्टर सेवा विवरण "
          handleModalOpen={openPhysiotherapyBibaranModal}
          getRegisterData={getPhysiotherapyBibaranSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showPhysiotherapyBibaranModal}
        onModalSubmit={handleSubmit(submitPhysiotherapyBibaranRegister)}
        onModalClose={closePhysiotherapyBibaranModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="मिति"
              name="date"
              defaultDate={modalDefaultValues?.date}
              onChange={handleDateChange}
            />
            {errors?.date && errors.date?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवा"
              name="sewaCode"
              variant="outlined"
              options={PHYSIO_THERAPY_CODE}
              onChange={handleSelectChange}
              defaultValue={modalDefaultValues?.therapyCode}
            />
            {errors?.therapyCode && errors.therapyCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexity}
              {...register("complexity", { required: true })}
            />
            {errors?.complexity && errors.complexity?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण /सल्लाह"
              size="small"
              variant="outlined"
              name="testing"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("testing", { required: true })}
            />
            {errors?.testing && errors.testing?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="कैफियत"
              size="small"
              variant="outlined"
              name="kaifiyat"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("kaifiyat", { required: true })}
            />
            {errors?.testing && errors.testing?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showPhysiotherapyBibaranDeleteModal}
        closeDeleteModel={closePhysiotherapyBibaranDeleteModal}
        deleteRecord={deletePhysiotherapyBibaranSewaRow}
      />
      <PhysiotherapySewaBibaranRegister
        onEditRow={physiotherapyBibaranSewaEdit}
        tableData={physiotherapyBibaranSewaBibaranTableData}
        deleteRow={onDeletePhysiotherapyBibaranRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default PhysiotherapySewaBibaranForm;
