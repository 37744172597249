import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  btnContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: "1px solid rgba(0,0,0,0.2)",
    display:"flex" ,
    justifyContent:"flex-end",
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  }
}));
export default styles;