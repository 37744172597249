import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React from 'react'
import { NO_RECORDS_FOUND } from '../../../../../utils/constants';
import { AppUtils } from '../../../../../utils/appUtils';
import { Delete, Edit } from '@mui/icons-material';
import ChartDataByByBittayaBibaran from '../../../../../components/chart/ChartDataByByBittayaBibaran';
import { AppMisc } from '../../../../../misc/appMisc';
import { PROGRAM_NAME } from '../../../../../utils/constants/forms';

const BittayaBibaranRegister = (props) => {

  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}>क्र.सं.</TableCell>
              <TableCell rowSpan={2}>कार्यक्रम नाम</TableCell>
              <TableCell rowSpan={2}>विनियोजित बजेट</TableCell>
              <TableCell rowSpan={2}>खर्च रकम</TableCell>
              <TableCell rowSpan={2}>बाँकी रकम</TableCell>
              <TableCell rowSpan={2} >सम्पादन</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {
              props?.tableData?.length > 0 ? (
                props?.tableData?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{row?.programName && AppMisc.findLabelFromValue(PROGRAM_NAME, row?.programName)}</TableCell>
                    <TableCell>{row.biniyojitBajet}</TableCell>
                    <TableCell>{row.karchaRakam}</TableCell>
                    <TableCell>{row.remainingMoney}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton aria-label="edit" onClick={() => props.onEditRow(row.id)}>
                          <Edit fontSize="small" />
                        </IconButton>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color='secondary' onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="22" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Box width="100%">
        <ChartDataByByBittayaBibaran
          chartData={props.tableData}
        />
      </Box>
    </>
  )
}

export default BittayaBibaranRegister;
