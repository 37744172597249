import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const TherapeuticYogaServiceReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">३. उपचारात्मक  योग सेवा प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Therapeutic-Yoga-Service-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}>योग अनुसारको सेवा विवरण</TableCell>
              <TableCell colSpan={2}>सेवा सुरू गर्नेको जम्मा संख्या</TableCell>
              <TableCell colSpan={2}>सेवा पुरा गर्नेको जम्मा संख्या</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell rowSpan={2}>पुरुष </TableCell>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell rowSpan={2}>पुरुष </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>सुक्ष्म व्यायाम</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>आसन</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>आसन</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>प्राणायाम</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>षट्कर्म</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ध्यान</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>अन्य</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>जम्मा संख्या</TableCell>
              <TableCell colSpan={2}></TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TherapeuticYogaServiceReport;