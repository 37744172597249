import { CreditCard, Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { FEMALE, GENDER_OPTIONS, MALE, NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { AppMisc } from "../../../../../misc/appMisc";
import { NEW_PATIENT, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";
import InfiniteScroll from "react-infinite-scroll-component";

const MedicineDistributionRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.registerData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>क्र.सं.</TableCell>
                <TableCell rowSpan={2}>दर्ता मिति</TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <TableCell colSpan={2}>लिंग</TableCell>
                <TableCell rowSpan={2}>अविपतकर चुर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>हिङग्वाष्टक चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>महायोगराज गुग्गुल १० ग्राम</TableCell>
                <TableCell rowSpan={2}>सितोपलादि चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>पुष्यानुग चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>मृगमदासव १५ एम. एल</TableCell>
                <TableCell rowSpan={2}>सर्पगन्धाघन वटि २५ ग्राम</TableCell>
                <TableCell rowSpan={2}>निम्वादि चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>गन्धक रसायन १० ग्राम</TableCell>
                <TableCell rowSpan={2}>खरिदादि वटी १० ग्राम</TableCell>
                <TableCell rowSpan={2}>षडविन्दु तेल १० एम. एल</TableCell>
                <TableCell rowSpan={2}>योगराज गुग्गुल २५ ग्राम</TableCell>
                <TableCell rowSpan={2}>महासुदर्शन चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>महाशंख वटी चूर्ण  १० ग्राम</TableCell>
                <TableCell rowSpan={2}>विल्वादी चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>जात्यादी तेल ६० एम. एल.</TableCell>
                <TableCell rowSpan={2}>कैशोर गुग्गुल २५ ग्राम</TableCell>
                <TableCell rowSpan={2}>चन्द्रप्रभावटी २५ ग्राम </TableCell>
                <TableCell rowSpan={2}>त्रिफला चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>अश्वगन्धा चूर्ण ५० ग्राम</TableCell>
                <TableCell rowSpan={2}>अश्वगन्धा चूर्ण १०० ग्राम</TableCell>
                <TableCell rowSpan={2}>दशमुल तेल ६० एम. एल.</TableCell>
                <TableCell rowSpan={2}>शतावरी चूर्ण १०० ग्राम</TableCell>
                <TableCell rowSpan={2}>ज्वानो १०० ग्राम</TableCell>
                <TableCell rowSpan={2}>ज्वानो १०० ग्राम</TableCell>
                <TableCell rowSpan={2}>अन्य</TableCell>
                <TableCell rowSpan={2} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पहिलो पटक</TableCell>
                <TableCell>थप पटक</TableCell>
                <TableCell>नाम</TableCell>
                <TableCell>थर</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>पुरुष</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {
                mainRegisterData?.length > 0 ? (
                  mainRegisterData?.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                      <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row?.dartaaNumber) : "-"}</TableCell>
                      <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row?.dartaaNumber) : "-"}</TableCell>
                      <TableCell>{row?.patientFirstName && row?.patientFirstName}</TableCell>
                      <TableCell>{row?.patientLastName && row?.patientLastName}</TableCell>
                      <TableCell>{row?.gender === FEMALE ? AppMisc.findLabelFromValue(GENDER_OPTIONS, row.gender) : "-"}</TableCell>
                      <TableCell>{row?.gender === MALE ? AppMisc.findLabelFromValue(GENDER_OPTIONS, row.gender) : "-"}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <IconButton aria-label="edit">
                            <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                          </IconButton>
                          <Tooltip title="स्वास्थ्य सेवा कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <CreditCard fontSize="small" />
                          </Tooltip>
                          <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <IconButton >
                              <Delete fontSize="small" color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="35" size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default MedicineDistributionRegister
