import { Delete, Edit } from '@mui/icons-material'
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { AYURVEDIC_THERAPY, CASTE_CODES, FEMALE, MALE, MONTH, NO, NO_RECORDS_FOUND, POLICE_CASE, YEAR, YES } from '../../../../../utils/constants';
import { AppUtils } from '../../../../../utils/appUtils';
import { AppMisc } from '../../../../../misc/appMisc';
import { DISEASE_CODE, NEW_PATIENT, PREVIOUS_PATIENT } from '../../../../../utils/constants/forms';

const DisChargeRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }
  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Gausahar-Sahari-Clinic-Paramarsha-Sewa">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={3}>SN</TableCell>
                <TableCell colSpan={2}>Inpatient No</TableCell>
                <TableCell colSpan={2}> Discharge</TableCell>
                <TableCell colSpan={2}> Admission</TableCell>
                <TableCell rowSpan={3}> Length of stay (days)</TableCell>
                <TableCell rowSpan={3}> Name </TableCell>
                <TableCell rowSpan={3}> Family name</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={3}>Caste/ Ethnicity Code*</TableCell>
                </Tooltip>
                <TableCell colSpan={12}>Age (Years)/ Sex </TableCell>
                <TableCell colSpan={3}>Address</TableCell>
                <TableCell rowSpan={3}>Ward/ Bed No.</TableCell>
                <TableCell rowSpan={3}>Treatment/Medicine Prescribed</TableCell>
                <Tooltip title={
                  <>
                    {AYURVEDIC_THERAPY.map(ayurvedicTherapy => (
                      <Typography key={ayurvedicTherapy.value} variant="caption"> {ayurvedicTherapy.label} </Typography>
                    ))}
                  </>
                }
                  placement="top"
                  arrow>
                  <TableCell rowSpan={3}>Ayurvedic Therapy</TableCell>
                </Tooltip>
                <TableCell colSpan={2}>Complication</TableCell>
                <TableCell rowSpan={3}>Final diagnosis at discharge</TableCell>
                <TableCell rowSpan={3}>Disease Code</TableCell>
                <TableCell rowSpan={3}>Outcome (Code)**</TableCell>
                <TableCell colSpan={3}>If Died</TableCell>
                <TableCell colSpan={4}>Cost Estimation</TableCell>
                <TableCell colSpan={2}>Police Case</TableCell>
                <TableCell rowSpan={3}>Remarks</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>First Visit</TableCell>
                <TableCell rowSpan={2}>Repeat Visit</TableCell>
                <TableCell rowSpan={2}>Date</TableCell>
                <TableCell rowSpan={2}>Time</TableCell>
                <TableCell rowSpan={2}>Date</TableCell>
                <TableCell rowSpan={2}>Time</TableCell>
                <TableCell colSpan={2}>0-6/12</TableCell>
                <TableCell colSpan={2}>6/12-1</TableCell>
                <TableCell colSpan={2}>1-4</TableCell>
                <TableCell colSpan={2}>5-16</TableCell>
                <TableCell colSpan={2}>17-59</TableCell>
                <TableCell colSpan={2}>≥60</TableCell>
                <TableCell rowSpan={2}>District</TableCell>
                <TableCell rowSpan={2}>Municipality</TableCell>
                <TableCell rowSpan={2}>Contact Number </TableCell>
                <TableCell rowSpan={2}>Yes</TableCell>
                <TableCell rowSpan={2}>No</TableCell>
                <TableCell rowSpan={2}>&lt;48 Hrs</TableCell>
                <TableCell rowSpan={2}>&gt;48 Hrs</TableCell>
                <TableCell rowSpan={2}>Cause of death</TableCell>
                <TableCell rowSpan={2}>Full</TableCell>
                <TableCell rowSpan={2}>Partial</TableCell>
                <TableCell rowSpan={2}>Total cost exempted (NRs.)</TableCell>
                <TableCell rowSpan={2}>Code</TableCell>
                <TableCell rowSpan={2}>Yes</TableCell>
                <TableCell rowSpan={2}>No</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData.length > 0 ? (
                mainRegisterData.map((row, index) => (
                  <TableRow>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                    <TableCell>{row?.timeOfDischarge}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dateOfAdmission)}</TableCell>
                    <TableCell>{row?.timeOfAdmission || "-"}</TableCell>
                    <TableCell>{row?.lengthOfStayDate || "-"}</TableCell>
                    <TableCell>{row?.name || "-"}</TableCell>
                    <TableCell>{row?.familyName || "-"}</TableCell>
                    <TableCell>{row.casteCode || "-"}</TableCell>
                    <TableCell>{row.ageUnit === MONTH && row.gender === FEMALE && (row.age > 0 && row.age <= 6) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === MONTH && row.gender === MALE && (row.age > 0 && row.age <= 6) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === MONTH && row.gender === FEMALE && (row.age > 6 && row.age <= 24) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === MONTH && row.gender === MALE && (row.age > 6 && row.age <= 24) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && (row.age > 1 && row.age <= 4) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === YEAR && row.gender === MALE && (row.age > 1 && row.age <= 4) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && (row.age > 5 && row.age <= 16) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === YEAR && row.gender === MALE && (row.age > 5 && row.age <= 16) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && (row.age > 17 && row.age <= 59) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === YEAR && row.gender === MALE && (row.age > 17 && row.age <= 59) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && row.age >= 60 && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === MALE && row.age >= 60 && row.age}</TableCell>
                    <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                    <TableCell>{row?.municipality && AppMisc.getMunicipalityName(row?.municipality)}</TableCell>
                    <TableCell>{row?.contact || "-"}</TableCell>
                    <TableCell>{row?.wardNumber || "-"}</TableCell>
                    <TableCell>{row?.medicinePrescribed || "-"}</TableCell>
                    <TableCell>{row?.ayurvedicTherapy || "-"}</TableCell>
                    <TableCell>{row.policeCase === YES && AppMisc.findLabelFromValue(POLICE_CASE, row?.policeCase)}</TableCell>
                    <TableCell>{row.policeCase === NO && AppMisc.findLabelFromValue(POLICE_CASE, row?.policeCase)}</TableCell>
                    <TableCell>{row?.finalDiagnosisDischarge || "-"}</TableCell>
                    <TableCell>{row.diseaseCode && AppMisc.findLabelFromValue(DISEASE_CODE, row?.diseaseCode)}</TableCell>
                    <TableCell>{row?.outComesCode || "-"}</TableCell>
                    <TableCell>{row?.full || "-"}</TableCell>
                    <TableCell>{row?.partial || "-"}</TableCell>
                    <TableCell>{row?.causeOfDied || "-"}</TableCell>
                    <TableCell>{row?.costExemptionFull || "-"}</TableCell>
                    <TableCell>{row?.costExemptionPartial || "-"}</TableCell>
                    <TableCell>{row?.totalCostExempted || "-"}</TableCell>
                    <TableCell>{row?.code || "-"}</TableCell>
                    <TableCell>{row.policeCase === YES && AppMisc.findLabelFromValue(POLICE_CASE, row?.policeCase)}</TableCell>
                    <TableCell>{row.policeCase === NO && AppMisc.findLabelFromValue(POLICE_CASE, row?.policeCase)}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        <Tooltip title="Delete Discharge Register" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color='secondary' onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="45" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default DisChargeRegister;