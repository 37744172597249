import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Container, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AddAlertMessage from "../../../components/alert/Alert";
import { ENTER_VALID_EMAIL, REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../utils/constants/index";
import styles from "./style";
import AHMIS, { API_URL } from "../../../api/api";

export default function ForgetPassword(props) {
  const navigate = useNavigate()
  const classes = styles();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = data => {
    data.email = data?.email?.toLowerCase().trim();
    AHMIS.post(API_URL.forgetPassword + "?email=" + data.emailAddress)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === "success") {
          navigate("/");
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <h2 className="border-bottom-heading">कृपया तपाईंको इमेल ठेगाना टाइप गर्नुहोस्।</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <TextField
            fullWidth
            id="emailAddress"
            label="इमेल"
            type="email"
            size="small"
            variant="outlined"
            name="emailAddress"
            {...register("emailAddress", { required: true, pattern: /\S+@\S+\.\S+/ })}
          />
          {errors.emailAddress && errors.emailAddress.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          {errors.emailAddress && errors.emailAddress.type === "pattern" && <span className="error-message">{ENTER_VALID_EMAIL}</span>}
        </Box>
        <Box textAlign="right" className={classes.btnContainer}>
          <Box mr={1}>
            <Button
              variant="contained"
              color="secondary"
              type="reset"
            >
              रद्द गर्नुहोस
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              सुरक्षित गर्नुहोस
            </Button>
          </Box>
        </Box>
      </form>
      <Box textAlign="center" mt={5}>
        <Button
          color="primary"
          onClick={() => {
            navigate("/");
          }}
          className={classes.button}
          startIcon={<ArrowBack />}
        >
          Back To Home
        </Button>
      </Box>
    </Container>
  );
}