
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import ReportHeader from '../component/report-header/ReportHeader';

const AccpuntureReport = () => {
  return (
    <>
      <ReportHeader />
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Typography variant="body1" className="report-heading">फिजियोथेरापी प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="physiotherapy-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={2}> सेवाको विवरण</TableCell>
              <TableCell>Acupuncture (Needling)</TableCell>
              <TableCell>cupping</TableCell>
              <TableCell>Moxibustion</TableCell>
              <TableCell>Acupressure</TableCell>
              <TableCell>Blood Letting</TableCell>
              <TableCell>Others</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={2}>सेवाग्राही संख्या</TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>सेवा संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AccpuntureReport;
