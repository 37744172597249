import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import { CASTE_CODES, FEMALE, MALE, MONTH, NO, NO_RECORDS_FOUND, POLICE_CASE, YEAR, YES } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { NEW_PATIENT, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";
import { AppMisc } from "../../../../../misc/appMisc";

const EmergencyServiceRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }
  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef}>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Gausahar-Sahari-Clinic-Paramarsha-Sewa">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={3}>SN</TableCell>
                <TableCell colSpan={2}>Registration No</TableCell>
                <TableCell rowSpan={3}>Date of Registration </TableCell>
                <TableCell rowSpan={3}>TIme of Registration </TableCell>
                <TableCell rowSpan={3}> Name </TableCell>
                <TableCell rowSpan={3}> Family name</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={3}>Caste/ Ethnicity Code*</TableCell>
                </Tooltip>
                <TableCell colSpan={12}>Age (Years)/ Sex </TableCell>
                <TableCell colSpan={3}>Address</TableCell>
                <TableCell colSpan={2}>Guardian</TableCell>
                <TableCell colSpan={2}>Source of Admission</TableCell>
                <TableCell rowSpan={3}>Brought Dead</TableCell>
                <TableCell rowSpan={3}>Signs and Symptoms (Main Complaints on Admission)</TableCell>
                <TableCell rowSpan={3}>Investigations</TableCell>
                <TableCell rowSpan={3}>Diagnosis</TableCell>
                <TableCell rowSpan={3}>DiseaseCode</TableCell>
                <TableCell rowSpan={3}>Treatment / Medicines prescribed </TableCell>
                <TableCell colSpan={2}>Observation</TableCell>
                <TableCell rowSpan={3}> Discharge Date</TableCell>
                <TableCell rowSpan={3}> Discharge Time</TableCell>
                <TableCell colSpan={2}>If Died</TableCell>
                <TableCell colSpan={4}>Cost Exemption</TableCell>
                <TableCell rowSpan={3}>Gender violence</TableCell>
                <TableCell colSpan={2}>Police Case</TableCell>
                <TableCell rowSpan={3}>Remarks</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>First Visit</TableCell>
                <TableCell rowSpan={2}>Repeat Visit</TableCell>
                <TableCell colSpan={2}>0-6/12</TableCell>
                <TableCell colSpan={2}>6/12-1</TableCell>
                <TableCell colSpan={2}>1-4</TableCell>
                <TableCell colSpan={2}>5-16</TableCell>
                <TableCell colSpan={2}>17-59</TableCell>
                <TableCell colSpan={2}>≥60</TableCell>
                <TableCell rowSpan={2}>District</TableCell>
                <TableCell rowSpan={2}>Rural/Municipality</TableCell>
                <TableCell rowSpan={2}>Ward/Tole</TableCell>
                <TableCell rowSpan={2}>Name</TableCell>
                <TableCell rowSpan={2}>Contact Number </TableCell>
                <TableCell rowSpan={2}>Self</TableCell>
                <TableCell rowSpan={2}>Referred in (Name of HF)</TableCell>
                <TableCell rowSpan={2}>Yes</TableCell>
                <TableCell rowSpan={2}>No</TableCell>
                <TableCell rowSpan={2}>Code</TableCell>
                <TableCell rowSpan={2}>Cause of Death</TableCell>
                <TableCell rowSpan={2}>Full </TableCell>
                <TableCell rowSpan={2}>Partial </TableCell>
                <TableCell rowSpan={2}>Total Cost Exempted (NRs.)</TableCell>
                <TableCell rowSpan={2}>Code</TableCell>
                <TableCell rowSpan={2}>Yes</TableCell>
                <TableCell rowSpan={2}>No</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
                <TableCell>F</TableCell>
                <TableCell>M</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData.length > 0 ? (
                mainRegisterData.map((row, index) => (
                  <TableRow>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                    <TableCell>{row.timeOfRegistration || "-"}</TableCell>
                    <TableCell>{row.name || "-"}</TableCell>
                    <TableCell>{row.familyName || "-"}</TableCell>
                    <TableCell>{row.castCode || "-"}</TableCell>
                    <TableCell>{row.ageUnit === MONTH && row.gender === FEMALE && (row.age > 0 && row.age <= 6) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === MONTH && row.gender === MALE && (row.age > 0 && row.age <= 6) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === MONTH && row.gender === FEMALE && (row.age > 6 && row.age <= 24) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === MONTH && row.gender === MALE && (row.age > 6 && row.age <= 24) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && (row.age > 1 && row.age <= 4) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === YEAR && row.gender === MALE && (row.age > 1 && row.age <= 4) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && (row.age > 5 && row.age <= 16) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === YEAR && row.gender === MALE && (row.age > 5 && row.age <= 16) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && (row.age > 17 && row.age <= 59) && row.age}</TableCell>
                    <TableCell> {row.ageUnit === YEAR && row.gender === MALE && (row.age > 17 && row.age <= 59) && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === FEMALE && row.age >= 60 && row.age}</TableCell>
                    <TableCell>{row.ageUnit === YEAR && row.gender === MALE && row.age >= 60 && row.age}</TableCell>
                    <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                    <TableCell>{row?.municipality && AppMisc.getMunicipalityName(row?.municipality)}</TableCell>
                    <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                    <TableCell>{row?.guardianName || "-"}</TableCell>
                    <TableCell>{row?.guardianPhone || "-"}</TableCell>
                    <TableCell>{row?.self || "-"}</TableCell>
                    <TableCell>{row?.referredName}</TableCell>
                    <TableCell>{row?.broughtDeath || "-"}</TableCell>
                    <TableCell>{row?.signAndSymptoms || "-"}</TableCell>
                    <TableCell>{row?.investigations || "-"}</TableCell>
                    <TableCell>{row?.diagnosis || "-"}</TableCell>
                    <TableCell>{row?.diseasesCode || "-"}</TableCell>
                    <TableCell>{row?.medicinePrescribed || "-"}</TableCell>
                    <TableCell>{row.policeCase === YES && AppMisc.findLabelFromValue(POLICE_CASE, row?.policeCase)}</TableCell>
                    <TableCell>{row.policeCase === NO && AppMisc.findLabelFromValue(POLICE_CASE, row?.policeCase)}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dischargeDate)}</TableCell>
                    <TableCell>{row?.dischargeTime}</TableCell>
                    <TableCell>{row?.deathCode || "-"}</TableCell>
                    <TableCell>{row?.causeOfDeath || "-"}</TableCell>
                    <TableCell>{row?.costExemptionFull || "-"}</TableCell>
                    <TableCell>{row?.costExemptionPartial || "-"}</TableCell>
                    <TableCell>{row?.totalCostExempted || "-"}</TableCell>
                    <TableCell>{row?.code || "-"}</TableCell>
                    <TableCell>{row?.genderViolence || "-"}</TableCell>
                    <TableCell>{row.observation === YES && AppMisc.findLabelFromValue(POLICE_CASE, row?.observation)}</TableCell>
                    <TableCell>{row.observation === NO && AppMisc.findLabelFromValue(POLICE_CASE, row?.observation)}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        <Tooltip title="Delete Discharge Register" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color='secondary' onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="48" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default EmergencyServiceRegister;
