import { combineReducers } from "redux";
import healthClientInfo from './healthClientInfo';
import medicineInfo from './medicineInfo';
import permissions from './permissions';
import resetPage from "./resetPage";
import settingFiscalYear from "./settingData";
import sidebarToggleState from "./sidebarToggle";
import userInfo from "./userInfo";
import registerShowHideInfo from "./registerShowHideInfo";
import labSewaItems from "./labSewaItems";

export default combineReducers({ sidebarToggleState, userInfo, settingFiscalYear, resetPage, permissions, registerShowHideInfo, healthClientInfo, medicineInfo, labSewaItems });