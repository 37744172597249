import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const PurbaPanchaKarmaReport = (props) => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">पूर्वपञ्चकर्म प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Ksharasutra-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={4} colSpan={2}> सेवाको विवरण</TableCell>
              <TableCell rowSpan={4}>उमेर</TableCell>
              <TableCell rowSpan={4}>दीपन ∕  पाचन</TableCell>
              <TableCell colSpan={3}>स्नेहन</TableCell>
              <TableCell colSpan={2} rowSpan={2}>स्वेदन</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={3}>आभ्यन्तर</TableCell>
              <TableCell colSpan={2} rowSpan={2}>बाह्य</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>नाडी</TableCell>
              <TableCell rowSpan={2}>सर्वाङ्ग</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>एकाङ्ग</TableCell>
              <TableCell>सर्वाङ्ग</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={4}>सेवाग्राही संख्या </TableCell>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell>&lt;60</TableCell>
              <TableCell>{props.tableData?.femaleUnder60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.femaleUnder60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.femaleUnder60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.femaleUnder60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.femaleUnder60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.femaleUnder60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&ge;=60</TableCell>
              <TableCell>{props.tableData?.femaleOver60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.femaleOver60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.femaleOver60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.femaleOver60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.femaleOver60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.femaleOver60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>पुरुष</TableCell>
              <TableCell>&lt;60</TableCell>
              <TableCell>{props.tableData?.maleUnder60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.maleUnder60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.maleUnder60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.maleUnder60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.maleUnder60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.maleUnder60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&ge;=60</TableCell>
              <TableCell>{props.tableData?.maleOver60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.maleOver60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.maleOver60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.maleOver60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.maleOver60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.maleOver60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={4}>सेवा संख्या </TableCell>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell>&lt;60</TableCell>
              <TableCell>{props.tableData?.totalFemaleUnder60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.totalFemaleUnder60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.totalFemaleUnder60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.totalFemaleUnder60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.totalFemaleUnder60NadiSwoden}</TableCell>
              <TableCell>{props.tableData.totalFemaleUnder60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&ge;=60</TableCell>
              <TableCell>{props.tableData?.totalFemaleOver60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.totalFemaleOver60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.totalFemaleOver60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.totalFemaleOver60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.totalFemaleOver60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.totalFemaleOver60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>पुरुष</TableCell>
              <TableCell>&lt;60</TableCell>
              <TableCell>{props.tableData?.totalMaleUnder60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.totalMaleUnder60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.totalMaleUnder60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.totalMaleUnder60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.totalMaleUnder60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.totalMaleUnder60SwodenSarvang}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&ge;=60</TableCell>
              <TableCell>{props.tableData?.totalMaleOver60DipanPachan}</TableCell>
              <TableCell>{props.tableData?.totalMaleOver60AbhyantarSneha}</TableCell>
              <TableCell>{props.tableData?.totalMaleOver60BaharaEkangSneha}</TableCell>
              <TableCell>{props.tableData?.totalMaleOver60BaharaSarvang}</TableCell>
              <TableCell>{props.tableData?.totalMaleOver60NadiSwoden}</TableCell>
              <TableCell>{props.tableData?.totalMaleOver60SwodenSarvang}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PurbaPanchaKarmaReport;
