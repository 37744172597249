import {   Box, Button, Grid, TextField } from "@mui/material";
import React from "react";

const DhisTwoSetup = () => {
  return (
    <>
      <Box>
        <h2>DHIS2 सेटिङ सम्पादन गर्नुहोस् ।</h2>
        <form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  label="DHIS2 Username"
                  type="text"
                  size="small"
                  variant="outlined"
                  name="username"
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  label="DHIS2 Password"
                  size="small"
                  variant="outlined"
                  name="password"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box textAlign="right" borderTop={1} borderColor={"grey.500"} m={3}>
            <Box mt={2}>
            <Button variant="contained" color="primary" type="submit">
              सुरक्षित गर्नुहोस
            </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default DhisTwoSetup;