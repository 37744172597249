import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { AppUtils } from "../../../../../utils/appUtils";
import { Delete, Edit } from "@mui/icons-material";
import { NO_RECORDS_FOUND } from "../../../../../utils/constants";




const KarmachariBibaranRegister = (props) => {

  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}>क्र.सं.</TableCell>
              <TableCell rowSpan={2}>कर्मचारीको नाम</TableCell>
              <TableCell rowSpan={2}>कर्मचारीको थर</TableCell>
              <TableCell rowSpan={2}>शाखा र महाशाखा	</TableCell>
              <TableCell rowSpan={2}>मोवाईल नम्बर	</TableCell>
              <TableCell rowSpan={2}>Email	</TableCell>
              <TableCell rowSpan={2}>Employee Type	</TableCell>
              <TableCell rowSpan={2}>Gender	</TableCell>
              <TableCell rowSpan={2}>National ID Number	</TableCell>
              <TableCell rowSpan={2}>Citizenship Number</TableCell>
              <TableCell rowSpan={2} >सम्पादन</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {
              props?.tableData?.length > 0 ? (
                props.tableData.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{row.employeeFirstName}</TableCell>
                    <TableCell>{row.employeeLastName}</TableCell>
                    <TableCell>{row.department}</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.employeeType}</TableCell>
                    <TableCell>{row.gender}</TableCell>
                    <TableCell>{row.nationalID}</TableCell>
                    <TableCell>{row.citizenNumber}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton aria-label="edit" onClick={() => props.onEditRow(row.id)}>
                          <Edit fontSize="small" />
                        </IconButton>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton onClick={() => props.deleteRow(row.id)}>
                            <Delete fontSize="small" color='secondary' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="22" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )
            }

          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default KarmachariBibaranRegister;