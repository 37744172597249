import React, { useState } from "react";
import CustomReactSelect from "../../../../../components/custom-react-select/CustomReactSelect";
import RegisterHeader from "../../../../../components/register-header/RegisterHeader";
import { Undo } from "@mui/icons-material";
import CustomModal from "../../../../../components/modal/CustomModal";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

const DeletedRecords = () => {
  const [showUndoConformationModal, setShowUndoConformationModal] = useState(false);

  const openUndoModal = () => {
    setShowUndoConformationModal(true);
  };

  const closeUndoConfirmationModal = () => {
    setShowUndoConformationModal(false);
  };

  return (
    <>
      <RegisterHeader registerTitle="Deleted items" hideButton />
      <CustomReactSelect
        label="Registers"
        name="registers"
        isClearable={false}
      />
      <Box mt={2}>
        <TableContainer>
          <Table
            classes={{ root: "table-bordered normal-spacing" }}
            id="deleted-records"
            size="small"
            aria-label="deleted records table"
          >
            <TableHead classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell>मूल दर्ता नं.</TableCell>
                <TableCell>पुरा नाम</TableCell>
                <TableCell>Deleted By </TableCell>
                <TableCell>Deleted Reason </TableCell>
                <TableCell>Created Date </TableCell>
                <TableCell>Deleted Date </TableCell>
                <TableCell>Restore Data </TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Tooltip
                    title="यो रेकर्ड लाइ पुन: प्राप्त गर्न गर्नुहोस्।"
                    placement="top"
                    arrow
                  >
                    <Box display="flex" justifyContent="space-around">
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => openUndoModal()}
                      >
                        <Undo fontSize="small" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CustomModal
        title="Restore Data"
        showModal={showUndoConformationModal}
        onModalClose={closeUndoConfirmationModal}
        submitButtonText="Restore Record"
      >
        <Typography>
          के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ यो रेकर्ड पुन: प्राप्त गर्न
          चाहनुहुन्छ?
        </Typography>
      </CustomModal>
    </>
  );
};

export default DeletedRecords; 