import { LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_LAB_TEST_ITEMS } from "../utils/constants/redux";

const reducer = (labSewaItems = null, action) => {
  switch (action.type) {
    case SAVE_LAB_TEST_ITEMS:
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return labSewaItems;
  }
}

export default reducer;