import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AHMIS, { API_URL } from '../../../api/api'
import AddAlertMessage from '../../../components/alert/Alert'
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants'
import { useForm } from 'react-hook-form'
import CustomModal from '../../../components/modal/CustomModal'
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect'
import { PROGRAM_NAME } from '../../../utils/constants/forms'
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation'
import BittayaBibaranRegister from '../../users/components/register/bittaya-bibaran/BittayaBibaranRegister'
import { Add } from '@mui/icons-material'

const BittayaBibaranForm = () => {
  const [modalTitle, setModalTitle] = useState("वित्तिय विवरण सेवा रजिष्टरमा नयाँ रेकर्ड थप्नुहोस्।");
  const [showBattayaBibaranModal, setShowBattayaBibaranModal] = useState(false)
  const [page, setPage] = useState(0);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [bittayaBibaranDataTable, setbittayaBibaranDataTable] = useState([]);
  const [bittayaBibaranDeleteId, setBittayaBibaranDeleteId] = useState(null);
  const [showbittayaBibaranDeleteModal, setShowbittayaBibaranDeleteModal] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();

  useEffect(() => {
    register("programName")
  }, [register])

  const openBattayaBibaranModal = () => {
    setShowBattayaBibaranModal(true);
  }

  const closeBattayaBibaranModal = () => {
    reset({});
    setShowBattayaBibaranModal(false);
    setModalDefaultValues()
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitBattayaBibaranForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.bityaBibaran, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeBattayaBibaranModal()
          getBittayaBibaran()
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const getBittayaBibaran = () => {
    let url = `${API_URL.bityaBibaran}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setbittayaBibaranDataTable(jsondata || []);
        // setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({
          type: ERROR, message: SOMETHING_WENT_WRONG

        });
      })
  }

  const bittayaBibaranEdit = (id) => {
    AHMIS.get(API_URL.bityaBibaran + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data);
        setShowBattayaBibaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeBittayabittayaBibaranDeleteModal = () => {
    setShowbittayaBibaranDeleteModal(false);
    setBittayaBibaranDeleteId(null);
  }

  const onDeletebittayaBibaranRow = (id) => {
    setShowbittayaBibaranDeleteModal(true);
    setBittayaBibaranDeleteId(id);
  }

  const deleteBittayaBibaranRow = (data) => {
    AHMIS.delete(API_URL.bityaBibaran + "/" + bittayaBibaranDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowbittayaBibaranDeleteModal(false);
          setBittayaBibaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          getBittayaBibaran()
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getBittayaBibaran()
  };

  useEffect(() => {
    getBittayaBibaran()
  }, []);

  return (
    <>
      <Box
        display="flex"
        className="border-bottom-heading"
        flexDirection="row"
        alignItems="center"
        width="100%"
        margin="0.5rem 0rem"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6">वित्तिय विवरण</Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={openBattayaBibaranModal}
          >
            वित्तिय विवरण थप्नुहोस
          </Button>
        </Box>
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showBattayaBibaranModal}
        onModalSubmit={handleSubmit(submitBattayaBibaranForm)}
        onModalClose={closeBattayaBibaranModal}
        maxWidth="lg"
      >
        <Box marginTop={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4} >
              <CustomReactSelect
                label="कार्यक्रम नाम"
                name="programName"
                onChange={handleCustomReactSelectChange}
                options={PROGRAM_NAME}
                defaultValue={modalDefaultValues?.programName}
                creatable
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="विनियोगित बजेट"
                size="small"
                variant="outlined"
                name="biniyojitBajet"
                autoComplete="true"
                defaultValue={modalDefaultValues?.biniyojitBajet}
                fullWidth
                {...register("biniyojitBajet")}
              />
              {/* {errors?.tratementAndAdvice && errors.tratementAndAdvice?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>} */}
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="खर्च रकम"
                size="small"
                variant="outlined"
                name="karchaRakam"
                autoComplete="true"
                defaultValue={modalDefaultValues?.karchaRakam}
                fullWidth
                {...register("karchaRakam")}
              />
              {/* {errors?.tratementAndAdvice && errors.tratementAndAdvice?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>} */}
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      {/* </Box> */}
      <DeleteRegisterRecordConformation
        showDeleteModal={showbittayaBibaranDeleteModal}
        closeDeleteModel={closeBittayabittayaBibaranDeleteModal}
        deleteRecord={deleteBittayaBibaranRow}
      />
      <BittayaBibaranRegister
        onEditRow={bittayaBibaranEdit}
        tableData={bittayaBibaranDataTable}
        deleteRow={onDeletebittayaBibaranRow}
        handleChangePage={handleChangePage}
        page={page}
      />
    </>
  )
}

export default BittayaBibaranForm
