import { Box, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AHMIS, { API_URL } from "../../../../../api/api";
import AddAlertMessage from "../../../../../components/alert/Alert";
import { useUserDispatch } from "../../../../../context/userContext";
import { AppUtils } from "../../../../../utils/appUtils";
import { ERROR, LOGOUT_SUCCESS, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../../utils/constants";
import styles from "../../style";

export default function UserInfo(props) {
  const { register, handleSubmit, formState:{errors} } = useForm();
  const [defaultValues, setDefaultValues] = useState({});
  const [profileDataRetrieved, setProfileDataRetrieved] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  var userDispatch = useUserDispatch();
  const classes = styles();
  let navigate = useNavigate()

  useEffect(() => {
    drawUserProfile();
  }, []);

  useEffect(() => {
    emailChanged && logout();
  });

  const logout = () => {
    AHMIS.post(API_URL.logOut)
      .then(response => {
        let data = response.data;
        if (data.type === SUCCESS) {
          AppUtils.removeUserRef();
          userDispatch({ type: LOGOUT_SUCCESS });
          navigate("/");
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const drawUserProfile = () => {
    AHMIS.get(API_URL.user)
      .then(response => {
        if (response.data !== null) {
          setDefaultValues(response.data);
          setProfileDataRetrieved(true);
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const onSubmit = data => {
    let emailChanged = defaultValues.userEmail !== data.userEmail
    AHMIS.post(API_URL.profile, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          setEmailChanged(emailChanged);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    profileDataRetrieved && (
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                label="ईमेल:"
                type="email"
                size="small"
                variant="outlined"
                name="userEmail"
                fullWidth
                {...register("userEmail", { required: true })}
                defaultValue={defaultValues.userEmail}
              />
              {errors.userEmail && ( <span className="error-message">{REQUIRED_FIELD}</span>)}
            </Grid>
            <Grid item xs>
              <TextField
                label="पुरा नाम:"
                type="text"
                size="small"
                variant="outlined"
                name="fullName"
                fullWidth
                {...register("fullName", { required: true })}
                defaultValue={defaultValues.fullName}
              />
              {errors.fullName && (
                <span className="error-message">{REQUIRED_FIELD}</span>
              )}
            </Grid>
            <Grid item xs>
              <TextField
                label="सम्पर्क नं"
                type="text"
                size="small"
                variant="outlined"
                name="phoneNumber"
                fullWidth
                {...register("phoneNumber")}
                defaultValue={defaultValues.phoneNumber}
              />
            </Grid>
          </Grid>
          <Box
            item="true"
            textAlign="right"
            borderTop={1}
            borderColor={"grey.500"}
            pt={2}
            className={classes.btnContainer}
          >
            <Box mr={1}>
              <Button
                variant="contained"
                color="secondary"
                type="reset"
              >
                रद्द गर्नुहोस
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                सुरक्षित गर्नुहोस
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    )
  )
}