import React, { useEffect, useState } from "react";
import StaffList from "./helper/StaffList";
import CustomModal from "../../../../../../components/modal/CustomModal";
import { Add } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import AHMIS, { API_URL } from "../../../../../../api/api";
import { useForm } from "react-hook-form";
import AddAlertMessage from "../../../../../../components/alert/Alert";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../../../utils/constants";
import DeleteRegisterRecordConformation from "../../../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";

const StaffDetails = () => {
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [staffDetailsDataTable, setStaffDetailsDataTable] = useState([]);
  const [staffDetailsDeleteId, setStaffDetailsDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const [showStaffDetailsDeleteModal, setShowStaffDetailsDeleteModal] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  const openStaffDetailsModal = () => {
    setShowAddStaffModal(true);
  }

  const closeStaffDetailsModal = () => {
    reset({});
    setShowAddStaffModal(false);
    setModalDefaultValues()
  }

  const submitStaffForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.staffDetails, data).then((response) => {
      if (response.data.type === "success") {
        closeStaffDetailsModal()
        getStaffDetails()
      }
      AddAlertMessage({ type: response.data.type, message: response.data.message })
    })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getStaffDetails = () => {
    let url = `${API_URL.staffDetails}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setStaffDetailsDataTable(jsondata || []);
      })
      .catch((error) => {
        AddAlertMessage({
          type: ERROR, message: SOMETHING_WENT_WRONG
        });
      })
  }

  const staffDetailsEdit = (id) => {
    AHMIS.get(API_URL.staffDetails + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data);
        setShowAddStaffModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeStaffDetailsDeleteModal = () => {
    setShowStaffDetailsDeleteModal(false);
    setStaffDetailsDeleteId(null);
  }

  const onDeleteStaffDetailsRow = (id) => {
    setShowStaffDetailsDeleteModal(true);
    setStaffDetailsDeleteId(id);
  }

  const deleteStaffDetailsRow = (data) => {
    AHMIS.delete(API_URL.staffDetails + "/" + staffDetailsDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowStaffDetailsDeleteModal(false);
          setStaffDetailsDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          getStaffDetails()
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getStaffDetails()
  };

  useEffect(() => {
    getStaffDetails()
  }, []);

  return (
    <>
      <Box
        display="flex"
        className="border-bottom-heading"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6">स्वास्थ्यकर्मीको विवरण</Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setShowAddStaffModal(true)}
          >
            स्वास्थ्यकर्मीको विवरण थप्नुहोस
          </Button>
        </Box>
      </Box>
      <CustomModal
        title="स्वास्थ्यकर्मीको विवरण थप्नुहोस्"
        showModal={showAddStaffModal}
        onModalClose={openStaffDetailsModal}
        onModalSubmit={handleSubmit(submitStaffForm)}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              label="स्वास्थ्यकर्मीको नाम"
              variant="outlined"
              name="name"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              label="स्वास्थ्यकर्मीको पद"
              variant="outlined"
              name="post"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              control={<Switch color="primary" name="active" />}
              label="सक्रिय / निष्क्रिय"
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showStaffDetailsDeleteModal}
        closeDeleteModel={closeStaffDetailsDeleteModal}
        deleteRecord={deleteStaffDetailsRow}
      />
      <StaffList
        onEditRow={staffDetailsEdit}
        tableData={staffDetailsDataTable}
        deleteRow={onDeleteStaffDetailsRow}
        handleChangePage={handleChangePage}
        page={page}
      />
    </>
  );
};

export default StaffDetails;