import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AHMIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { ERROR, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import CustomModal from "../../../components/modal/CustomModal";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import KarmaChariBibaranRegister from "../../users/components/register/Karmachari-bibaran/KarmaChariBibaranRegister";
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect";
import { EMPLOYEE_TYPE } from "../../../utils/constants/forms";


const KarmachariBibaranForm = () => {
  const [modalTitle, setModalTitle] = useState("कर्मचारी विवरण  सेवा रजिष्टरमा नयाँ रेकर्ड थप्नुहोस्।");
  const [showkarmachariBibaranModal, setShowkarmachariBibaranModal] = useState(false)
  const [page, setPage] = useState(0);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [karmachariBibaranDataTable, setkarmachariBibaranDataTable] = useState([]);
  const [karmachariBibaranDeleteId, setkarmachariBibaranDeleteId] = useState(null);
  const [showkarmachariBibaranDeleteModal, setShowkarmachariBibaranDeleteModal] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  useEffect(() => {
    register("employeeType")
    register("gender")
  }, [register])

  const openkarmachariBibaranModal = () => {
    setShowkarmachariBibaranModal(true);
  }

  const closekarmachariBibaranModal = () => {
    reset({});
    setShowkarmachariBibaranModal(false);
    setModalDefaultValues()
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitkarmachariBibaranForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.karmaBibaran, data)
      .then((response) => {
        if (response.data.type === "success") {
          closekarmachariBibaranModal()
          getkarmachariBibaran()
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const getkarmachariBibaran = () => {
    let url = `${API_URL.karmaBibaran}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setkarmachariBibaranDataTable(jsondata || []);
        // setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({
          type: ERROR, message: SOMETHING_WENT_WRONG

        });
      })
  }

  const karmachariBibaranEdit = (id) => {
    AHMIS.get(API_URL.karmaBibaran + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data);
        setShowkarmachariBibaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeBittayakarmachariBibaranDeleteModal = () => {
    setShowkarmachariBibaranDeleteModal(false);
    setkarmachariBibaranDeleteId(null);
  }

  const onDeletekarmachariBibaranRow = (id) => {
    setShowkarmachariBibaranDeleteModal(true);
    setkarmachariBibaranDeleteId(id);
  }

  const deletekarmachariBibaranRow = (data) => {
    AHMIS.delete(API_URL.karmaBibaran + "/" + karmachariBibaranDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowkarmachariBibaranDeleteModal(false);
          setkarmachariBibaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          getkarmachariBibaran()
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getkarmachariBibaran()
  };

  useEffect(() => {
    getkarmachariBibaran()
  }, []);
  return (
    <>
      <Box
        display="flex"
        className="border-bottom-heading"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6">कर्मचारी विवरण </Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={openkarmachariBibaranModal}
          >
            कर्मचारी   विवरण थप्नुहोस
          </Button>
        </Box>
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showkarmachariBibaranModal}
        onModalSubmit={handleSubmit(submitkarmachariBibaranForm)}
        onModalClose={closekarmachariBibaranModal}
        maxWidth="lg"
      >
        <Box marginTop={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="कर्मचारीको नाम"
                size="small"
                variant="outlined"
                name="employeeFirstName"
                autoComplete="true"
                defaultValue={modalDefaultValues?.employeeFirstName}
                fullWidth
                {...register("employeeFirstName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="कर्मचारीको थर"
                size="small"
                variant="outlined"
                name="employeeLastName"
                autoComplete="true"
                defaultValue={modalDefaultValues?.employeeLastName}
                fullWidth
                {...register("employeeLastName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="शाखा र महाशाखा	"
                size="small"
                variant="outlined"
                name="department"
                autoComplete="true"
                defaultValue={modalDefaultValues?.department}
                fullWidth
                {...register("department")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="मोवाईल नम्बर	"
                size="small"
                variant="outlined"
                name="phoneNumber"
                autoComplete="true"
                defaultValue={modalDefaultValues?.phoneNumber}
                fullWidth
                {...register("phoneNumber")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="Email	"
                size="small"
                variant="outlined"
                name="email"
                autoComplete="true"
                defaultValue={modalDefaultValues?.email}
                fullWidth
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <CustomReactSelect
                label="Employee Type"
                name="employeeType"
                options={EMPLOYEE_TYPE}
                onChange={handleCustomReactSelectChange}
                defaultValue={modalDefaultValues?.employeeType}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <CustomReactSelect
                label="Gender"
                name="gender"
                options={GENDER_OPTIONS}
                onChange={handleCustomReactSelectChange}
                defaultValue={modalDefaultValues?.gender}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="National ID Number	"
                size="small"
                variant="outlined"
                name="nationalID"
                autoComplete="true"
                defaultValue={modalDefaultValues?.nationalID}
                fullWidth
                {...register("nationalID")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                label="Citizenship Numbers	"
                size="small"
                variant="outlined"
                name="citizenNumber"
                autoComplete="true"
                defaultValue={modalDefaultValues?.citizenNumber}
                fullWidth
                {...register("citizenNumber")}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showkarmachariBibaranDeleteModal}
        closeDeleteModel={closeBittayakarmachariBibaranDeleteModal}
        deleteRecord={deletekarmachariBibaranRow}
      />
      <KarmaChariBibaranRegister
        onEditRow={karmachariBibaranEdit}
        tableData={karmachariBibaranDataTable}
        deleteRow={onDeletekarmachariBibaranRow}
        handleChangePage={handleChangePage}
        page={page}
      />
    </>
  )
}

export default KarmachariBibaranForm