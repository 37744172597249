import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ENTER_VALID_EMAIL, REQUIRED_FIELD } from "../../utils/constants";
import CustomReactSelect from "../custom-react-select/CustomReactSelect";
import CustomModal from "../modal/CustomModal";
import styles from "./style";
import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";

export default function AddUserModal({ showAddUserModal, handleAddUserModalClose, onSubmit, ...props }) {
  const classes = styles();
  const { register, setValue, handleSubmit, formState: { errors }, reset } = useForm();
  const [activeStatus, setActiveStatus] = useState(true);

  useEffect(() => {
    register("role", { required: props.userRoles });
  }, [register, props.userRoles]);

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handleStatusChange = (event) => {
    setActiveStatus(event.target.checked);
  };

  function closeAddUserModal() {
    setActiveStatus(true);
    handleAddUserModalClose();
  }

  useEffect(() => {
    reset(props.defaultValues);
    typeof props.defaultValues?.active === "boolean" && setActiveStatus(props.defaultValues.active);
  }, [props.defaultValues, reset]);

  useEffect(() => {
    if (showAddUserModal) {
      reset()
    }

  }, [showAddUserModal, reset]);
  return (
    <CustomModal
      title="नयाँ प्रयोगकर्ता थप्नुहोस्"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddUserModal}
      onModalClose={closeAddUserModal}
    >
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="User Full Name"
            type="text"
            variant="outlined"
            size="small"
            name="fullName"
            {...register("fullName", { required: true })}
            defaultValue={props.defaultValues?.fullName}
          />
          {errors.fullName && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="User Email"
            type="email"
            size="small"
            variant="outlined"
            name="userEmail"
            defaultValue={props.defaultValues?.userEmail}
            {...register("userEmail", { required: true, pattern: /\S+@\S+\.\S+/ })}
          />
          {errors.userEmail && errors.userEmail.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          {errors.userEmail && errors.userEmail.type === "pattern" && <span className="error-message">{ENTER_VALID_EMAIL}</span>}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Phone Number"
            type="text"
            variant="outlined"
            size="small"
            name="phoneNumber"
            {...register("phoneNumber")}
            defaultValue={props.defaultValues?.phoneNumber}
          />
        </Grid>
        {
          props.userRoles &&
          <Grid item xs={4}>
            <CustomReactSelect
              name="role"
              label="प्रयोगकर्ताको भूमिका"
              options={props.userRoles}
              onChange={handleCustomSelectChange}
              defaultValue={props?.defaultValues?.role}
              fullWidth
            />
            {errors?.role && errors.role?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        }
      </Grid>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs>
          <FormControlLabel
            {...register("active")}
            control={
              <Switch
                checked={activeStatus}
                color="primary"
                name="active"
                onChange={handleStatusChange}
              />
            }
            label="सक्रिय / निष्क्रिय"
            classes={{
              label: classes.switchLabelSmall,
            }}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};