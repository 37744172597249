import { Box, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import DisChargeRegister from "../../users/components/register/discharge-register/DisChargeRegister"
import { useForm } from "react-hook-form"
import { AYURVEDIC_THERAPY, ERROR, GENDER_OPTIONS, PATIENT_TYPES, POLICE_CASE, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import { AGE_UNITS, DISEASE_CODE } from "../../../utils/constants/forms"
import { AppMisc } from "../../../misc/appMisc"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"
import NepaliCalender from "../../../components/nepali-calender"

const DischargeForm = () => {
  const [modalTitle, setModalTitle] = useState("Discharge Register")
  const [showDischarge, setshowDischarge] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [getTableData, setGetTableData] = useState(false);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [dischargeTableData, setDischargeTableData] = useState([])
  const [dischargeDeleteId, setDischargeDeleteId] = useState(null);
  const [showDischargeRegisterDeleteModal, setShowDischargeRegisterDeleteModal] = useState(false);
  const districtOptions = AppMisc.getDistrictOptions();
  const { register, handleSubmit, setValue, formState: { errors }, reset, } = useForm();

  const openDischargeModal = () => {
    setshowDischarge(true)
  }

  const closeDischargeModal = () => {
    setshowDischarge(false)
    setModalDefaultValues();
    reset();
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    showDischarge && reset();
  }, [reset, showDischarge])

  useEffect(() => {
    register("date", { required: true });
    register("patientType", { required: true });
    register("casteCode", { required: true });
    register("district", { required: true });
    register("municipality", { required: true });
    register("sourceOfAdmission", { required: true });
    register("ayurvedicTherapy", { required: true });
    register("ageUnit", { required: true });
    register("policeCase", { required: true });
    register("dateOfDischarge", { required: true });
    register("dateOfAdmission", { required: true });
  }, [register]);

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const submitDischargeRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.discharge, data).then((response) => {
      if (response.data.type === SUCCESS) {
        closeDischargeModal();
        setGetTableData(true);
      }
      AddAlertMessage({ type: response.data.type, message: response.data.message });
    }).catch((error) => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
    })
  }

  const getDischargeSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.discharge}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url).then((response) => {
      let jsonData = response.data;
      setGetTableData(false);
      setDischargeTableData(jsonData?.data?.objectList || []);
      setTotalSize(jsonData?.data?.size)
    }).catch((error) => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG })
    })
  }

  const dischargeSewaEdit = (id) => {
    AHMIS.get(API_URL.discharge + "/" + id).then((response) => {
      setModalDefaultValues(response.data);
      setshowDischarge(true);
      if (response.data.district && response.data.palikaName) {
        populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    })
  }
  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeDischargeDeleteModal = () => {
    setShowDischargeRegisterDeleteModal(false)
    setDischargeDeleteId(null);
  }

  const onDeleteAdmissionRow = (id) => {
    setShowDischargeRegisterDeleteModal(true)
    setDischargeDeleteId(id);
  }

  const deleteDischargeRow = (data) => {
    AHMIS.delete(API_URL.discharge + "/" + dischargeDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowDischargeRegisterDeleteModal(false);
          setDischargeDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getDischargeSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="Discharge Register"
          handleModalOpen={openDischargeModal}
          getRegisterData={getDischargeSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showDischarge}
        onModalSubmit={handleSubmit(submitDischargeRegister)}
        onModalClose={closeDischargeModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Patient Type"
              name="patientType"
              options={PATIENT_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.patientType}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                showDefaultDate
                label="Date of Discharge"
                onChange={handleDateChange}
                defaultDate={modalDefaultValues?.dartaaMiti}
              />
              {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Time of Discharge"
              type="number"
              size="small"
              variant="outlined"
              name="timeOfDischarge"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.timeOfDischarge}
              {...register("timeOfDischarge", { required: true })}
            />
            {errors?.timeOfDischarge && errors.timeOfAdmission?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="mul-dartaa-dartaa-miti"
              name="dateOfAdmission"
              showDefaultDate
              label="Date  of Admission"
              onChange={handleDateChange}
              defaultDate={modalDefaultValues?.dateOfAdmission}
            />
            {errors?.dateOfAdmission && errors.dateOfAdmission?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Time of Admission"
              type="number"
              size="small"
              variant="outlined"
              name="timeOfAdmission"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.timeOfAdmission}
              {...register("timeOfAdmission", { required: true })}
            />
            {errors?.timeOfAdmission && errors.timeOfAdmission?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.dartaaNumber}
              {...register("dartaaNumber", { required: true })}
            />
            {errors?.dartaaNumber && errors.dartaaNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Lenght of Stay Days"
              type="text"
              size="small"
              variant="outlined"
              name="lengthOfStayDate"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.lengthOfStayDate}
              {...register("lengthOfStayDate", { required: true })}
            />
            {errors?.lengthOfStayDate && errors.lengthOfStayDate?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Frist Name"
              type="text"
              size="small"
              variant="outlined"
              name="name"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.name}
              {...register("name", { required: true })}
            />
            {errors?.name && errors.name?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Family Name"
              type="text"
              size="small"
              variant="outlined"
              name="familyName"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.familyName}
              {...register("familyName", { required: true })}
            />
            {errors?.familyName && errors.familyName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Caste Code"
              type="text"
              size="small"
              variant="outlined"
              name="casteCode"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.casteCode}
              {...register("casteCode", { required: true })}
            />
            {errors?.casteCode && errors.casteCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.age}
              {...register("age", { required: true })}
            />
            {errors?.age && errors.age?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.gender}
              options={GENDER_OPTIONS}
            />
            {errors?.gender && errors.gender?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              options={AGE_UNITS}
              defaultValue={modalDefaultValues?.ageUnit}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.ageUnit && errors.ageUnit?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="District"
              name="district"
              variant="outlined"
              onChange={handleDistrictChange}
              options={districtOptions}
              defaultValue={modalDefaultValues?.district}
            />
            {errors?.district && errors.district?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Municipality"
              name="municipality"
              variant="outlined"
              options={palikaOptions}
              onChange={handlePalikaNameChange}
              defaultValue={modalDefaultValues?.municipality}
            />
            {errors?.municipality && errors.municipality?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Contact Number"
              size="small"
              type="number"
              variant="outlined"
              name="contact"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.contact}
              {...register("contact", { required: true })}
            />
            {errors?.contact && errors.contact?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Ward/Bed No"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.wardNumber}
              {...register("wardNumber", { required: true })}
            />
            {errors?.wardNumber && errors.wardNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Treatment/Medicine Prescribed"
              size="small"
              variant="outlined"
              name="medicinePrescribed"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.medicinePrescribed}
              {...register("medicinePrescribed", { required: true })}
            />
            {errors?.medicinePrescribed && errors.medicinePrescribed?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Ayurvedic Therapy"
              size="small"
              variant="outlined"
              name="ayurvedicTherapy"
              autoComplete="true"
              fullWidth
              options={AYURVEDIC_THERAPY}
              defaultValue={modalDefaultValues?.ayurvedicTherapy}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.ayurvedicTherapy && errors.ayurvedicTherapy?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Complication"
              name="complication"
              variant="outlined"
              options={POLICE_CASE}
              defaultValue={modalDefaultValues?.complication}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.complication && errors.complication?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Final diagnosis at discharge"
              size="small"
              variant="outlined"
              name="finalDiagnosisDischarge"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.finalDiagnosisDischarge}
              {...register("finalDiagnosisDischarge")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Disease Code"
              name="diseaseCode"
              variant="outlined"
              options={DISEASE_CODE}
              defaultValue={modalDefaultValues?.diseaseCode}
              onChange={handleCustomReactSelectChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="OutComes Code"
              size="small"
              variant="outlined"
              name="outComesCode"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.outComesCode}
              {...register("outComesCode")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="cause of Died"
              size="small"
              variant="outlined"
              name="causeOfDied"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.causeOfDied}
              {...register("causeOfDied")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Cost exemption Full"
              size="small"
              variant="outlined"
              name="costexemptionFull"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.costexemptionFull}
              {...register("costexemptionFull")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Cost exemption Partial"
              size="small"
              variant="outlined"
              name="costexemptionPartial"
              autoComplete="true"
              defaultValue={modalDefaultValues?.costexemptionPartial}
              fullWidth
              {...register("costexemptionPartial")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Total cost exempted"
              size="small"
              variant="outlined"
              name="totalCostExempted"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.totalCostExempted}
              {...register("totalCostExempted")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Code"
              size="small"
              variant="outlined"
              name="code"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.code}
              {...register("code")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Police Case"
              size="small"
              variant="outlined"
              name="policeCase"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.policeCode}
              options={POLICE_CASE}
              onChange={handleCustomReactSelectChange}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showDischargeRegisterDeleteModal}
        closeDeleteModel={closeDischargeDeleteModal}
        deleteRecord={deleteDischargeRow}
      />
      <DisChargeRegister
        onEditRow={dischargeSewaEdit}
        tableData={dischargeTableData}
        deleteRow={onDeleteAdmissionRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default DischargeForm