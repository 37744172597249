import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const ChartDataByAge = () => {

  const options = {
    series: [
      {
        name: "Male",
        data: [1000, 1300, 1500, 1100, 1400, 1200, 700, 200, 1500, 1200, 1000]
      },
      {
        name: "Female",
        data: [1700, 1500, 1200, 1400, 1200, 1600, 1200, 1400, 2900, 1000, 1300]
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    dataLabels: {
      formatter: (val) => {
        return val
      }
    },
    title: {
      text: "चालु आर्थिक वर्षमा आएका सेवाग्राहीहरुको उमेर अनुसारको वर्गिकरण",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    xaxis: {
      categories: [
        "0-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80-89",
        "90-99",
        "100+"
      ],
      title: {
        text: "उमेर "
      }
    },
    fill: {
      opacity: 1
    },
    colors: ["#80c7fd", "#008FFB"],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val
        }
      },
      title: {
        text: "जम्मा सख्या "
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center"
    }
  };

  return (
    <>
      <Box mt={2}>
        <Card>
          <Chart
            options={options}
            series={options.series}
            type="bar"
            height={350} />;
        </Card>
      </Box>
    </>
  )
};

export default ChartDataByAge;