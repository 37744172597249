import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect";
import NepaliCalender from "../../../components/nepali-calender";
import CustomModal from "../../../components/modal/CustomModal";
import RegisterHeader from "../../../components/register-header/RegisterHeader";
import PanchaKarmaRegister from "../../users/components/register/panchakarma-sewa-register/PanchaKarmaRegister";
import { ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, THERAPY, bahya_Snehana } from "../../../utils/constants";
import { useForm } from "react-hook-form";
import { AppMisc } from "../../../misc/appMisc";
import { INVALID_BLOOD_PRESSURE_MESSAGE, MUL_DARTA_NUMBERS_LIST } from "../../../utils/constants/forms";
import AHMIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import MulDartaaSelect from "../../../components/mul-dartaa-select/MulDartaaSelect";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import { DateUtils } from "../../../utils/dateUtils";
import PendingPatientList from "../../../components/pending-patient-list/PendingPatientList";

const PanchaKarmaSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("पञ्चकर्म सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showPanchakarmaModal, setShowPanchakarmaModal] = useState(false);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [panchakarmaTableData, setPanchakarmaTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [showPanchakarmaDeleteModal, setShowPanchakarmaDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [panchakarmaDeleteId, setPanchakarmaDeleteId] = useState(null);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const watchFields = watch("therapy")
  const [pendingPatientList, setpendingPatientList] = useState([])

  useEffect(() => {
    register("district", { required: true });
    register("palikaName", { required: true });
    register("gender", { required: true });
    register("casteCode", { required: true });
    register("therapy", { required: true });
    register("ageUnit", { required: true });
    register("settlement", { required: true });
    register("patientType", { required: true });
    register("dateOfBirth", { required: true });
    register("dartaaMiti", { required: true });
    register("lubrication", { required: true })
    register("date", { required: true });
    register("sweden", { required: true });
  }, [register]);

  useEffect(() => {
    showPanchakarmaModal && reset();
  }, [reset, showPanchakarmaModal]);

  const openPanchakarmaModal = () => {
    setShowPanchakarmaModal(true);
  };

  const closePanchakarmaModal = () => {
    setShowPanchakarmaModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const submitParchaKarmaRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.panchakarmaRegister, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closePanchakarmaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getPanchakarmaData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.panchakarmaRegister}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setPanchakarmaTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const panchakarmaEdit = (id) => {
    AHMIS.get(API_URL.panchakarmaRegister + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowPanchakarmaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closePanchakarmaDeleteModal = () => {
    setShowPanchakarmaDeleteModal(false);
    setPanchakarmaDeleteId(null);
  }

  const onDeletePanchakarmaRow = (id) => {
    setShowPanchakarmaDeleteModal(true);
    setPanchakarmaDeleteId(id);
  }

  const deletePanchaKarmaRow = (data) => {
    AHMIS.delete(API_URL.panchakarmaRegister + "/" + panchakarmaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowPanchakarmaDeleteModal(false);
          setPanchakarmaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getPanchakarmaData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=2&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  useEffect(() => {
    panchaKarmaPendingPatient()
  }, [])

  const panchaKarmaPendingPatient = () => {
    let url = `${API_URL.getPanchaKarmaPendingPatient}`;
    AHMIS.get(url).then((response) => {
      let jsondata = response.data;
      setpendingPatientList(jsondata.data)
    })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="पञ्चकर्म सेवा रजिष्टर"
          handleModalOpen={openPanchakarmaModal}
          getRegisterData={getPanchakarmaData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showPanchakarmaModal}
        onModalSubmit={handleSubmit(submitParchaKarmaRegister)}
        onModalClose={closePanchakarmaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रक्तचाप (Blood Pressure)"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure", {
                pattern: /^(0|[1-9][0-9]*)\/(0|[1-9][0-9]*)$/,
                required: true
              })}
            />
            {errors.bloodPressure && errors.bloodPressure.type === "pattern" && <span className="error-message">{INVALID_BLOOD_PRESSURE_MESSAGE}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature", { required: true })}
            />
            {errors?.nature && errors.nature?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल (KG)"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight", { required: true })}
            />
            {errors?.weight && errors.weight?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid> <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature", { required: true })}
            />
            {errors?.nature && errors.nature?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल (KG)"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight", { required: true })}
            />
            {errors?.weight && errors.weight?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रोग"
              size="small"
              variant="outlined"
              name="disease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.disease}
              {...register("disease", { required: true })}
            />
            {errors?.disease && errors.disease?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="FBS"
              size="small"
              variant="outlined"
              name="fbs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("fbs", { required: true })}
            />
            {errors?.fbs && errors.fbs?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.test}
              {...register("test", { required: true })}
            />
            {errors?.test && errors.test?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रोग"
              size="small"
              variant="outlined"
              name="disease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.disease}
              {...register("disease", { required: true })}
            />
            {errors?.disease && errors.disease?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="FBS"
              size="small"
              variant="outlined"
              name="fbs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("fbs", { required: true })}
            />
            {errors?.fbs && errors.fbs?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.test}
              {...register("test", { required: true })}
            />
            {errors?.test && errors.test?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Therapy"
              name="therapy"
              variant="outlined"
              options={THERAPY}
              onChange={handleSelectChange}
              defaultValue={modalDefaultValues?.therapy}
            />
            {errors?.therapy && errors.therapy?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          {watchFields && watchFields?.includes("2") && (
            <Grid item xs={12} sm={6} md={3}>
              <CustomReactSelect
                label="बाह्य स्नेहन"
                name="bahyaSnehana"
                variant="outlined"
                options={bahya_Snehana}
                onChange={handleSelectChange}
                defaultValue={modalDefaultValues?.bahyaSnehana}
              />
            </Grid>
          )}
        </Grid>
      </CustomModal>
      <PendingPatientList
        pendingPatientList={pendingPatientList}
        showModal={setShowPanchakarmaModal}
        modalDefaultValues={setModalDefaultValues}
      />
      <DeleteRegisterRecordConformation
        showDeleteModal={showPanchakarmaDeleteModal}
        closeDeleteModel={closePanchakarmaDeleteModal}
        deleteRecord={deletePanchaKarmaRow}
      />
      <PanchaKarmaRegister
        onEditRow={panchakarmaEdit}
        tableData={panchakarmaTableData}
        deleteRow={onDeletePanchakarmaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  );
};

export default PanchaKarmaSewaForm;