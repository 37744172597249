import { Box, Card } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';

const ChartDataByMonth = () => {
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: ["साउन", "भदौ", "आश्विन", "कार्तिक", "मंसिर", "पौष", "माघ", "फागुन", "चैत", "बैशाख", "जेष्ठ",],
      title: {
        text: "महिना"
      }
    },
    yaxis: {
      title: {
        text: " जम्मा सख्या"
      }
    },
    title: {
      text: "चालु आ. व. को लिङ्ग अनुसार सेवाग्राही विवरण",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    legend: {
      position: 'top',
    },
    fill: {
      opacity: 1,
    },
  };

  const seriesData = [
    {
      name: 'Male',
      data: [1500, 1200, 1400, 900, 1500, 1200, 1100, 1800, 1000, 900, 1300, 1200],
    },
    {
      name: 'Female',
      data: [1000, 1500, 1200, 1800, 1100, 600, 1300, 1100, 1200, 1400, 1100, 1000],
    },
  ];

  return (
    <>
      <Box mt={2}>
        <Card>
          <Chart
            options={chartOptions}
            series={seriesData}
            type="bar"
            height={350}
          />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataByMonth;
