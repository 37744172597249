import { Box, Card } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartDataByNewOldPatient = () => {
  const chartData = [
    { month: "साउन", newPatients: 1000, oldPatients: 1500 },
    { month: "भदौ", newPatients: 800, oldPatients: 700 },
    { month: "आश्विन", newPatients: 1100, oldPatients: 1300 },
    { month: "कार्तिक", newPatients: 1500, oldPatients: 1400 },
    { month: "मंसिर", newPatients: 1000, oldPatients: 1300 },
    { month: "पौष", newPatients: 1400, oldPatients: 1000 },
    { month: "माघ", newPatients: 1000, oldPatients: 1200 },
    { month: "फागुन", newPatients: 800, oldPatients: 700 },
    { month: "चैत", newPatients: 1000, oldPatients: 1300 },
    { month: "बैशाख", newPatients: 1500, oldPatients: 900 },
    { month: "जेष्ठ", newPatients: 1400, oldPatients: 1000 },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 430
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'center',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      type: 'category',
      categories: chartData.map(item => item.month),
      title: {
        text: "महिना"
      }
    },
    yaxis: {
      title: {
        text: " जम्मा सख्या"
      }
    },
    title: {
      text: "सेवाग्राहीको किसिम अनुसार चालु आ. व. को सेवाग्राही विवरण",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    legend: {
      position: 'top',
    },
  };

  const series = [
    { name: 'New Patients', data: chartData.map(item => item.newPatients) },
    { name: 'Old Patients', data: chartData.map(item => item.oldPatients) }
  ];

  return (
    <>
      <Box mt={2}>
        <Card>
          <ReactApexChart options={options} series={series} type="bar" height={430} />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataByNewOldPatient;
