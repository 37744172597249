import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react"

const BreastfeedingMaternalAndChildServicesReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">५. स्तनपायी आमा तथा शिशु सेवा प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Breastfeeding-Maternal-And-Child-Services-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={1}>विवरण </TableCell>
              <TableCell rowSpan={1}>संख्या</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>जम्मा स्तनपायी आमाको संख्या </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>१ महिना भन्दा कम औषधी खाएका आमाहरुको संख्या </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>१ देखि २ महिना सम्म औषधी खाएका आमाहरुको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>२ देखि ३ महिना सम्म औषधी खाएका आमाहरुको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>३ देखि ४ महिना सम्म औषधी खाएका आमाहरुको संख्या </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>४ देखि ५ महिना सम्म औषधी खाएका आमाहरुको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>५ महिना भन्दा बढी औषधी खाएका आमाहरुको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>४ महिनामा  आमाको दुधको मात्रामा  सुधार भएको आमाको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>४ महिनामा  तौलमा सुधार भएको बच्चाको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>६ महिनामा  आमाको दुधको मात्रामा  सुधार भएको आमाको संख्या </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>६ महिनामा  तौलमा सुधार भएको बच्चाको संख्या</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default BreastfeedingMaternalAndChildServicesReport;