import { Box, Card } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartDataByEmployeeCount = () => {
  const chartData = [
    { employee: "वरिष्ठ कन्सलटेन्ट आयुर्वेद विज्ञ", count: 1000 },
    { employee: "आयुर्वेद चिकित्सक", count: 800 },
    { employee: "शाखा अधिकृत", count: 1100 },
    { employee: "तथ्यांक अधिकृत", count: 1500 },
    { employee: "लेखा अधिकृत", count: 1000 },
    { employee: "व. कविराज निरिक्षक", count: 1400 },
    { employee: "कविराज निरिक्षक", count: 900 },
    { employee: "नायब सुब्बा", count: 1000 },
    { employee: "कविराज", count: 1100 },
    { employee: "कम्प्युटर अपरेटर", count: 1200 },
    { employee: "कार्यालय सहयोगी", count: 1300 }
  ];

  const options = {
    chart: {
      type: "bar",
      height: 430
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "center",
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      type: "category",
      categories: chartData.map(item => item.employee),
      title: {
        text: "महिना"
      }
    },
    yaxis: {
      title: {
        text: " जम्मा सख्या"
      }
    },
    title: {
      text: "पद अनुसार कर्मचारी   विवरण",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    legend: {
      position: "top",
    },
  };

  const series = [
    { name: "कर्मचारी", data: chartData.map(item => item.count) },
  ];

  return (
    <>
      <Box mt={2}>
        <Card>
          <ReactApexChart options={options} series={series} type="bar" height={430} />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataByEmployeeCount;
