import { Box, Card } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartDataByEmployeeTypes = () => {
  const series = [44, 55, 41, 17];

  const options = {
    chart: {
      width: 380,
      type: "donut",
      align: "center",
    },
    labels: ["अस्थायी", "स्थायी", "करार", "अन्य "],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      }
    },
    fill: {
      type: "gradient",
    },
    title: {
      text: "सेवामा आयको  कर्मचारीहरु",
      align: "center",
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: "bottom"
        }
      }
    }]
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" mt={2}>
      {/* <Card> */}
      <ReactApexChart options={options} series={series} type="donut" width={600} />
      {/* </Card> */}
    </Box>
  );
};

export default ChartDataByEmployeeTypes;
