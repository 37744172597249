import AHMIS from "../../api/api";
import AddAlertMessage from "../../components/alert/Alert";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../constants";

export const getDataFromServer = async (url) => {
  try {
    let response = await AHMIS.get(url);
    let jsondata = response.data;
    if (jsondata.type === SUCCESS) {
      return jsondata.data || jsondata.objectList;
    } else {
      AddAlertMessage({ type: jsondata.type, message: jsondata.message })
    }
  } catch (error) {
    AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
  }
}

export const postDataToServer = async (url, data, hideAlertMessage) => {
  try {
    let response = await AHMIS.post(url, data);
    let jsondata = response.data;
    !hideAlertMessage && AddAlertMessage({ type: jsondata.type, message: jsondata.message })
    if (jsondata.type === SUCCESS) {
      return true;
    }
  } catch (error) {
    AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
  }
}
