import { Edit } from "@mui/icons-material";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { AppUtils } from "../../../../utils/appUtils";
import { NO_RECORDS_FOUND } from "../../../../utils/constants";
import { AppMisc } from "../../../../misc/appMisc";

export default function MunicipalitiesList(props) {

  function editRow(id) {
    props.editRow(id);
  }

  return (
    <TableContainer>
      <Table classes={{ root: "table-bordered" }} size="small" aria-label="स्वास्थ्य व्यवस्थापन सूचना प्रणाली - सबै नगरपालिकाहरु">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>क्र. सं.</TableCell>
            <TableCell>प्रदेश</TableCell>
            <TableCell>जिल्ला</TableCell>
            <TableCell>पालिकाको नाम</TableCell>
            <TableCell>ठेगाना</TableCell>
            <TableCell>फोन नम्बर</TableCell>
            <TableCell>ईमेल</TableCell>
            <TableCell>वेबसाइट</TableCell>
            {
              props?.tableData?.length !== 0 && <TableCell>सम्पादन</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {props?.tableData?.length === 0 ?
            (
              <TableRow>
                <TableCell colSpan={8} size="medium">{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (
              props?.tableData?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                  <TableCell>{AppMisc.getProvinceName(row.province)}</TableCell>
                  <TableCell>{AppMisc.getDistrictName(row.district)}</TableCell>
                  <TableCell><a href={`municipality-home?id=${row.id}`}>{row.palikaName ? AppMisc.getMunicipalityName(row.palikaName) : "-"}</a></TableCell>
                  <TableCell>{row.address || "-"}</TableCell>
                  <TableCell>{row.phoneNumber || "-"}</TableCell>
                  <TableCell>{row.email || "-"}</TableCell>
                  <TableCell>{row.website ? <a href={row.website} rel="noopener noreferrer" target="_blank">{row.website}</a> : "-"}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => editRow(row.id)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
