import { Box, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import CustomModal from "../../../components/modal/CustomModal"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import EmergencyServiceRegister from "../../users/components/register/emergency-service-register/EmergencyServiceRegister"
import NepaliCalender from "../../../components/nepali-calender"
import { useForm } from "react-hook-form"
import { CASTE_CODES, ERROR, GENDER_OPTIONS, PATIENT_TYPES, POLICE_CASE, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import { AGE_UNITS } from "../../../utils/constants/forms"
import { AppMisc } from "../../../misc/appMisc"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"

const EmergencyServiceForm = () => {
  const [modalTitle, setModalTitle] = useState("Emergency Service Register")
  const [showEmergencyService, setShowEmergencyService] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [emergencySewaTableData, setEmergencySewaTableData] = useState([]);
  const [showEmergencyRegisterDeleteModal, setShowEmergencyRegisterDeleteModal] = useState(false);
  const [emergencyDeleteId, setEmergencyDeleteId] = useState(null);
  const [getTableData, setGetTableData] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const districtOptions = AppMisc.getDistrictOptions();
  const { register, handleSubmit, setValue, formState: { errors }, reset, } = useForm();

  useEffect(() => {
    register("date", { required: true });
    register("patientType", { required: true });
    register("castCode", { required: true });
    register("district", { required: true });
    register("municipality", { required: true });
    register("sourceOfAdmission", { required: true });
    register("ageUnit", { required: true });
    register("policeCase", { required: true });
    register("gender", { required: true });
    register("dischargeDate", { required: true });
  }, [register]);

  const openEmergencyServiceModal = () => {
    setShowEmergencyService(true)
  }

  const closeEmergencyServiceModal = () => {
    setShowEmergencyService(false)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const submitEmergencyRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.emergency, data).then((response) => {
      if (response.data.type === SUCCESS) {
        closeEmergencyServiceModal()
        setGetTableData(true);
      }
      AddAlertMessage({ type: response.data.type, message: response.data.message });
    }).catch((error) => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    })
  }

  const getEmergencySewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.emergency}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setEmergencySewaTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const emergencySewaEdit = (id) => {
    AHMIS.get(API_URL.emergency + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowEmergencyService(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeEmergencyDeleteModal = () => {
    setShowEmergencyRegisterDeleteModal(false)
    setEmergencyDeleteId(null);
  }

  const onDeleteEmergencyRow = (id) => {
    setShowEmergencyRegisterDeleteModal(true)
    setEmergencyDeleteId(id);
  }

  const deleteAdmissionRow = (data) => {
    AHMIS.delete(API_URL.emergency + "/" + emergencyDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowEmergencyRegisterDeleteModal(false);
          setEmergencyDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getEmergencySewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="Emergency Service Register"
          handleModalOpen={openEmergencyServiceModal}
          getRegisterData={getEmergencySewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showEmergencyService}
        onModalSubmit={handleSubmit(submitEmergencyRegister)}
        onModalClose={closeEmergencyServiceModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="mul-dartaa-dartaa-miti"
              name="dartaaMiti"
              showDefaultDate
              label="दर्ता मिति"
              onChange={handleDateChange}
              defaultDate={modalDefaultValues?.dartaaMiti}
            />
            {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Registration No"
              name="patientType"
              options={PATIENT_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.patientType}
            />
            {errors?.patientType && errors.patientType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Dartaa Number"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.dartaaNumber}
              {...register("dartaaNumber", { required: true })}
            />
            {errors?.dartaaNumber && errors.dartaaNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Time of Registration"
              type="time"
              size="small"
              variant="outlined"
              name="timeOfRegistration"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.timeOfRegistration}
              {...register("timeOfRegistration", { required: true })}
            />
            {errors?.timeOfRegistration && errors.timeOfRegistration?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Name"
              type="text"
              size="small"
              variant="outlined"
              name="name"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.name}
              {...register("name", { required: true })}
            />
            {errors?.name && errors.name?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Family Name"
              size="small"
              type="text"
              variant="outlined"
              name="familyName"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.familyName}
              {...register("familyName", { required: true })}
            />
            {errors?.familyName && errors.familyName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Caste Code"
              type="text"
              size="small"
              variant="outlined"
              name="castCode"
              autoComplete="true"
              fullWidth
              options={CASTE_CODES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.castCode}
            />
            {errors?.casteCode && errors.casteCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.gender}
              options={GENDER_OPTIONS}
            />
            {errors?.gender && errors.gender?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.age}
              {...register("age", { required: true })}
            />
            {errors?.age && errors.age?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              options={AGE_UNITS}
              defaultValue={modalDefaultValues?.ageUnit}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.ageUnit && errors.ageUnit?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="District"
              name="district"
              variant="outlined"
              onChange={handleDistrictChange}
              options={districtOptions}
              defaultValue={modalDefaultValues?.district}
            />
            {errors?.district && errors.district?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Municipality"
              name="municipality"
              variant="outlined"
              options={palikaOptions}
              onChange={handlePalikaNameChange}
              defaultValue={modalDefaultValues?.municipality}
            />
            {errors?.municipality && errors.municipality?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Ward/Tole"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.wardNumber}
              {...register("wardNumber", { required: true })}
            />
            {errors?.wardNumber && errors.wardNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="sourceOfAdmission**"
              label="Source of Admission**"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              defaultDate={modalDefaultValues?.sourceOfAdmission}
              {...register("sourceOfAdmission", { required: true })}
            />
            {errors?.sourceOfAdmission && errors.sourceOfAdmission?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="guardianName"
              label="Guardian Name"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              defaultDate={modalDefaultValues?.guardianName}
              {...register("guardianName", { required: true })}
            />
            {errors?.guardianName && errors.guardianName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="guardianPhone"
              label="Guardian Phone"
              variant="outlined"
              type="number"
              autoComplete="on"
              size="small"
              fullWidth
              defaultDate={modalDefaultValues?.guardianPhone}
              {...register("guardianPhone", { required: true })}
            />
            {errors?.guardianName && errors.guardianName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Brought Dead"
              size="small"
              variant="outlined"
              name="broughtDeath"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.broughtDeath}
              {...register("broughtDeath", { required: true })}
            />
            {errors?.broughtDeath && errors.broughtDeath?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Sign and symptoms"
              size="small"
              variant="outlined"
              name="signAndSymptoms"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.signAndSymptom}
              {...register("signAndSymptoms", { required: true })}
            />
            {errors?.signAndSymptom && errors.signAndSymptom?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Investigations"
              size="small"
              variant="outlined"
              name="investigations"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.investigations}
              {...register("investigations", { required: true })}
            />
            {errors?.investigations && errors.investigations?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Diagnosis"
              size="small"
              variant="outlined"
              name="diagnosis"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.diagnosis}
              {...register("diagnosis", { required: true })}
            />
            {errors?.diagnosis && errors.diagnosis?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Disease Code"
              size="small"
              variant="outlined"
              name="diseasesCode"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.diseaseCode}
              {...register("diseasesCode", { required: true })}
            />
            {errors?.diseaseCode && errors.diseaseCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Treatment/Medicine Prescribed"
              size="small"
              variant="outlined"
              name="medicinePrescribed"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.medicinePrescribed}
              {...register("medicinePrescribed", { required: true })}
            />
            {errors?.medicinePrescribed && errors.medicinePrescribed?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Observation"
              size="small"
              variant="outlined"
              name="observation"
              autoComplete="true"
              fullWidth
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.observation}
              options={POLICE_CASE}
            />
            {errors?.observation && errors.observation?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              name="dischargeDate"
              showDefaultDate
              label="Discharge Date"
              onChange={handleDateChange}
              defaultDate={modalDefaultValues?.dischargeDate}
            />
            {errors?.dischargeDate && errors.dischargeDate?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Discharge Time"
              size="small"
              type="time"
              variant="outlined"
              name="dischargeTime"
              autoComplete="true"
              fullWidth
              {...register("dischargeTime", { required: true })}
            />
            {errors?.dischargeTime && errors.dischargeTime?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Death Code"
              size="small"
              variant="outlined"
              name="deathCode"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.diedCode}
              {...register("deathCode")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Cause of death"
              size="small"
              variant="outlined"
              name="causeOfDeath"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.causeOfDeath}
              {...register("causeOfDeath", { required: true })}
            />
            {errors?.causeOfDeath && errors.causeOfDeath?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Cost exemption Full"
              size="small"
              variant="outlined"
              name="costExemptionFull"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.costExemptionFull}
              {...register("costExemptionFull", { required: true })}
            />
            {errors?.costExemptionFull && errors.costExemptionFull?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Cost exemption Partial"
              size="small"
              variant="outlined"
              name="costExemptionPartial"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.costExemptionFull}
              {...register("costExemptionPartial", { required: true })}
            />
            {errors?.costExemptionFull && errors.costExemptionFull?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Total cost exempted"
              size="small"
              variant="outlined"
              name="totalCostExempted"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.totalCostExempted}
              {...register("totalCostExempted", { required: true })}
            />
            {errors?.totalCostExempted && errors.totalCostExempted?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Code"
              size="small"
              variant="outlined"
              name="code"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.code}
              {...register("code", { required: true })}
            />
            {errors?.code && errors.code?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Gender Violence"
              size="small"
              variant="outlined"
              name="genderViolence"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.genderViolence}
              {...register("genderViolence", { required: true })}
            />
            {errors?.genderViolence && errors.genderViolence?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="Police Case"
              size="small"
              variant="outlined"
              name="policeCase"
              autoComplete="true"
              fullWidth
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.policeCase}
              options={POLICE_CASE}
            />
            {errors?.policeCase && errors.policeCase?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showEmergencyRegisterDeleteModal}
        closeDeleteModel={closeEmergencyDeleteModal}
        deleteRecord={deleteAdmissionRow}
      />
      <EmergencyServiceRegister
        onEditRow={emergencySewaEdit}
        tableData={emergencySewaTableData}
        deleteRow={onDeleteEmergencyRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default EmergencyServiceForm;