import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const UpakarmaReport = (props) => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">योग सेवा प्रतिवेदन </Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="YOG-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={2} rowSpan={2}> सेवाको विवरण</TableCell>
              <TableCell colSpan={5}>उपचारात्मक</TableCell>
              <TableCell rowSpan={2}>प्रवर्द्धनात्मक</TableCell>
              <TableCell rowSpan={2}>अभियानमूलक</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>आसन</TableCell>
              <TableCell>प्राणायाम</TableCell>
              <TableCell>ध्यान</TableCell>
              <TableCell>षट्कर्म</TableCell>
              <TableCell>अन्य </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={2}>सेवाग्राही संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>सेवा संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default UpakarmaReport;
