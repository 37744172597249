
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import CountUp from 'react-countup';
import styles from "../style";

const EmployeeDetails = () => {
  const classes = styles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'primary.main', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={1000}
                    duration={5}
                    className={classes.countValue}
                  />
                  जना
                </Typography>
                <Typography variant="body1"> जम्मा कर्मचारी </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'info.main', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={300}
                    duration={5}
                    className={classes.countValue}
                  />
                  जना
                </Typography>
                <Typography variant="body1"> कएमा दर बन्दी </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'success.main', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={200}
                    duration={5}
                    className={classes.countValue}
                  />
                  जना
                </Typography>
                <Typography variant="body1"> रित पद </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: 'text.primary', color: 'common.white' }}>
            <CardContent className={classes.CardContent}>
              <Box>
                <Typography variant="h3">
                  <CountUp
                    start={0}
                    end={100}
                    duration={5}
                    className={classes.countValue}
                  />
                  जना
                </Typography>
                <Typography variant="body1">जम्मा बिदामा  रहेका कर्मचारी</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeDetails;