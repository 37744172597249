import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import React from "react"

const IndoorServices = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Typography variant="body1" className="report-heading">1. Summary of Indoor Services</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="InDoor-Services">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={16}>A.Inpatient Outcomes</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>Age group</TableCell>
              <TableCell colSpan={2}>Recovered/Cured</TableCell>
              <TableCell colSpan={2}>Not Improved</TableCell>
              <TableCell colSpan={2}>Referred Out</TableCell>
              <TableCell colSpan={2}>DOR/LAMA/DAMA</TableCell>
              <TableCell colSpan={2}>Absconded</TableCell>
              <TableCell colSpan={2}>Death  48 Hours</TableCell>
              <TableCell colSpan={2}>Death ≥ 48 Hours</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
              <TableCell rowSpan={2}>Female</TableCell>
              <TableCell rowSpan={2}>Male</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>≤ 6/12 Year</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6/12 - 1 Year</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>01 - 04 Years</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>05 - 16 Years</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>17 - 59 Years</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>≥ 60 Years</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default IndoorServices;