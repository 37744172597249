import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"

const NewOldPatientCount = (props) => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="New-Old-Patient-Count">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2} >उमेर समूह</TableCell>
              <TableCell colSpan={2} >नयाँ सेवाग्राही संख्या</TableCell>
              <TableCell colSpan={2} >पुनरावृत सेवाग्राही संख्या</TableCell>
              <TableCell colSpan={2} > प्रेषण गरिएको संख्या</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell rowSpan={2}>पुरुष</TableCell>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell rowSpan={2}>पुरुष</TableCell>
              <TableCell rowSpan={2}>महिला</TableCell>
              <TableCell rowSpan={2}>पुरुष</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>०-९ वर्ष</TableCell>
              <TableCell>{props?.tableData?.newFemalePatientZeroToNineYears}</TableCell>
              <TableCell>{props.tableData?.newMalePatientZeroToNineYears}</TableCell>
              <TableCell>{props.tableData?.totalFemalePatientZeroToNineYears}</TableCell>
              <TableCell>{props.tableData?.totalMalePatientZeroToNineYears}</TableCell>
              <TableCell>{props.tableData?.referredFemalePatientZeroToNineYears}</TableCell>
              <TableCell>{props.tableData?.referredMalePatientZeroToNineYears}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>१०-१४ वर्ष</TableCell>
              <TableCell>{props.tableData?.newFemalePatientTenToFourteen}</TableCell>
              <TableCell>{props.tableData?.newMalePatientTenToFourteen}</TableCell>
              <TableCell>{props.tableData?.totalFemalePatientTenToFourteen}</TableCell>
              <TableCell>{props.tableData?.totalMalePatientTenToFourteen}</TableCell>
              <TableCell>{props.tableData?.referredFemalePatientTenToFourteen}</TableCell>
              <TableCell>{props.tableData?.referredMalePatientTenToFourteen}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>१५-१९ वर्ष</TableCell>
              <TableCell>{props.tableData?.newFemalePatientFifteenToNineteen}</TableCell>
              <TableCell>{props.tableData?.newMalePatientFifteenToNineteen}</TableCell>
              <TableCell>{props.tableData?.totalFemalePatientFifteenToNineteen}</TableCell>
              <TableCell>{props.tableData?.totalMalePatientFifteenToNineteen}</TableCell>
              <TableCell>{props.tableData?.referredFemalePatientFifteenToNineteen}</TableCell>
              <TableCell>{props.tableData?.referredMalePatientFifteenToNineteen}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>२०-५९ वर्ष</TableCell>
              <TableCell>{props.tableData?.newFemalePatientTwentyToFiftyNine}</TableCell>
              <TableCell>{props.tableData?.newMalePatientTwentyToFiftyNine}</TableCell>
              <TableCell>{props.tableData?.totalFemalePatientTwentyToFiftyNine}</TableCell>
              <TableCell>{props.tableData?.totalMalePatientTwentyToFiftyNine}</TableCell>
              <TableCell>{props.tableData?.referredFemalePatientTwentyToFiftyNine}</TableCell>
              <TableCell>{props.tableData?.referredMalePatientTwentyToFiftyNine}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>६०-६९ वर्ष</TableCell>
              <TableCell>{props.tableData?.newFemalePatientSixtyToSixtyNine}</TableCell>
              <TableCell>{props.tableData?.newMalePatientSixtyToSixtyNine}</TableCell>
              <TableCell>{props.tableData?.totalFemalePatientSixtyToSixtyNine}</TableCell>
              <TableCell>{props.tableData?.totalMalePatientSixtyToSixtyNine}</TableCell>
              <TableCell>{props.tableData?.referredFemaleSixtyToSixtyNine}</TableCell>
              <TableCell>{props.tableData?.referredMaleSixtyToSixtyNine}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&ge; ७० वर्ष</TableCell>
              <TableCell>{props.tableData?.newFemalePatientSeventyAndAbove}</TableCell>
              <TableCell>{props.tableData?.newMalePatientSeventyAndAbove}</TableCell>
              <TableCell>{props.tableData?.totalFemalePatientSeventyAndAbove}</TableCell>
              <TableCell>{props.tableData?.totalMalePatientSeventyAndAbove}</TableCell>
              <TableCell>{props.tableData?.referredFemalePatientSeventyAndAbove}</TableCell>
              <TableCell>{props.tableData?.referredMalePatientSeventyAndAbove}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default NewOldPatientCount;