import { Box, Breadcrumbs, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Home, NavigateNext } from "@mui/icons-material";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import AHMIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { AppMisc } from "../../../../misc/appMisc";
import { LocalStorage } from "../../../../utils/storage/localStorage";

const BreadCrumbs = () => {
  const [palikaInfo, setPalikaInfo] = useState()
  const [healthClientInfo, setHealthClientInfo] = useState()

  const getPalikaInfo = id => {
    AHMIS.get(API_URL.municipality + "/" + AppUtils.getUrlParam(ID)).then(response => {
      LocalStorage.setItem("palikaInfo", response.data);
      setPalikaInfo(response.data);
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    });
  }

  const getHealthClientInfo = id => {
    AHMIS.get(API_URL.healthClient + "/" + id).then(response => {
      setHealthClientInfo(response?.data);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  useEffect(() => {
    if (AppUtils.getUrlParam(ID) && AppUtils.getUrlParam("clientType")) {
      getHealthClientInfo(AppUtils.getUrlParam(ID))
    }
    else if (AppUtils.getUrlParam(ID)) {
      getPalikaInfo(AppUtils.getUrlParam(ID))
    }
  }, [AppUtils.getUrlParam(ID), AppUtils.getUrlParam("clientType")])

  return (
    <>
      <Box mx={2}>
        <Breadcrumbs separator={<NavigateNext />}>
          <Link href="/admin/dashboard" underline="hover">{<Home />}</Link>
          { /*  TODO: Krishna Refactor the code  */}
          {AppUtils.getUrlParam(ID) && <Link href={`municipality-home?id=${LocalStorage.getItem("palikaInfo")?.id || AppUtils.getUrlParam(ID)}`} underline="hover">{AppMisc.getMunicipalityName((LocalStorage.getItem("palikaInfo")?.palikaName) || palikaInfo?.palikaName)}</Link>}
          {AppUtils.getUrlParam("clientType") && <Link href={`health-client-home?id=${AppUtils.getUrlParam(ID)}&clientType=${AppUtils.getUrlParam("clientType")}`} underline="hover">{healthClientInfo?.name}</Link>}
        </Breadcrumbs>
      </Box>
    </>
  );
};

export default BreadCrumbs;