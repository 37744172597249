import React from "react"
import IndoorServices from "../indoor-services/IndoorServices";
import AyurvedicTherapy from "../ayurvedic-therapy/AyurvedicTherapy";
import DeathInformation from "../death-information/DeathInformation";
import FreeHealthServiceSummery from "../free-health-service-summery/FreeHealthServiceSummery";
import FreeHealthServicesAndSocialSecurityProgramme from "../free-health-services-and-social-security-programme/FreeHealthServicesAndSocialSecurityProgramme";

const HospitalReports = () => {
  return (
    <>
      <IndoorServices />
      <AyurvedicTherapy />
      <DeathInformation />
      <FreeHealthServiceSummery />
      <FreeHealthServicesAndSocialSecurityProgramme />
    </>
  )
}

export default HospitalReports;