import { Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import React from "react"

const GaugharSahariClinicParamarshaSewaRegister = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Gausahar-Sahari-Clinic-Paramarsha-Sewa">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}>क्र.सं.</TableCell>
              <TableCell rowSpan={2}>दर्ता मिति</TableCell>
              <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
              <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
              <TableCell rowSpan={2}>जाती कोड</TableCell>
              <TableCell colSpan={2}>उमेर</TableCell>
              <TableCell colSpan={3}>ठेगाना</TableCell>
              <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
              <TableCell rowSpan={2}>परामर्श दिएको विषय</TableCell>
              <TableCell rowSpan={2}>प्रेषण गरिएको संस्था</TableCell>
              <TableCell rowSpan={2} >सम्पादन</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पहिलो पटक</TableCell>
              <TableCell>थप पटक</TableCell>
              <TableCell>नाम</TableCell>
              <TableCell>थर</TableCell>
              <TableCell>महिला</TableCell>
              <TableCell>पुरुष</TableCell>
              <TableCell>जिल्ला</TableCell>
              <TableCell>नगर/गाउँपालिका</TableCell>
              <TableCell>वडा नं.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <IconButton aria-label="edit">
                    <Edit fontSize="small" />
                  </IconButton>
                  <Tooltip title="मूल दर्ता रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                    <IconButton >
                      <Delete fontSize="small" color='secondary' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default GaugharSahariClinicParamarshaSewaRegister;
