import { HEALTH_CLIENT_INFO, LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_HEALTH_CLIENT_INFO } from "../utils/constants/redux";
import { LocalStorage } from "../utils/storage/localStorage";

const reducer = (healthClientInfo = null, action) => {
  switch (action.type) {
    case SAVE_HEALTH_CLIENT_INFO:
      LocalStorage.setItem(HEALTH_CLIENT_INFO, action.payload);
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return healthClientInfo;
  }
}

export default reducer;