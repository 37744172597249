import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const PardanKarmaReport = (props) => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">प्रधानकर्म प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Ksharasutra-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}> सेवाको विवरण</TableCell>
              <TableCell>लिङ्ग</TableCell>
              <TableCell>वमन</TableCell>
              <TableCell>विरेचन</TableCell>
              <TableCell>निरुह वस्ति</TableCell>
              <TableCell>अनुवासन वस्ति</TableCell>
              <TableCell>नस्य</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={2}>सेवाग्राही संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell>{props.tableData?.femaleVaman}</TableCell>
              <TableCell>{props.tableData?.femaleVirechan}</TableCell>
              <TableCell>{props.tableData?.femaleNiruhaVasti}</TableCell>
              <TableCell>{props.tableData?.femaleAnuvasanVasti}</TableCell>
              <TableCell>{props.tableData?.femaleNasya}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell>{props.tableData?.maleVaman}</TableCell>
              <TableCell>{props.tableData?.maleVirechan}</TableCell>
              <TableCell>{props.tableData?.maleNiruhaVasti}</TableCell>
              <TableCell>{props.tableData?.maleAnuvasanVasti}</TableCell>
              <TableCell>{props.tableData?.maleNasya}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>सेवा संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell>{props.sewaData?.totalServiceFemaleVaman}</TableCell>
              <TableCell>{props.sewaData?.totalServiceFemaleVirechan}</TableCell>
              <TableCell>{props.sewaData?.totalServiceFemaleNiruhaVasti}</TableCell>
              <TableCell>{props.sewaData?.totalServiceFemaleAnuvasanVasti}</TableCell>
              <TableCell>{props.sewaData?.totalServiceFemaleNasya}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell>{props.sewaData?.totalServiceMaleVaman}</TableCell>
              <TableCell>{props.sewaData?.totalServiceMaleVirechan}</TableCell>
              <TableCell>{props.sewaData?.totalServiceMaleNiruhaVasti}</TableCell>
              <TableCell>{props.sewaData?.totalServiceMaleAnuvasanVasti}</TableCell>
              <TableCell>{props.sewaData?.totalServiceMaleNasya}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PardanKarmaReport;