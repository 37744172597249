import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const JesthaNagrikSewaReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">जेष्ठ नागरिक सेवा प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Jestha Nagrik Sewa">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={3} colSpan={2}>सेवा लिएको जम्मा संख्या</TableCell>
              <TableCell colSpan={25}>फलोअपको स्वास्थ्य अवस्था</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={3}>फलोअप महिना</TableCell>
              <TableCell colSpan={6}>BMI </TableCell>
              <TableCell colSpan={6}>Hb% </TableCell>
              <TableCell colSpan={6}>ESR </TableCell>
              <TableCell colSpan={6}>निद्रा</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>घटेको </TableCell>
              <TableCell colSpan={2}>स्थिर </TableCell>
              <TableCell colSpan={2}>बढेको </TableCell>
              <TableCell colSpan={2}>घटेको </TableCell>
              <TableCell colSpan={2}>स्थिर </TableCell>
              <TableCell colSpan={2}>बढेको </TableCell>
              <TableCell colSpan={2}>घटेको </TableCell>
              <TableCell colSpan={2}>स्थिर </TableCell>
              <TableCell colSpan={2}>बढेको </TableCell>
              <TableCell colSpan={2}>घटेको </TableCell>
              <TableCell colSpan={2}>स्थिर </TableCell>
              <TableCell colSpan={2}>बढेको </TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
              <TableCell rowSpan={2}>म</TableCell>
              <TableCell rowSpan={2}>पु</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default JesthaNagrikSewaReport;