import { RESET_PAGE_NUMBER } from "../utils/constants/redux";

const reducer = (resetPageNumber = false, action) => {
  switch (action.type) {
    case RESET_PAGE_NUMBER:
      return !resetPageNumber;
    default:
      return resetPageNumber;
  }
}

export default reducer;