import { CreditCard, Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import { CASTE_CODES, FEMALE, MALE, NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { AppMisc } from "../../../../../misc/appMisc";
import { NEW_PATIENT, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";

const JesthaNagarikaSewaRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }
  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Jestha-Nagarik">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={4}>क्र.सं.</TableCell>
                <TableCell rowSpan={4}>दर्ता मिति </TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर </TableCell>
                {/* <TableCell rowSpan={4}>मिति</TableCell> */}
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={4}>जाती कोड</TableCell>
                </Tooltip>
                <TableCell colSpan={2}>उमेर</TableCell>
                <TableCell colSpan={4}>ठेगाना</TableCell>
                <TableCell rowSpan={4}>सम्पर्क नम्बर</TableCell>
                <TableCell rowSpan={4}>प्रेषण भई आएको संस्था</TableCell>
                <TableCell colSpan={6}>स्वास्थ्य अवस्था</TableCell>
                <TableCell colSpan={6}>जेष्ठ नागरिकको स्वास्थ्य अवस्था र जाँच गरेको मिति</TableCell>
                <TableCell colSpan={18}>फलोअप</TableCell>
                <TableCell colSpan={7}>सेवा विवरण</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>पहिलो पटक</TableCell>
                <TableCell rowSpan={2}>थप पटक</TableCell>
                <TableCell rowSpan={2}>नाम</TableCell>
                <TableCell rowSpan={2}>थर</TableCell>
                <TableCell rowSpan={2}>महिला</TableCell>
                <TableCell rowSpan={2}>पुरुष</TableCell>
                <TableCell rowSpan={2}>जिल्ला</TableCell>
                <TableCell rowSpan={2}>नगर/गाउँपालिका</TableCell>
                <TableCell rowSpan={2}>वडा नं.</TableCell>
                <TableCell rowSpan={2}>टोल</TableCell>
                <TableCell rowSpan={2}>रक्तचाप</TableCell>
                <TableCell rowSpan={2}>तौल</TableCell>
                <TableCell rowSpan={2}>FBS</TableCell>
                <TableCell rowSpan={2}>प्रकृति</TableCell>
                <TableCell rowSpan={2}>रोग</TableCell>
                <TableCell rowSpan={2}>परीक्षण</TableCell>
                <TableCell rowSpan={2}>उचाइ मि.</TableCell>
                <TableCell rowSpan={2}>तौल केजी</TableCell>
                <TableCell rowSpan={2}>BMI</TableCell>
                <TableCell rowSpan={2}>Hb %</TableCell>
                <TableCell rowSpan={2}>ESR</TableCell>
                <TableCell rowSpan={2}>निन्द्रा</TableCell>
                <TableCell colSpan={6}>२ महिना</TableCell>
                <TableCell colSpan={6}>४ महिना</TableCell>
                <TableCell colSpan={6}>६ महिना</TableCell>
                <TableCell rowSpan={2}>क्रम संख्या</TableCell>
                <TableCell rowSpan={2}>मिति</TableCell>
                <TableCell colSpan={2}>औषधी</TableCell>
                <TableCell rowSpan={2}>जटिलता</TableCell>
                <TableCell rowSpan={2}>परीक्षण /सल्लाह</TableCell>
                <TableCell rowSpan={2}>कैफियत</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>उचाइ मि.</TableCell>
                <TableCell rowSpan={2}>तौल केजी</TableCell>
                <TableCell rowSpan={2}>BMI</TableCell>
                <TableCell rowSpan={2}>Hb %</TableCell>
                <TableCell rowSpan={2}>ESR</TableCell>
                <TableCell rowSpan={2}>निन्द्रा</TableCell>
                <TableCell rowSpan={2}>उचाइ मि.</TableCell>
                <TableCell rowSpan={2}>तौल केजी</TableCell>
                <TableCell rowSpan={2}>BMI</TableCell>
                <TableCell rowSpan={2}>Hb %</TableCell>
                <TableCell rowSpan={2}>ESR</TableCell>
                <TableCell rowSpan={2}>निन्द्रा</TableCell>
                <TableCell rowSpan={2}>उचाइ मि.</TableCell>
                <TableCell rowSpan={2}>तौल केजी</TableCell>
                <TableCell rowSpan={2}>BMI</TableCell>
                <TableCell rowSpan={2}>Hb %</TableCell>
                <TableCell rowSpan={2}>ESR</TableCell>
                <TableCell rowSpan={2}>निन्द्रा</TableCell>
                <TableCell rowSpan={2}>अश्वगन्धा चूर्ण १०० ग्राम</TableCell>
                <TableCell rowSpan={2}>अमलकी चुर्ण १०० ग्राम</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData.length > 0 ? (
                mainRegisterData?.map((row, index) => (
                  <TableRow>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                    <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row.dartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientFirstName || "-"}</TableCell>
                    <TableCell>{row?.patientLastName || "-"}</TableCell>
                    <TableCell>{row?.casteCode && AppUtils.replaceWithNepaliDigit(row.casteCode) || "-"}</TableCell>
                    <TableCell>{row?.gender === FEMALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                    <TableCell>{row?.gender === MALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                    <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                    <TableCell>{row?.palikaName && AppMisc.getMunicipalityName(row?.palikaName)}</TableCell>
                    <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                    <TableCell>{row?.gaunOrTole || "-"}</TableCell>
                    <TableCell>{row?.phoneNumber && AppUtils.replaceWithNepaliDigit(row?.phoneNumber)}</TableCell>
                    <TableCell>{row.sentFromOrganizationName || "-"}</TableCell>
                    <TableCell>{row.bloodPressure || "-"}</TableCell>
                    <TableCell>{row.weight || "-"}</TableCell>
                    <TableCell>{row.fbs || "-"}</TableCell>
                    <TableCell>{row.nature || "-"}</TableCell>
                    <TableCell>{row.disease || "-"}</TableCell>
                    <TableCell>{row.test || "-"}</TableCell>
                    <TableCell>{row.jesthaNagrikHeight || "-"}</TableCell>
                    <TableCell>{row.jesthaNagrikWeight || "-"}</TableCell>
                    <TableCell>{row.jesthaNagrikBmi || "-"}</TableCell>
                    <TableCell>{row.jesthaNagrikHb || "-"}</TableCell>
                    <TableCell>{row.jesthNigrikEsr || "-"}</TableCell>
                    <TableCell>{row.jesthaNigrikSleepHour || "-"}</TableCell>
                    <TableCell>{row.followUpTwoMonthHeight || "-"}</TableCell>
                    <TableCell>{row.followUpTwoMonthWeight || "-"}</TableCell>
                    <TableCell>{row.followUpTwoMonthBmi || "-"}</TableCell>
                    <TableCell>{row.followUpTwoMonthHb || "-"}</TableCell>
                    <TableCell>{row.followUpTwoMonthEsr || "-"}</TableCell>
                    <TableCell>{row.followUpTwoMonthSleepHour || "-"}</TableCell>
                    <TableCell>{row.followUpFourMonthHeight || "-"}</TableCell>
                    <TableCell>{row.followUpFourMonthWeight || "-"}</TableCell>
                    <TableCell>{row.followUpFourMonthBmi || "-"}</TableCell>
                    <TableCell>{row.followUpFourMonthHb || "-"}</TableCell>
                    <TableCell>{row.followUpFourMonthEsr || "-"}</TableCell>
                    <TableCell>{row.followUpFourMonthSleepHour || "-"}</TableCell>
                    <TableCell>{row.followUpSixMonthHeight || "-"}</TableCell>
                    <TableCell>{row.followUpSixMonthWeight || "-"}</TableCell>
                    <TableCell>{row.followUpSixMonthBmi || "-"}</TableCell>
                    <TableCell>{row.followUpSixMonthHb || "-"}</TableCell>
                    <TableCell>{row.followUpSixMonthEsr || "-"}</TableCell>
                    <TableCell>{row.followUpSixMonthHour || "-"}</TableCell>
                    <TableCell>{row.sequenceNumber || "-"}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row.date)}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{row.complexity || "-"}</TableCell>
                    <TableCell>{row.testing || "-"}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        <Tooltip title="स्वास्थ्य सेवा कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <CreditCard fontSize="small" />
                        </Tooltip>
                        <Tooltip title="मूल दर्ता रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color='secondary' onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="41" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default JesthaNagarikaSewaRegister;