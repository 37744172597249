import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react"

const VillageAyurvedaClinicReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading"> गाउँघर/शहरी आयुर्वेद क्लिनिक प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Village-Ayurveda-Clinic-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={1}>विवरण </TableCell>
              <TableCell rowSpan={1}>महिला</TableCell>
              <TableCell rowSpan={1}>पुरूष</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>सेवा लिएको जम्मा संख्या</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>जेष्ठ नागरिकको जम्मा संख्या</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>स्तनपायी आमाको जम्मा संख्या</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>वात व्याधी</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>जीर्ण श्वास</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>मधुमेह</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>उच्च रक्तचाप</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ग्रन्थी/अर्वुद जन्य रोग</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>अन्तः स्राव ग्रन्थीजन्य रोग</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>मानसिक रोग</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>परामर्श सेवा</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>अन्य</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default VillageAyurvedaClinicReport;