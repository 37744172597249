import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const MonthlyMedicineDispensingReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">मासिक औषधी वितरण विवरण</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Monthly-Medicine-Dispensing-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={1}> क्र.सं. </TableCell>
              <TableCell rowSpan={1}>औषधीको नाम</TableCell>
              <TableCell rowSpan={1}>फायल (संख्या)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>अविपतकर चुर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>हिङग्वाष्टक चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>महायोगराज गुग्गुल १० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4</TableCell>
              <TableCell>महानारायण तेल ६० एम. एल.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell>सितोपलादि चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6</TableCell>
              <TableCell>टंकण भष्म १० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>7</TableCell>
              <TableCell>पुष्यानुग चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>8</TableCell>
              <TableCell>वालामृत १०० एम. एल.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>9</TableCell>
              <TableCell>मृगमदासव १५ एम. एल.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>10</TableCell>
              <TableCell>सर्पगन्धाघन वटि २५ ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>11</TableCell>
              <TableCell>निम्वादि चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>12</TableCell>
              <TableCell>गन्धक रसायन १० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13</TableCell>
              <TableCell>खरिदादि वटी १० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>14</TableCell>
              <TableCell>षडविन्दु तेल १० एम. एल.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15</TableCell>
              <TableCell>योगराज गुग्गुल २५ ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16</TableCell>
              <TableCell>महासुदर्शन चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>17</TableCell>
              <TableCell>महाशंख वटी चूर्ण  १० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18</TableCell>
              <TableCell>विल्वादी चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>19</TableCell>
              <TableCell>जात्यादी तेल ६० एम. एल.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>20</TableCell>
              <TableCell>कैशोर गुग्गुल २५ ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21</TableCell>
              <TableCell>चन्द्रप्रभावटी २५ ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22</TableCell>
              <TableCell>त्रिफला चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23</TableCell>
              <TableCell>अश्वगन्धा चूर्ण ५० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24</TableCell>
              <TableCell>अश्वगन्धा चूर्ण १०० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25</TableCell>
              <TableCell>आमलकी चूर्ण १०० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>26</TableCell>
              <TableCell>दशमुल तेल ६० एम. एल.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27</TableCell>
              <TableCell>शतावरी चूर्ण १०० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>28</TableCell>
              <TableCell>ज्वानो १०० ग्राम</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>29</TableCell>
              <TableCell>अन्य</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default MonthlyMedicineDispensingReport;