import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomModal from "../../../components/modal/CustomModal";
import { useUserDispatch } from "../../../context/userContext";
import { AppUtils } from "../../../utils/appUtils";
import { LOGOUT_SUCCESS, PASSWORD_DO_NOT_MATCHES, REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import UserInfo from "./helpers/user-info/UserInfo";
import styles from "./style";
import { Box } from "@mui/system";
import { Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import AHMIS, { API_URL } from "../../../api/api";

export default function UserProfile(props) {
  const classes = styles();
  const { register, handleSubmit, formState:{errors}, watch } = useForm();
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  var userDispatch = useUserDispatch();

  const closePasswordChangeModal = () => {
    setOpenPasswordChangeModal(false);
  };

  const  handleClickShowPassword=()=> {
    setShowPassword(!showPassword)
  }

  const submitPasswordChangeModal = data => {
    AHMIS.post(API_URL.changePassword, data)
      .then(response => {
        if (response.data.type === "success") {
          AppUtils.removeUserRef();
          userDispatch({ type: LOGOUT_SUCCESS });
          props.history.push("/");
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Box className={classes.profilePage}>
      <h3 className="border-bottom-heading">
        प्रयोगकर्ता सेटिङ सम्पादन गर्नुहोस् ।
      </h3>
      <Box>
        <UserInfo />
      </Box>
      <Box mt={2} py={2}>
        <h3 className="border-bottom-heading">
          पासवर्ड परिवर्तन गर्नुहोस् ।
        </h3>
        <Box align="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={() => {
              setOpenPasswordChangeModal(true);
            }}
          >
            पासवर्ड परिवर्तन गर्नुहोस्
          </Button>
        </Box>
        <CustomModal
          title="पासवर्ड परिवर्तन गर्नुहोस् ।"
          showModal={openPasswordChangeModal}
          onModalSubmit={handleSubmit(submitPasswordChangeModal)}
          onModalClose={closePasswordChangeModal}
        >
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                id="outlined-old-password-input"
                label="वर्तमान पासवर्ड"
                variant="outlined"
                name="oldPassword"
                type={showPassword ? "text" : "password"}
                {...register("oldPassword", { required: true })}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              {errors.oldPassword && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                id="outlined-new-password-input"
                label="नयाँ पासवर्ड"
                size="small"
                variant="outlined"
                name="newPassword"
                {...register("newPassword", { required: true })}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.newPassword && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                id="outlined-confirm-password-input"
                label="नयाँ पासवर्ड सुनिश्चित गर्नुहोस"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                name="confirmPassword"
                {...register("confirmPassword", { required: true , validate: value => value === watch("newPassword")})}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.confirmPassword && errors.confirmPassword.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
              {errors.confirmPassword && errors.confirmPassword.type === "validate" && <span className="error-message">{PASSWORD_DO_NOT_MATCHES} </span>}
            </Grid>
          </Grid>
        </CustomModal>
      </Box>
    </Box>
  );
}