import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import ReportHeader from '../component/report-header/ReportHeader';

const SalyaChikitsaReport = () => {
  return (
    <>
      <ReportHeader />
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Typography variant="body1" className="report-heading">शल्य चिकित्सा सेवा प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="salyachikitsa-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={2}> सेवाको विवरण</TableCell>
              <TableCell>क्षारसूत्र</TableCell>
              <TableCell>अग्निकर्म</TableCell>
              <TableCell>रक्तमोक्षण</TableCell>
              <TableCell>विशिष्ठिकृत शल्य सेवा</TableCell>
              <TableCell>अन्य शल्य सेवा</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={2}>सेवाग्राही संख्या</TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>सेवा संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>

      </TableContainer>
    </>
  )
}

export default SalyaChikitsaReport;
