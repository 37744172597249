import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../api/api";
// import logo from "../../assets/img/logo.png";
import { Person as AccountIcon, Logout, Menu as MenuIcon, PhoneInTalk, Settings } from "@mui/icons-material";
import { AppBar, Avatar, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import classNames from "classnames";
import AHMIS from "../../api/api";
import { useUserDispatch } from "../../context/userContext";
import { AppMisc } from "../../misc/appMisc";
import { AppUtils } from "../../utils/appUtils";
import { ERROR, LOGOUT_SUCCESS, PROFILE_IMAGE_URL, ROLE_AHMIS_APPLICATIONSETTING_SAVESETTING, ROLE_AHMIS_AUTHENTICATION_LOGOUT, SOMETHING_WENT_WRONG, SUCCESS, TOGGLE_SIDEBAR } from "../../utils/constants";
import { LocalStorage } from "../../utils/storage/localStorage";
import AddAlertMessage from "../alert/Alert";
import styles from "./style";

export default function Header(props) {
  const dispatch = useDispatch();
  const [userFullName, setUserFullName] = useState("AAMIS-NEPAL");
  const userInfo = useSelector(state => state.userInfo);
  const healthClientInfo = useSelector(state => state.healthClientInfo);
  const permissions = useSelector(state => state.permissions);

  const classes = styles();
  let navigate = useNavigate("/")

  // global
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  const getHealthClientInfoLabel = (name, district, palika) => {
    return `${name} (${AppMisc.getMunicipalityName(palika)}, ${AppMisc.getDistrictName(district)})`
  }

  // useEffect(() => {
  //   if (!healthClientInfo && permissions?.includes(ROLE_HMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO)) {
  //     dispatch(getHealthClientAndPalikaInfoData());
  //   }
  // }, [permissions, healthClientInfo])

  // useEffect(() => {
  //   if (permissions) {
  //     if (permissions.includes(ROLE_HMIS_USER_GETUSERINFO)) {
  //       userInfo ? setUserFullName(userInfo.fullName) : dispatch(getUserInfo());
  //     }
  //   } else {
  //     dispatch({ type: SAVE_USER_PERMISSIONS, payload: LocalStorage.getItem(PERMISSIONS) })
  //   }
  // }, [permissions, userInfo, dispatch])

  const logOut = () => {
    AHMIS.post(API_URL.logOut)
      .then(response => {
        let data = response.data;
        if (data.type === SUCCESS) {
          AppUtils.removeUserRef();
          userDispatch({ type: LOGOUT_SUCCESS });
          dispatch({ type: LOGOUT_SUCCESS });
          navigate("/");
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <AppBar position="fixed" className={classes.appBar} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => dispatch({ type: TOGGLE_SIDEBAR })}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          <MenuIcon
            classes={{
              root: classNames(
                classes.headerIcon,
                classes.headerIconCollapse,
              ),
            }}
          />
        </IconButton>
        {/* <img src={logo} alt="HMIS" width="36"></img> */}
        <Typography variant="h6" className={classes.brand} sx={{ fontSize: "1rem" }}>
          Ayurveda and Alternative Medicine Information System (AAMIS-NEPAL)
        </Typography>
        <Box display="flex" className={classes.userProfileMenu} justifyContent="center" alignItems="center">
          <Box className={classes.supportTextContainer}>
            <PhoneInTalk color="primary" fontSize="small" />
            <Typography className={classes.supportText} color="primary">Technical Support: 9851340247, 9851340248</Typography>
          </Box>
          <Box className="hide-on-xs">
            {/* {healthClientInfo && (
              <Typography color="textSecondary" className={classes.healthClientName}>
                {getHealthClientInfoLabel(healthClientInfo.name, healthClientInfo.district, healthClientInfo.palikaName)}
              </Typography>
            )} */}
          </Box>
          <Box className={classes.usernameContainer} display="flex" justifyContent="center" alignItems="center" onClick={e => setProfileMenu(e.currentTarget)}>
            <Typography variant="body2" className={classes.username}>
              {userFullName}
            </Typography>
            <Avatar alt="Avatar" src={LocalStorage.getItem(PROFILE_IMAGE_URL)} />
          </Box>
        </Box>
        <Menu anchorEl={profileMenu} open={Boolean(profileMenu)} onClose={() => setProfileMenu(null)} classes={{ paper: classes.profileMenu }} disableAutoFocusItem>
          <MenuItem className={classes.profileMenuItem}>
            <Link href="profile" variant="body1" underline="none" className={classes.profileMenuLink}>
              <AccountIcon className={classes.profileMenuIcon} />
              Profile
            </Link>
          </MenuItem>
          {permissions?.includes(ROLE_AHMIS_APPLICATIONSETTING_SAVESETTING) &&
            <MenuItem className={classes.profileMenuItem}>
              <Link href="setting" variant="body1" underline="none" className={classes.profileMenuLink}>
                <Settings className={classes.profileMenuIcon} />
                Setting
              </Link>
            </MenuItem>
          }
          {permissions?.includes(ROLE_AHMIS_AUTHENTICATION_LOGOUT) && (
            <MenuItem className={classes.profileMenuItem}>
              <Link onClick={logOut} variant="body1" underline="none" className={classes.profileMenuLink}>
                <Logout className={classes.profileMenuIcon} />
                Logout
              </Link>
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}