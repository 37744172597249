import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"

const FreeHealthServiceSummery = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Free-Health-Service-Summery">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={3}>Free health service summary</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost Exemption</TableCell>
              <TableCell>No. of  Patients</TableCell>
              <TableCell>Total Exempted cost (NRS)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>Partially</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completely</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default FreeHealthServiceSummery;