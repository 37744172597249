import { Box, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import NepaliCalender from "../../../components/nepali-calender"
import JesthaNagarikaSewaRegister from "../../users/components/register/jestha-nagarika-sewa-register/JesthaNagarikaSewaRegister"
import { useForm } from "react-hook-form"
import { CASTE_CODES, ERROR, GENDER_OPTIONS, PATIENT_TYPES, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import { AGE_UNITS } from "../../../utils/constants/forms"
import { AppMisc } from "../../../misc/appMisc"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"

const JesthaNagarikaSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("Jestha Nagarika Sewa Register")
  const [showJesthaNagarikaSewaModal, setJesthaNagarikaSewaModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [getTableData, setGetTableData] = useState(false);
  const [JesthaNagarikaSewaTableData, setJesthaNagarikaSewaTableData] = useState([]);
  const [showJesthaNigrikDeleteModal, setShowJesthaNigrikDeleteModal] = useState(false);
  const districtOptions = AppMisc.getDistrictOptions();
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [jesthaNigrikDeleteId, setJesthaNigrikDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, } = useForm();

  useEffect(() => {
    register("district", { required: true });
    register("palikaName", { required: true });
    register("dartaaMiti", { required: true });
    register("patientType", { required: true });
    register("patientType", { required: true });
    register("gender", { required: true });
    register("ageUnit", { required: true })
    register("date", { required: true })
  }, [register]);

  useEffect(() => {
    showJesthaNagarikaSewaModal && reset();
  }, [reset, showJesthaNagarikaSewaModal])

  const openJesthaNagarikaSewaModal = () => {
    setJesthaNagarikaSewaModal(true);
  }

  const closeJesthaNagarikaSewaModal = () => {
    setJesthaNagarikaSewaModal(false);
    setModalDefaultValues();
    reset();
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const submitJesthaNagarikaSewaRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.jesthanagarik, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeJesthaNagarikaSewaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getJesthaNagarikaSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.jesthanagarik}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setJesthaNagarikaSewaTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const jesthaNagrikSewaEdit = (id) => {
    AHMIS.get(API_URL.jesthanagarik + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setJesthaNagarikaSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }
  const closeJesthaNigrikDeleteModal = () => {
    setShowJesthaNigrikDeleteModal(false)
    setJesthaNigrikDeleteId(null);
  }

  const onDeleteJesthaNigrikRow = (id) => {
    setShowJesthaNigrikDeleteModal(true)
    setJesthaNigrikDeleteId(id);
  }

  const deleteJesthaNigrikRow = (data) => {
    AHMIS.delete(API_URL.jesthanagarik + "/" + jesthaNigrikDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowJesthaNigrikDeleteModal(false);
          setJesthaNigrikDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getJesthaNagarikaSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="Jestha Nagarika Sewa Register"
          handleModalOpen={openJesthaNagarikaSewaModal}
          getRegisterData={getJesthaNagarikaSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
          showRegisterHideSection
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showJesthaNagarikaSewaModal}
        onModalSubmit={handleSubmit(submitJesthaNagarikaSewaRegister)}
        onModalClose={closeJesthaNagarikaSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                showDefaultDate
                label="दर्ता मिति"
                onChange={handleDateChange}
                defaultDate={modalDefaultValues?.dartaaMiti}
              />
              {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवाग्राहीको किसिम"
              name="patientType"
              options={PATIENT_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.patientType}
            />
            {errors?.patientType && errors.patientType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.dartaaNumber}
              {...register("dartaaNumber")}
            />
            {errors?.dartaaNumber && errors.dartaaNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="patientFirstName"
              autoComplete="on"
              fullWidth
              defaultValue={modalDefaultValues?.patientFirstName}
              {...register("patientFirstName")}
            />
            {errors?.patientFirstName && errors.patientFirstName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको थर"
              type="text"
              size="small"
              variant="outlined"
              name="patientLastName"
              autoComplete="on"
              fullWidth
              {...register("patientLastName")}
              defaultValue={modalDefaultValues?.patientLastName}
            />
            {errors?.patientLastName && errors.patientLastName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              options={GENDER_OPTIONS}
              defaultValue={modalDefaultValues?.gender}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.gender && errors.gender?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="date-of-birth-of-child"
                label="जन्म मिति"
                name="dateOfBirth"
                defaultDate={modalDefaultValues?.dateOfBirth}
                onChange={handleDateChange}
              />
            </Box>
            {errors?.dateOfBirth && errors.dateOfBirth?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.age}
              {...register("age")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.ageUnit}
              options={AGE_UNITS}
            />
            {errors?.age && errors.age?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="जाती"
              size="small"
              name="casteCode"
              options={CASTE_CODES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.casteCode}
            />
            {errors?.ageUnit && errors.ageUnit?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="जिल्ला"
              name="district"
              variant="outlined"
              onChange={handleDistrictChange}
              options={districtOptions}
              defaultValue={modalDefaultValues?.district} />
            {errors?.district && errors.district?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="नगर/गाउँपालिका"
              name="palikaName"
              variant="outlined"
              options={palikaOptions}
              onChange={handlePalikaNameChange}
              defaultValue={modalDefaultValues?.palikaName}
            />
            {errors?.palikaName && errors.palikaName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="वडा नं"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.wardNumber}
              {...register("wardNumber",)}
            />
            {errors?.wardNumber && errors.wardNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="gaunOrTole"
              label="गाँउ/टोल"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              defaultValue={modalDefaultValues?.gaunOrTole}
              {...register("gaunOrTole",)}
            />
            {errors?.gaunOrTole && errors.gaunOrTole?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सम्पर्क नम्बर"
              size="small"
              variant="outlined"
              name="phoneNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.phoneNumber}
              {...register("phoneNumber")}
            />
            {errors?.phoneNumber && errors.phoneNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="प्रेषण भई आएको संस्था"
              size="small"
              variant="outlined"
              name="sentFromOrganizationName"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.sentFromOrganizationName}
              {...register("sentFromOrganizationName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="रक्तचाप"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="FBS"
              size="small"
              variant="outlined"
              name="fbs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("fbs")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रोग"
              size="small"
              variant="outlined"
              name="disease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.disease}
              {...register("disease")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.test}
              {...register("test")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल केजी"
              size="small"
              variant="outlined"
              name="jesthaNagrikWeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.jesthaNagrikWeight}
              {...register("jesthaNagrikWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उचाइ मि"
              size="small"
              variant="outlined"
              name="jesthaNagrikHeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.jesthaNagrikHeight}
              {...register("jesthaNagrikHeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="BMI"
              size="small"
              variant="outlined"
              name="jesthaNagrikBmi"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.jesthaNagrikBmi}
              {...register("jesthaNagrikBmi")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="Hb gm %"
              size="small"
              variant="outlined"
              name="jesthaNagrikHb"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.jesthaNagrikHb}
              {...register("jesthaNagrikHb")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="ESR mm/hr"
              size="small"
              variant="outlined"
              name="jesthNigrikEsr"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.jesthNigrikEsr}
              {...register("jesthNigrikEsr")}
            />

          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="निन्द्रा ‍घण्टा"
              size="small"
              variant="outlined"
              name="jesthaNigrikSleepHour"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.jesthaNigrikSleepHour}
              {...register("jesthaNigrikSleepHour")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उचाइ मि"
              size="small"
              variant="outlined"
              name="followUpTwoMonthHeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpTwoMonthHeight}
              {...register("followUpTwoMonthHeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल केजी"
              size="small"
              variant="outlined"
              name="followUpTwoMonthWeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpTwoMonthWeight}
              {...register("followUpTwoMonthWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="BMi"
              size="small"
              variant="outlined"
              name="followUpTwoMonthBmi"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpTwoMonthBmi}
              {...register("followUpTwoMonthBmi")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="Hb gm %"
              size="small"
              variant="outlined"
              name="followUpTwoMonthHb"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpTwoMonthHb}
              {...register("followUpTwoMonthHb")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="ESR mm/hr"
              size="small"
              variant="outlined"
              name="followUpTwoMonthEsr"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpTwoMonthEsr}
              {...register("followUpTwoMonthEsr")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="निन्द्रा ‍घण्टा"
              size="small"
              variant="outlined"
              name="followUpTwoMonthSleepHour"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpTwoMonthSleepHour}
              {...register("followUpTwoMonthSleepHour")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उचाइ मि"
              size="small"
              variant="outlined"
              name="followUpFourMonthHeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpFourMonthHeight}
              {...register("followUpFourMonthHeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल केजी"
              size="small"
              variant="outlined"
              name="followUpFourMonthWeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpFourMonthWeight}
              {...register("followUpFourMonthWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="BMi"
              size="small"
              variant="outlined"
              name="followUpFourMonthBmi"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpFourMonthBmi}
              {...register("followUpFourMonthBmi")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="Hb gm %"
              size="small"
              variant="outlined"
              name="followUpFourMonthHb"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpFourMonthHb}
              {...register("followUpFourMonthHb")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="ESR mm/hr"
              size="small"
              variant="outlined"
              name="followUpFourMonthEsr"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpFourMonthEsr}
              {...register("followUpFourMonthEsr")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="निन्द्रा ‍घण्टा"
              size="small"
              variant="outlined"
              name="followUpFourMonthHour"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpFourMonthSleepHour}
              {...register("followUpFourMonthSleepHour")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उचाइ मि"
              size="small"
              variant="outlined"
              name="followUpSixMonthHeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpSixMonthHeight}
              {...register("followUpSixMonthHeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल केजी"
              size="small"
              variant="outlined"
              name="followUpSixMonthWeight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpSixMonthWeight}
              {...register("followUpSixMonthWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="BMi"
              size="small"
              variant="outlined"
              name="followUpSixMonthBmi"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpSixMonthBmi}
              {...register("followUpSixMonthBmi")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="Hb gm %"
              size="small"
              variant="outlined"
              name="followUpSixMonthHb"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpSixMonthHb}
              {...register("followUpSixMonthHb")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="ESR mm/hr"
              size="small"
              variant="outlined"
              name="followUpSixMonthEsr"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpSixMonthEsr}
              {...register("followUpSixMonthEsr")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="निन्द्रा ‍घण्टा"
              size="small"
              variant="outlined"
              name="followUpSixMonthHour"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.followUpSixMonthHour}
              {...register("followUpSixMonthHour")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="क्रम संख्या"
              size="small"
              variant="outlined"
              name="sequenceNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.sequenceNumber}
              {...register("sequenceNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="मिति"
              name="date"
              defaultDate={modalDefaultValues?.date}
              onChange={handleDateChange}
              defaultValue={modalDefaultValues?.date}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="अश्वगन्धा चुर्ण १०० ग्राम"
              size="small"
              variant="outlined"
              name="ashwagandhaChurna"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="अमलकी चुर्ण १०० ग्राम"
              size="small"
              variant="outlined"
              name="aamalakeeChoorn"
              autoComplete="true"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexity}
              {...register("complexity")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण /सल्लाह"
              size="small"
              variant="outlined"
              name="testing"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("testing")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showJesthaNigrikDeleteModal}
        closeDeleteModel={closeJesthaNigrikDeleteModal}
        deleteRecord={deleteJesthaNigrikRow}
      />
      <JesthaNagarikaSewaRegister
        onEditRow={jesthaNagrikSewaEdit}
        tableData={JesthaNagarikaSewaTableData}
        deleteRow={onDeleteJesthaNigrikRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default JesthaNagarikaSewaForm;