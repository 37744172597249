import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const UpakarmaReport = (props) => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">उपक्रम प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Ksharasutra-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={2}> सेवाको विवरण</TableCell>
              <TableCell>स्थानिक बस्ति</TableCell>
              <TableCell>शिरोधारान</TableCell>
              <TableCell>पोटली स्वेद</TableCell>
              <TableCell>क्रियाकल्प</TableCell>
              <TableCell>पिचु</TableCell>
              <TableCell>प्रक्षालन</TableCell>
              <TableCell>अन्य</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={2}>सेवाग्राही संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell>{props.tableData?.femaleSthanayabasti}</TableCell>
              <TableCell>{props.tableData?.femaleSirodhar}</TableCell>
              <TableCell>{props.tableData?.femalePotaliSwad}</TableCell>
              <TableCell>{props.tableData?.femaleKriyaKalpa}</TableCell>
              <TableCell>{props.tableData?.femalePichu}</TableCell>
              <TableCell>{props.tableData?.femalePrakshyalan}</TableCell>
              <TableCell>{props.tableData?.femaleOthers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell>{props.tableData?.maleSthanayabasti}</TableCell>
              <TableCell>{props.tableData?.maleSirodhar}</TableCell>
              <TableCell>{props.tableData?.malePotaliSwad}</TableCell>
              <TableCell>{props.tableData?.maleKriyaKalpa}</TableCell>
              <TableCell>{props.tableData?.malePichu}</TableCell>
              <TableCell>{props.tableData?.malePrakshyalan}</TableCell>
              <TableCell>{props.tableData?.maleOthers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>सेवा संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell>{props.tableData?.totalFemaleSthanayabasti}</TableCell>
              <TableCell>{props.tableData?.totalFemaleSirodhar}</TableCell>
              <TableCell>{props.tableData?.totalFemalePotaliSwad}</TableCell>
              <TableCell>{props.tableData?.totalFemaleKriyaKalpa}</TableCell>
              <TableCell>{props.tableData?.totalFemalePichu}</TableCell>
              <TableCell>{props.tableData?.totalFemalePrakshyalan}</TableCell>
              <TableCell>{props.tableData?.totalFemaleOthers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell>{props.tableData?.totalMaleSthanayabasti}</TableCell>
              <TableCell>{props.tableData?.totalMaleSirodhar}</TableCell>
              <TableCell>{props.tableData?.totalMalePotaliSwad}</TableCell>
              <TableCell>{props.tableData?.totalMaleKriyaKalpa}</TableCell>
              <TableCell>{props.tableData?.totalMalePichu}</TableCell>
              <TableCell>{props.tableData?.totalMalePrakshyalan}</TableCell>
              <TableCell>{props.tableData?.totalMaleOthers}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default UpakarmaReport;
