import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import NepaliCalender from "../../components/nepali-calender/index";
// import HealthClientSelect from '../health-client-select/HealthClientSelect';
import styles from "./style";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Add, MoreVert, Search } from "@mui/icons-material";
import { ROLE_USER_MUNICIPALITY, SEARCH_TIME_OUT } from "../../utils/constants";
import { AppUtils } from "../../utils/appUtils";
import { DateUtils } from "../../utils/dateUtils";
import HealthClientSelect from "../health-client-select/HealthClientSelect";
export default function RegisterHeader({ handleModalOpen, getRegisterData, onHeaderParamChange, lazyLoading, showSearchField, ...props }) {
  const classes = styles();
  const { register, setValue, watch } = useForm();
  const [registerDate, setRegisterDate] = useState({
    dateFrom: null,
    dateTo: null
  });
  const [healthClientId, setHealthClientId] = useState(null);
  const [searchRecord, setSearchRecord] = useState(false);
  const isMunicipalityUser = AppUtils.getUserRole() === ROLE_USER_MUNICIPALITY;
  const watchFields = watch(["searchText"]);
  const validSearchTextRegex = /^[a-zA-Z0-9\-._~:/?#[\]@!$&',;=%]+$/;
  let getTableData = lazyLoading ? onHeaderParamChange : getRegisterData;

  useEffect(() => {
    if (registerDate.dateFrom && registerDate.dateTo) {
      isMunicipalityUser ? (healthClientId && getTableData(registerDate.dateFrom, registerDate.dateTo, watchFields.searchText, healthClientId)) : getTableData(registerDate.dateFrom, registerDate.dateTo, watchFields.searchText);
    }
  }, [registerDate, healthClientId, searchRecord]);

  const handleRegisterDateFromSelect = (name, dateInMilli) => {
    setRegisterDate(prev => ({
      ...prev,
      dateFrom: dateInMilli
    }));
  }

  const handleRegisterDateToSelect = (name, dateInMilli) => {
    setRegisterDate(prev => ({
      ...prev,
      dateTo: dateInMilli
    }));
  }

  useEffect(() => {
    if (registerDate.dateFrom && registerDate.dateTo && props.getTableData) {
      isMunicipalityUser ? (healthClientId && getRegisterData(registerDate.dateFrom, registerDate.dateTo, watchFields.searchText, healthClientId)) : getRegisterData(registerDate.dateFrom, registerDate.dateTo, watchFields.searchText);
    }
  }, [registerDate, healthClientId, props.getTableData]);

  const handleHealthClientChange = id => {
    setHealthClientId(id);
  }

  const onSearchParamChange = (e) => {
    setTimeout(() => {
      setSearchRecord(!searchRecord)
    }, SEARCH_TIME_OUT)
  }

  return (
    <Box className={classes.registerHeaderContainer} borderBottom={1}>
      <Typography variant="h6" className={classes.registerTitle}>
        {props.registerTitle}
      </Typography>
      {/* {showSearchField &&
        <TextField
          label="नाम/मुल दर्ता नं. बाट खोज्नुहोस्"
          name="searchText"
          type="text"
          size="small"
          {...register("searchText")}
          onKeyDown={onSearchParamChange}
          variant="outlined"
          InputProps={{
            endAdornment: <Box pl={1} pt={1}><Search fontSize="small" /></Box>,
          }}
        />
      } */}
      {isMunicipalityUser && <HealthClientSelect handleHealthClientChange={handleHealthClientChange} />}
      <Box className={classes.dateRangeContainer} display="flex" alignItems="center" mr={1}>
        <Box>
          <NepaliCalender
            id="dartaa-from-date"
            onChange={handleRegisterDateFromSelect}
            label={props.dateFromLabel}
            defaultDate={props.datePickerFromDate}
            defaultFormat={props.fromDateDefaultFormat}
          />
        </Box>
        <Typography variant="subtitle2">देखी</Typography>
        <Box>
          <NepaliCalender
            id="dartaa-to-date"
            onChange={handleRegisterDateToSelect}
            label={props.dateToLabel}
            defaultDate={props.datePickerToDate}
            defaultFormat={props.toDateDefaultFormat}
          />
        </Box>
        <Typography variant="subtitle2">सम्म</Typography>
      </Box>
      {!isMunicipalityUser && !props.hideButton &&
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleModalOpen}>नयाँ रेकर्ड थप्नुहोस्</Button>
          {props.showRegisterHideSection && <IconButton>
            <MoreVert fontSize="small" onClick={(e) => props.getRegisterHideSectionValue(e.target)} />
          </IconButton>}
        </Box>
      }
    </Box>
  )
}

RegisterHeader.propTypes = {
  registerTitle: PropTypes.string,
  getTableData: PropTypes.bool,
  dateFromLabel: PropTypes.string,
  dateToLabel: PropTypes.string,
  fromDateDefaultFormat: PropTypes.bool,
  toDateDefaultFormat: PropTypes.bool,
  datePickerFromDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  datePickerToDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  showRegisterHideSection: PropTypes.bool
}

RegisterHeader.defaultProps = {
  getTableData: false,
  registerTitle: "रजिस्टर शीर्षक",
  dateFromLabel: "दर्ता मिति",
  dateToLabel: "दर्ता मिति",
  fromDateDefaultFormat: true,
  toDateDefaultFormat: true,
  datePickerFromDate: DateUtils.getDaysBeforeBSDate(30),
  datePickerToDate: DateUtils.getDateFromMilliseconds(Date.now()),
  showRegisterHideSection: false
}