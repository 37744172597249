import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

export default function CustomReactSelect({ onChange, ...props }) {
  const [selectedOption, setSelectedOption] = useState(props.isMulti ? [] : null);
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {
          React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
          )
        }
      </ValueContainer>
    );
  };

  useEffect(() => {
    let executeHandleChangeForDefaultValue = props.isMulti ? !!(props.defaultValue?.length) : props.defaultValue;
    executeHandleChangeForDefaultValue ? handleSelectChange(findSelectedOptionObject(props.defaultValue)) : setSelectedOption(null);
  }, [`${props.isMulti ? Array.from(props.defaultValue || []) : props.defaultValue}`]);

  useEffect(() => {
    props.clearSelect && handleSelectChange(null);
  }, [props.clearSelect]);

  const findSelectedOptionObject = (value) => {
    if (props.isMulti) {
      if (!props.hasMultiLevelObject) {
        return (
          value.map(item => (props.options.find(obj => (obj.value == item))) || ({ value: item, label: item }))
        )
      }
      let valueContainingArray = [];
      value.map(item => props.options.map(object => object.options.find(obj => (obj.value === item) && valueContainingArray.push(obj))));
      return valueContainingArray;
    }
    if (!props.hasMultiLevelObject) {
      // TODO - Prastav : Find alternative solution for "=="
      return props.options?.find(obj => obj.value == value) || ({ value: value, label: value });
    }
    for (let i in props.options) {
      for (let j in props.options[i].options) {
        if (props.options[i].options[j].value === value) {
          return props.options[i].options[j];
        }
      }
    }
  }

  const handleSelectChange = (options) => {
    let value = props.isMulti ? (Boolean(options?.length) ? options.map(item => item.value) : null) : options?.value;
    setSelectedOption(props.isMulti ? (options || []) : options);
    onChange(props.name, value, options);
  }

  const styles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    valueContainer: (base, state) => ({
      ...base,
      overflow: "visible"
    }),
    placeholder: (base, state) => ({
      ...base,
      position: "absolute",
      transition: "top 0.2s, font-size 0.1s",
      top: state.hasValue || state.selectProps.inputValue ? -13 : "13%",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 12
    })
  }

  return (
    !props.creatable ? (
      <Select
        placeholder={props.required ? props.label + " *" : props.label}
        name={props.name}
        options={props.options}
        className={props.className}
        classNamePrefix={props.classNamePrefix}
        value={selectedOption}
        menuPlacement="auto"
        onChange={handleSelectChange}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        isClearable={props.isClearable}
        styles={styles}
        menuPortalTarget={document.body}
        {...props}
      />
    ) : (
      <CreatableSelect
        placeholder={props.required ? props.label + " *" : props.label}
        name={props.name}
        options={props.options}
        className={props.className}
        classNamePrefix={props.classNamePrefix}
        value={selectedOption}
        onChange={handleSelectChange}
        menuPlacement="auto"
        components={{
          ValueContainer: CustomValueContainer,
        }}
        isClearable={props.isClearable}
        styles={styles}
        menuPortalTarget={document.body}
        {...props}
      />
    )
  );
}

CustomReactSelect.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isClearable: PropTypes.bool,
};

CustomReactSelect.defaultProps = {
  className: "select-sm",
  classNamePrefix: "react-select",
  isClearable: true,
}