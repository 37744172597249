import { Box, Card } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartDataByEmployeeeCategoriesByAgeRange = () => {
  const chartData = [
    { age: "0-10", employee: 1000 },
    { age: "11-19", employee: 800 },
    { age: "20-19", employee: 1100 },
    { age: "21-29", employee: 1500 },
    { age: "30-39", employee: 1000 },
    { age: "40-49", employee: 1400 },
    { age: "50-59", employee: 1400 },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 430
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "center",
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      type: "category",
      categories: chartData.map(item => item.age),
      title: {
        text: "उमेर"
      }
    },
    yaxis: {
      title: {
        text: " जम्मा सख्या"
      }
    },
    title: {
      text: "पद अनुसार कर्मचारी   विवरण",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    legend: {
      position: "top",
    },
  };

  const series = [
    { name: "कर्मचारी", data: chartData.map(item => item.employee) },
  ];

  return (
    <>
      <Box mt={2}>
        <Card>
          <ReactApexChart options={options} series={series} type="bar" height={430} />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataByEmployeeeCategoriesByAgeRange;
