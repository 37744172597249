import React, { useState } from "react"
import PardanKarmaReport from "./helper/PardanKarmaReport";
import UpakarmaReport from "./helper/UpakarmaReport";
import PurbaPanchaKarmaReport from "./helper/PurbaPanchaKarmaReport";
import MessageBlock from "../../admin/components/message-block/MessageBlock";
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import ReportHeader from "../component/report-header/ReportHeader";
import AddAlertMessage from "../../../components/alert/Alert";
import AHMIS, { API_URL } from "../../../api/api";
import { DateUtils } from "../../../utils/dateUtils";

const PanchaKarmaPurbaKarmaSewaReport = () => {
  const [panchakarmadata, setPanchakarmadata] = useState(null)
  const [purbakarmadata, setPurbakarmadata] = useState(null)
  const [upakarnadata, setUpakarnadata] = useState(null)
  const [pradhanKarmaSewaSankhyaData, setpradhanKarmaSewaSankhyaData] = useState(null)

  const getReportData = (fromDate, toDate, healthClientId) => {
    const url = `${API_URL.pradhanKarmaReport}?fromDate=${fromDate}&toDate=${toDate}&healthClientId=${healthClientId}&fiscalYear=${DateUtils.getFiscalYearFromDate(DateUtils.getDateFromMilliseconds(toDate))}`;
    AHMIS.get(url)
      .then((response) => {
        const jsondata = response?.data;
        if (jsondata.type === SUCCESS) {
          const { pradhanKarmaReport, upakarmaPratibedanReport, purbaPanchaKarma, pradhanKarmaSewaSankhya } = jsondata.data;
          setPanchakarmadata(pradhanKarmaReport);
          setPurbakarmadata(upakarmaPratibedanReport);
          setUpakarnadata(purbaPanchaKarma);
          setpradhanKarmaSewaSankhyaData(pradhanKarmaSewaSankhya)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
      });
  };

  return (
    <>
      <ReportHeader
        title="पञ्चकर्म रिपोर्ट"
        getReportData={getReportData}
      />
      <>
        {panchakarmadata || purbakarmadata || upakarnadata || pradhanKarmaSewaSankhyaData ? (
          <>
            {panchakarmadata && <PardanKarmaReport tableData={panchakarmadata} sewaData={pradhanKarmaSewaSankhyaData} />}
            {purbakarmadata && <UpakarmaReport tableData={purbakarmadata} />}
            {upakarnadata && <PurbaPanchaKarmaReport tableData={upakarnadata} />}
          </>
        ) : (
          <MessageBlock text={REPORT_NOT_DRAWN} />
        )}
      </>
    </>
  )
}

export default PanchaKarmaPurbaKarmaSewaReport;