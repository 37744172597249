import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppUtils } from "../../../../utils/appUtils";
import { PROVINCE_DISTRICT_PALIKA_LIST, ROLE_ELIGIBLE_FOR_SUBMITTING_DHIS2_REPORT, ROLE_USER_CENTRAL, ROLE_USER_DISTRICT, ROLE_USER_MUNICIPALITY, ROLE_USER_PROVINCE } from "../../../../utils/constants";
import { DateUtils } from "../../../../utils/dateUtils";
import { findLastDayOfMonthOfBsDate } from "ad-bs-date-conversion";
import { Box, Button, Typography } from "@mui/material";
import CustomReactSelect from "../../../../components/custom-react-select/CustomReactSelect";
import HealthClientSelect from "../../../../components/health-client-select/HealthClientSelect";
import NepaliCalender from "../../../../components/nepali-calender";
import PropTypes from "prop-types";
import styles from "./styles";
import { API_URL } from "../../../../api/api";
import { getDataFromServer } from "../../../../utils/apiUtils";
import { Print, TableChart } from "@mui/icons-material";
import ReactToPrint from "react-to-print";

export default function ReportHeader({ getReportData, ...props }) {
  const classes = styles();
  const [healthClientId, setHealthClientId] = useState();
  const [districtOptions, setDistrictOptions] = useState([]);
  const userInfo = useSelector(state => state.userInfo);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [healthClientOptions, setHealthClientOptions] = useState([]);
  const [clearHealthClientSelect, setClearHealthClientSelect] = useState(false);
  const [clearPalikaSelect, setClearPalikaSelect] = useState(false);
  const [clearDistrictSelect, setClearDistrictSelect] = useState(false);
  const [reportDate, setReportDate] = useState({
    dateFrom: null,
    dateTo: null
  });

  const role = AppUtils.getUserRole();

  const handleFromDateSelect = (...params) => {
    setReportDate(prev => ({
      ...prev,
      dateFrom: params[1]
    }));
  }

  const handleToDateSelect = (...params) => {
    setReportDate(prev => ({
      ...prev,
      dateTo: params[1]
    }));
  }

  const handleHealthClientChange = id => {
    setHealthClientId(id);
  }

  useEffect(() => {
    (userInfo?.role === ROLE_USER_PROVINCE) && getDistrictOptions(userInfo?.province)
  }, [userInfo?.province])

  const checkDateDifferenceForOneMonth = (fromDate, toDate) => {
    let currentDate = DateUtils.getDateFromMilliseconds(Date.now());
    let currentMonth = DateUtils.getSeparatedDateFromBsDate(currentDate).month;
    let dateObj = DateUtils.getSeparatedDateFromMilliseconds(fromDate);

    if (currentMonth === dateObj.month) {
      return true
    }

    let dayOfMonth = findLastDayOfMonthOfBsDate(dateObj.year, dateObj.month);
    return dayOfMonth !== ((toDate - fromDate) / 86400000) + 1
  }

  const modifyParamForDhisTwo = (fromDate, toDate) => {
    let dateObj = DateUtils.getSeparatedDateFromMilliseconds(fromDate);
    let month = `${dateObj.year}${dateObj.month}`
    props.submitToDhisTwo(fromDate, toDate, month)
  }

  function getDistrictOptions(province) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      if (PROVINCE_DISTRICT_PALIKA_LIST[i].value === province) {
        for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
          setDistrictOptions(PROVINCE_DISTRICT_PALIKA_LIST[i].districts);
        }
      }
    }
  }

  const handleProvinceChange = (name, value, options) => {
    if (value) {
      setClearDistrictSelect(false);
      let districts = [];
      options?.forEach((province) => {
        province.districts.forEach((district) => {
          districts.push(district);
        });
      });
      setDistrictOptions(districts);
    }
    else {
      setClearDistrictSelect(true);
      setDistrictOptions([])
    }
  }

  const handleDistrictChange = (name, value, options) => {
    if (value) {
      setClearPalikaSelect(false);
      let palikas = [];
      options?.forEach((district) => {
        district.palikas.forEach((palikasList) => {
          palikas.push(palikasList);
        });
      });
      setPalikaOptions(palikas);
    }
    else {
      setClearPalikaSelect(true);
      setPalikaOptions([]);
    }
  }

  const handlePalikaChange = (name, value, options) => {
    if (value) {
      setClearHealthClientSelect(false)
      getHealthPostOptions(value)
    }
    else {
      setClearHealthClientSelect(true)
      setHealthClientOptions([])
    }
  }

  useEffect(() => {
    userInfo?.role === ROLE_USER_DISTRICT && getPalikaOptions(userInfo?.district)
  }, [userInfo?.role])

  const getPalikaOptions = (value) => {
    PROVINCE_DISTRICT_PALIKA_LIST?.forEach((province) => {
      province.districts?.forEach((districtName) => {
        if (districtName.value === value) {
          setPalikaOptions(districtName.palikas)
        }
      });
    });
  }

  const getHealthPostOptions = async (palika) => {
    let result = [];
    let url = API_URL.provinceDashboard + "/health-client-list?palikaNameList=" + palika;
    let response = await getDataFromServer(url);
    for (let i = 0; i < response.length; i++) {
      result.push({ label: response[i].name, value: response[i].id });
    }
    setHealthClientOptions(result);
  };

  useEffect(() => {
    reportDate && props.getDateForReportPrint && props.getDateForReportPrint(reportDate);

  }, [reportDate])

  return (
    <Box className={classes.reportHeaderContainer} borderBottom={1} mb={3} pb={1}>
      {role === ROLE_USER_CENTRAL &&
        <Box className={classes.selectContainer} mr={1} mb={1}>
          <CustomReactSelect
            label="प्रदेश"
            options={PROVINCE_DISTRICT_PALIKA_LIST}
            onChange={handleProvinceChange}
            isMulti
          />
        </Box>
      }
      {(role === ROLE_USER_CENTRAL || role === ROLE_USER_PROVINCE) &&
        <Box className={classes.selectContainer} mr={1} mb={1}>
          <CustomReactSelect
            label="जिल्ला"
            options={districtOptions}
            onChange={handleDistrictChange}
            isMulti
            isDisabled={!districtOptions.length}
            clearSelect={clearDistrictSelect}
          />
        </Box>
      }
      {(role === ROLE_USER_CENTRAL || role === ROLE_USER_PROVINCE || role === ROLE_USER_DISTRICT) &&
        < Box className={classes.selectContainer} mr={1} mb={1}>
          <CustomReactSelect
            label="पालिका"
            options={palikaOptions}
            onChange={handlePalikaChange}
            isDisabled={!palikaOptions.length}
            isMulti
            clearSelect={clearPalikaSelect}
          />
        </Box>}
      {(role === ROLE_USER_CENTRAL || role === ROLE_USER_PROVINCE || role === ROLE_USER_DISTRICT) ?
        (
          <Box className={classes.selectContainer} mr={1} mb={2}>
            <CustomReactSelect
              label="स्वास्थ्य संस्था"
              options={healthClientOptions}
              onChange={(...params) => handleHealthClientChange(params[1])}
              isDisabled={!healthClientOptions.length}
              isMulti={props.isMulti}
              clearSelect={clearHealthClientSelect}
            />
          </Box>
        ) : (AppUtils.getUserRole() === ROLE_USER_MUNICIPALITY ? (
          <HealthClientSelect isMulti={props.isMulti} handleHealthClientChange={handleHealthClientChange} />
        ) : (<Typography variant="h5">{props.title}</Typography>))
      }
      <Box className={classes.reportHeaderWrapper}>
        <Box className={`${classes.dateRangeContainer} report-date-range-container`} mr={1} mt={2}>
          <Box>
            <NepaliCalender
              id="report-header-from-date"
              onChange={handleFromDateSelect}
              defaultDate={props.defaultFromDate}
              label={props.dateFromLabel}
              defaultFormat={props.fromDateDefaultFormat}
            />
          </Box>
          <Typography variant="subtitle2">देखी</Typography>
          <Box>
            <NepaliCalender
              id="report-header-to-date"
              onChange={handleToDateSelect}
              label={props.dateToLabel}
              defaultDate={props.defaultToDate}
              defaultFormat={props.toDateDefaultFormat}
            />
          </Box>
          <Typography variant="subtitle2">सम्म</Typography>
        </Box>
        <Box>
          <Button variant="contained" color="primary" startIcon={<TableChart />} onClick={() => getReportData(reportDate.dateFrom, reportDate.dateTo, healthClientId)} disabled={(role === ROLE_USER_CENTRAL || role === ROLE_USER_PROVINCE || role === ROLE_USER_DISTRICT || role === ROLE_USER_MUNICIPALITY) && !healthClientId}>रिपोर्ट हेर्नुहोस्</Button>
        </Box>
        {props.showDhisTwoSubmitButton && ROLE_ELIGIBLE_FOR_SUBMITTING_DHIS2_REPORT.includes(role) &&
          <Box>
            <Button className="report-print-btn" variant="contained" color="primary" startIcon={<TableChart />} onClick={() => modifyParamForDhisTwo(reportDate.dateFrom, reportDate.dateTo)} disabled={checkDateDifferenceForOneMonth(reportDate.dateFrom, reportDate.dateTo)}>Submit to DHIS-2</Button>
          </Box>
        }
        {
          props.reportData &&
          <ReactToPrint
            trigger={() => <Button className="report-print-btn" variant="contained" color="primary" startIcon={<Print />}>रिपोर्ट प्रिन्ट गर्नुहोस्</Button>}
            content={() => props.printComponentRef.current}
          />
        }
      </Box>
    </Box>
  );
}

ReportHeader.propTypes = {
  dateFromLabel: PropTypes.string,
  dateToLabel: PropTypes.string,
  fromDateDefaultFormat: PropTypes.bool,
  toDateDefaultFormat: PropTypes.bool,
  defaultFromDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  defaultToDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  title: PropTypes.string,
  // enableForOneMonth: PropTypes.bool,
  showDhisTwoSubmitButton: PropTypes.bool
}

ReportHeader.defaultProps = {
  title: "रिपोर्ट शीर्षक",
  defaultFromDate: DateUtils.getCurrentMonthFirstAndLastDate().firstDay,
  defaultToDate: DateUtils.getCurrentMonthFirstAndLastDate().lastDay,
  dateFromLabel: "मिति देखी",
  dateToLabel: "मिति सम्म",
  fromDateDefaultFormat: true,
  toDateDefaultFormat: true,
  // enableForOneMonth: false,
  showDhisTwoSubmitButton: false
}