import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DASHBOARD } from '../../utils/constants';
import CustomReactSelect from '../custom-react-select/CustomReactSelect';
import ChartDataByAge from './AHMIS/ChartDataByAge';
import ChartDataByDailyPatient from './AHMIS/ChartDataByDailyPatient';
import ChartDataByMonth from './AHMIS/ChartDataByMonth';
import ChartDataBySewaByMonth from './AHMIS/ChartDataBySewaByMonth';
import ChartDataBySewaType from './AHMIS/ChartDataBySewaType';
import ChartDataForTotalDisease from './AHMIS/ChartDataForTotalDisease';
import ChartDataByTotalStaff from './HR/ChartDataByTotalStaff';
import ChartDataByTotalStaffProvience from './HR/ChartDataByTotalStaffProvience';
import MedicineStockDetails from './Logistics/MedicineStockDetails';
import TopMedicineName from './Logistics/TopMedicineName';
import styles from "./style";
import ChartDataByNewOldPatient from './AHMIS/ChartDataByNewOldPaient';
import EmployeeDetails from './HR/EmployeeDetails';
import ChartDataByEmployeeType from './HR/ChartDataByEmployeeType';
import ChartDataByEmployeeCount from './HR/ChartDataByEmployeeCount';
import ChartDataByEmployeeeCategoriesByAgeRange from './HR/ChartDataByEmployeeeCategoriesByAgeRange';
import BittayaBibaranForm from "../../pages/forms/bittaya-bibaran/BittayaBibaranForm";
const Dashboard = () => {
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const handleDashBoardChange = (name, value) => {
    setSelectedDashboard(value);
  }
  const classes = styles();

  return (
    <>
      <Box className={classes.registerHeaderContainer} borderBottom={1}>
        <Typography variant="h6" className={classes.registerTitle}>गृहपृष्ठ</Typography>
        <CustomReactSelect
          label="AAMIS"
          name="dashboard"
          size="small"
          fullWidth
          options={DASHBOARD}
          onChange={handleDashBoardChange}
          defaultValue={"PROGRAM"}
          isClearable={false}
        />
      </Box>
      <Grid container spacing={2}>
        {
          selectedDashboard && selectedDashboard === "PROGRAM" && (
            <>
              <Grid item xs={12}>
                <ChartDataForTotalDisease />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByDailyPatient />
              </Grid>
              <Grid item xs={12}>
                <ChartDataBySewaByMonth />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByMonth />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByAge />
              </Grid>
              <Grid item xs={12}>
                <ChartDataBySewaType />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByNewOldPatient />
              </Grid>
            </>
          )
        }
        {
          selectedDashboard && selectedDashboard === "HR" && (
            <>
              <Grid item xs={12}>
                <EmployeeDetails />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByTotalStaffProvience />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByTotalStaff />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByEmployeeType />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByEmployeeCount />
              </Grid>
              <Grid item xs={12}>
                <ChartDataByEmployeeeCategoriesByAgeRange />
              </Grid>
            </>
          )
        }
        {
          selectedDashboard && selectedDashboard === "LOGISTIC" && (
            <>
              <Grid item xs={12}>
                <MedicineStockDetails />
              </Grid>
              <Grid item xs={12}>
                <TopMedicineName />
              </Grid>
            </>
          )
        }
        {
          selectedDashboard && selectedDashboard === "FINANCE" && (
            <Grid item xs={12}>
              <BittayaBibaranForm />
            </Grid>
          )
        }
      </Grid>
    </>
  )
}

export default Dashboard;