import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import AHMIS, { API_URL } from "../../../../api/api";
import AddUserModal from "../../../../components/add-user/AddUserModal";
import AddAlertMessage from "../../../../components/alert/Alert";
import { ERROR, HEALTH_ORGANIZATION_ROLES, HOSPITAL_ORGANIZATION_ROLE, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants";
import MunicipalityUserList from "../user-list/UserList";
import { AppUtils } from "../../../../utils/appUtils";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

export default function HealthClientHome(props) {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [userTableData, setUserTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const clientType = AppUtils.getUrlParam("clientType");

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
    setModalDefaultValues({});
  }

  const handleAddUserModalSubmit = data => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      data.id = modalDefaultValues.id;
      data.palikaId = palikaId;
      data.userEmail = data?.userEmail?.toLowerCase().trim();
      AHMIS.post(API_URL.user, data).then(response => {
        if (response.data.type === SUCCESS) {
          setUserTableData(response.data?.objectList);
          handleAddUserModalClose();
          getHealthClientUserData();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      });
    }
  }

  const handleRowEdit = id => {
    AHMIS.get(API_URL.user + "/" + id).then(response => {
      setModalDefaultValues(response.data.data);
      setOpenAddUserModal(true);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  useEffect(() => {
    getHealthClientUserData();
  }, []);

  const getHealthClientUserData = () => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      AHMIS.get(API_URL.municipalityUsers + "/" + palikaId).then(response => {
        setUserTableData(response.data?.objectList);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
    }
  };

  return (
    <>
      <Box borderBottom={1}>
        <BreadCrumbs />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1} mt={2}>
        <Typography variant="h5">
          स्वास्थ्य संस्था प्रयोगकर्ताहरू
        </Typography>
        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddUserModal(true) }}>नयाँ प्रयोगकर्ता थप्नुहोस्</Button>
        </Box>
      </Box>
      <Box>
        <MunicipalityUserList tableData={userTableData} showEditColumn editRow={handleRowEdit} />
      </Box>
      <AddUserModal userRoles={clientType === "HOSPITAL" ? HOSPITAL_ORGANIZATION_ROLE : HEALTH_ORGANIZATION_ROLES} showAddUserModal={openAddUserModal} handleAddUserModalClose={handleAddUserModalClose} onSubmit={handleAddUserModalSubmit.bind(this)} defaultValues={modalDefaultValues} />
    </>
  )
}