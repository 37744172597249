import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AHMIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import CustomModal from '../../../components/modal/CustomModal';
import RegisterHeader from '../../../components/register-header/RegisterHeader';
import { AppMisc } from '../../../misc/appMisc';
import { AYURVEDIC_THERAPY, ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import { DISEASE_CODE, MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import OutPatientRegister from '../../users/components/register/out-patient-register/OutPatientRegister';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { DateUtils } from '../../../utils/dateUtils';
import PendingPatientList from '../../../components/pending-patient-list/PendingPatientList';

const OutPatientForm = () => {
  const [modalTitle, setModalTitle] = useState("बहिरङ्ग सेवा रजिष्टरमा नयाँ रेकर्ड थप्नुहोस्।");
  const [showOutPatientModal, setShowOutPatientModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState();
  const [OpdDataTable, setOpdDataTable] = useState([]);
  const [showOpdDeleteModal, setShowOpdDeleteModal] = useState(false);
  const [opdDeleteId, setOpdDeleteId] = useState(null);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [pendingPatientList, setPendingPatientList] = useState([])

  useEffect(() => {
    register("district", { required: true });
    register("palikaName", { required: true });
    register("gender", { required: true });
    register("ageUnit", { required: true });
    register("patientType", { required: true });
    register("ayurvedicTherapy", { required: true });
    register("ageUnit", { required: true });
    register("casteCode", { required: true });
    register("dartaaMiti", { required: true });
    register("dateOfBirth", { required: true });
  }, [register]);

  useEffect(() => {
    showOutPatientModal && reset();
  }, [reset, showOutPatientModal])

  const openOutPatientModal = () => {
    setShowOutPatientModal(true);
  }

  const closeOutPatientModal = () => {
    reset();
    setShowOutPatientModal(false);
    setModalDefaultValues()
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    // setValue(name, dateInMilli);  
  }

  const submitOpdForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.outPatient, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeOutPatientModal()
          setGetTableData(true)
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const getOutPatientData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.outPatient}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setOpdDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  useEffect(() => {
    opdPendingPatient()
  }, [])

  const opdPendingPatient = () => {
    let url = `${API_URL.getOpdPendingPatient}`;
    AHMIS.get(url).then((response) => {
      let jsondata = response.data;
      setPendingPatientList(jsondata.data)
    })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const opdEdit = (id) => {
    AHMIS.get(API_URL.outPatient + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data);
        setShowOutPatientModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeMulDartaaDeleteModal = () => {
    setShowOpdDeleteModal(false);
    setOpdDeleteId(null);
  }

  const onDeleteOpdRow = (id) => {
    setShowOpdDeleteModal(true);
    setOpdDeleteId(id);
  }

  const deleteOutPatientRow = (data) => {
    AHMIS.delete(API_URL.outPatient + "/" + opdDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowOpdDeleteModal(false);
          setOpdDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getOutPatientData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="बहिरङ्ग सेवा रजिष्टर"
          handleModalOpen={openOutPatientModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getOutPatientData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showOutPatientModal}
        onModalSubmit={handleSubmit(submitOpdForm)}
        onModalClose={closeOutPatientModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="अनुसन्धान मूलक परीक्षण"
              size="small"
              variant="outlined"
              name="exploratoryTesting"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.exploratoryTesting}
              {...register("exploratoryTesting", { required: true })}
            />
            {errors?.exploratoryTesting && errors.exploratoryTesting?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="सम्भावित निदान(Provisional Diagnosis)"
              size="small"
              variant="outlined"
              name="provisionalDiagnosis"
              autoComplete="true"
              defaultValue={modalDefaultValues?.provisionalDiagnosis}
              fullWidth
              {...register("provisionalDiagnosis", { required: true })}
            />
            {errors?.provisionalDiagnosis && errors.provisionalDiagnosis?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <CustomReactSelect
              label="Disease Code"
              name="diseaseCode"
              onChange={handleCustomReactSelectChange}
              options={DISEASE_CODE}
              defaultValue={modalDefaultValues?.diseaseCode}
              isMulti
              creatable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="उपचार र सल्लाह"
              size="small"
              variant="outlined"
              name="tratementAndAdvice"
              autoComplete="true"
              defaultValue={modalDefaultValues?.tratementAndAdvice}
              fullWidth
              {...register("tratementAndAdvice", { required: true })}
            />
            {errors?.tratementAndAdvice && errors.tratementAndAdvice?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <CustomReactSelect
              label="Ayurvedic Therapy"
              name="ayurvedicTherapy"
              options={AYURVEDIC_THERAPY}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.ayurvedicTherapy}
              isMulti
              creatable
            />
            {errors?.ayurvedicTherapy && errors.ayurvedicTherapy?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="लैङ्गिक हिंसा"
              size="small"
              variant="outlined"
              name="genderViolence"
              autoComplete="true"
              defaultValue={modalDefaultValues?.genderViolence}
              fullWidth
              {...register("genderViolence", { required: true })}
            />
            {errors?.genderViolence && errors.genderViolence?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="institutionName"
              autoComplete="true"
              defaultValue={modalDefaultValues?.institutionName}
              fullWidth
              {...register("institutionName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="institutionAttended"
              autoComplete="true"
              defaultValue={modalDefaultValues?.institutionAttended}
              fullWidth
              {...register("institutionAttended")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <PendingPatientList
        pendingPatientList={pendingPatientList}
        showModal={setShowOutPatientModal}
        modalDefaultValues={setModalDefaultValues}
      />
      <DeleteRegisterRecordConformation
        showDeleteModal={showOpdDeleteModal}
        closeDeleteModel={closeMulDartaaDeleteModal}
        deleteRecord={deleteOutPatientRow}
      />
      <Box mt={2}>
        <OutPatientRegister
          onEditRow={opdEdit}
          tableData={OpdDataTable}
          deleteRow={onDeleteOpdRow}
          handleChangePage={handleChangePage}
          page={page}
          totalSize={totalSize}
          getTableData={(value) => setGetTableData(value)}
        />
      </Box>
    </>
  )
}

export default OutPatientForm;