import { Box, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import CustomModal from '../../../components/modal/CustomModal'
import StanpaiAamaTathaShishuSewaRegister from '../../users/components/register/stanpai-aama-tatha-shishu-sewa-register/StanpaiAamaTathaShishuSewaRegister'
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect'
import NepaliCalender from '../../../components/nepali-calender'
import { useForm } from 'react-hook-form'
import { CASTE_CODES, ERROR, GENDER_OPTIONS, PATIENT_TYPES, ROW_PER_PAGE, SEWA_TYPES, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants'
import { AGE_UNITS } from '../../../utils/constants/forms'
import { AppMisc } from '../../../misc/appMisc'
import AHMIS, { API_URL } from '../../../api/api'
import AddAlertMessage from '../../../components/alert/Alert'
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation'

const StanpaiAamatathaShishuSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("स्तनपायी आमा तथा शिशु रजिष्टर")
  const [showStanpaiAamatathaShishuSewaModal, setStanpaiAamatathaShishuSewaModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [showStanpaiAamatathaShishuDeleteModal, setStanpaiAamatathaShishuDeleteModal] = useState(false);
  const [stanpaiAamatathaShishuTableData, setStanpaiAamatathaShishuTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [stanpaiAamatathaShishuDeleteId, setStanpaiAamatathaShishuDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, } = useForm();

  const openStanpaiAamatathaShishuSewaModal = () => {
    setStanpaiAamatathaShishuSewaModal(true)
  }

  const closeStanpaiAamatathaShishuSewaModal = () => {
    setStanpaiAamatathaShishuSewaModal(false)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  useEffect(() => {
    register("district");
    register("palikaName");
    register("gender");
    register("casteCode");
    register("ageUnit");
    register("settlement");
    register("patientType");
    register("dateOfBirth");
    register("dartaaMiti");
    register("fristMonthHealthSitutation")
    register("secondMonthHealthSitutation")
    register("thirdMonthHealthSitutation")
    register("fourthMonthHealthSitutation")
    register("fifthMonthHealthSitutation")
    register("sixthMonthHealthSitutation")
    register("dateOfDelivery")
  }, [register]);

  const submitStanpaiAamatathaShishuSewa = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.stanpaiAama, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeStanpaiAamatathaShishuSewaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getStanpaiAamatathaShishuSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.stanpaiAama}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setStanpaiAamatathaShishuTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const stanpaiAamatathaShishuSewaEdit = (id) => {
    AHMIS.get(API_URL.stanpaiAama + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setStanpaiAamatathaShishuSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeStanpaiAamatathaShishuDeleteModal = () => {
    setStanpaiAamatathaShishuDeleteModal(false);
    setStanpaiAamatathaShishuDeleteId(null);
  }

  const onDeleteStanpaiAamatathaShishuRow = (id) => {
    setStanpaiAamatathaShishuDeleteModal(true);
    setStanpaiAamatathaShishuDeleteId(id);
  }

  const deleteStanpaiAamatathaShishuRow = (data) => {
    AHMIS.delete(API_URL.stanpaiAama + "/" + stanpaiAamatathaShishuDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setStanpaiAamatathaShishuDeleteModal(false);
          setStanpaiAamatathaShishuDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getStanpaiAamatathaShishuSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="स्तनपायी आमा तथा शिशु रजिष्टर"
          handleModalOpen={openStanpaiAamatathaShishuSewaModal}
          getRegisterData={getStanpaiAamatathaShishuSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showStanpaiAamatathaShishuSewaModal}
        onModalSubmit={handleSubmit(submitStanpaiAamatathaShishuSewa)}
        onModalClose={closeStanpaiAamatathaShishuSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                showDefaultDate
                label="दर्ता मिति"
                onChange={handleDateChange}
                defaultDate={modalDefaultValues?.dartaaMiti}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवाग्राहीको किसिम"
              name="patientType"
              options={PATIENT_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.patientType}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="dartaaNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.dartaaNumber}
              {...register("dartaaNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="patientFirstName"
              autoComplete="on"
              fullWidth
              defaultValue={modalDefaultValues?.patientFirstName}
              {...register("patientFirstName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको थर"
              type="text"
              size="small"
              variant="outlined"
              name="patientLastName"
              autoComplete="on"
              fullWidth
              {...register("patientLastName")}
              defaultValue={modalDefaultValues?.patientLastName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              options={GENDER_OPTIONS}
              defaultValue={modalDefaultValues?.gender}
              onChange={handleCustomReactSelectChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="date-of-birth-of-child"
                label="जन्म मिति"
                name="dateOfBirth"
                defaultDate={modalDefaultValues?.dateOfBirth}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultDate={modalDefaultValues?.age}
              {...register("age")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.ageUnit}
              options={AGE_UNITS}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} id="casteScrollContainer" style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="जाती"
              size="small"
              name="casteCode"
              options={CASTE_CODES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.casteCode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="जिल्ला"
              name="district"
              variant="outlined"
              onChange={handleDistrictChange}
              options={districtOptions}
              defaultValue={modalDefaultValues?.district}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="नगर/गाउँपालिका"
              name="palikaName"
              variant="outlined"
              options={palikaOptions}
              onChange={handlePalikaNameChange}
              defaultValue={modalDefaultValues?.palikaName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="वडा नं"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.wardNumber}
              {...register("wardNumber", { required: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="gaunOrTole"
              label="गाँउ/टोल"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              defaultValue={modalDefaultValues?.gaunOrTole}
              {...register("gaunOrTole", { required: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सम्पर्क नम्बर"
              size="small"
              variant="outlined"
              fullWidth
              name="phoneNumber"
              autoComplete="true"
              defaultValue={modalDefaultValues?.phoneNumber}
              {...register("phoneNumber", { required: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} id="scrollContainer" style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="सेवाको किसिम"
              name="serviceType"
              variant="outlined"
              options={SEWA_TYPES}
              defaultValue={modalDefaultValues?.serviceType}
              onChange={handleCustomReactSelectChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="नि:शुल्क/शुल्क रु"
              type="text"
              size="small"
              variant="outlined"
              name="cost"
              autoComplete="true"
              fullWidth
              {...register("cost")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रेषण भई आएको संस्थाको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="sentFromOrganizationName"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.sentFromOrganizationName}
              {...register("sentFromOrganizationName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              name="dateOfDelivery"
              showDefaultDate
              label="Date of Delivery"
              onChange={handleDateChange}
              defaultDate={modalDefaultValues?.dateOfDelivery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Place of Delivery"
              type="text"
              size="small"
              variant="outlined"
              name="placeOfDelivery"
              autoComplete="true"
              fullWidth
              {...register("placeOfDelivery")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Types of Delivery"
              type="text"
              size="small"
              variant="outlined"
              name="typesOfDelivery"
              autoComplete="true"
              fullWidth
              {...register("typesOfDelivery")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रक्तचाप"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="BS"
              size="small"
              variant="outlined"
              name="bs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("bs")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="nature"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.disease}
              {...register("nature")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रोग /निदान"
              size="small"
              variant="outlined"
              name="diagnosis"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.diagnosis}
              {...register("diagnosis")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण तथा सल्लाह"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.test}
              {...register("test")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="First Month Child weight"
              type="text"
              size="small"
              variant="outlined"
              name="firstMonthChildWeight"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.firstMonthChildWeight}
              {...register("firstMonthChildWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="First Month Mother Situtation"
              type="text"
              size="small"
              variant="outlined"
              name="firstMonthMotherSitutation"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.firstMonthMotherSitutation}
              {...register("firstMonthMotherSitutation")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              label="मिति"
              name="firstMonthHealthSitutation"
              defaultDate={modalDefaultValues?.firstMonthHealthSitutation}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Second Month Child weight"
              type="text"
              size="small"
              variant="outlined"
              name="secondMonthChildWeight"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.secondMonthChildWeight}
              {...register("secondMonthChildWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Second Month Mother Situtation"
              type="text"
              size="small"
              variant="outlined"
              name="secondMonthMotherSitutation"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.secondMonthMotherSitutation}
              {...register("secondMonthMotherSitutation")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              label="मिति"
              name="secondMonthHealthSitutation"
              defaultDate={modalDefaultValues?.secondMonthHealthSitutation}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Third Month Child weight"
              type="text"
              size="small"
              variant="outlined"
              name="thirdMonthChildWeight"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.thirdMonthChildWeight}
              {...register("thirdMonthChildWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Third Month Mother Situtation"
              type="text"
              size="small"
              variant="outlined"
              name="thirdMonthMotherSitutation"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.thirdMonthMotherSitutation}
              {...register("thirdMonthMotherSitutation")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              label="मिति"
              name="thirdMonthHealthSitutation"
              defaultDate={modalDefaultValues?.thirdMonthHealthSitutation}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Fourth Month Child weight"
              type="text"
              size="small"
              variant="outlined"
              name="fourthMonthChildWeight"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.fourthMonthChildWeight}
              {...register("fourthMonthChildWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="fourth Month Mother Situtation"
              type="text"
              size="small"
              variant="outlined"
              name="fourthMonthMotherSitutation"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.fourthMonthMotherSitutation}
              {...register("fourthMonthMotherSitutation")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              label="मिति"
              name="fourthMonthHealthSitutation"
              defaultDate={modalDefaultValues?.fourthMonthHealthSitutation}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Fifth Month Child weight"
              type="text"
              size="small"
              variant="outlined"
              name="fifthMonthChildWeight"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.fifthMonthChildWeight}
              {...register("fifthMonthChildWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Fifth Month Mother Situtation"
              type="text"
              size="small"
              variant="outlined"
              name="fifthMonthMotherSitutation"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.fifthMonthMotherSitutation}
              {...register("fifthMonthMotherSitutation")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              label="मिति"
              name="fifthMonthHealthSitutation"
              defaultDate={modalDefaultValues?.fifthMonthHealthSitutation}
              onChange={handleDateChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Sixth Month Child weight"
              type="text"
              size="small"
              variant="outlined"
              name="sixthMonthChildWeight"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.sixthMonthChildWeight}
              {...register("sixthMonthChildWeight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Sixth Month Mother Situtation"
              type="text"
              size="small"
              variant="outlined"
              name="sixthMonthMotherSitutation"
              autoComplete="true"
              fullWidth
              defaultDate={modalDefaultValues?.sixthMonthMotherSitutation}
              {...register("sixthMonthMotherSitutation")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              label="मिति"
              name="sixthMonthHealthSitutation"
              defaultDate={modalDefaultValues?.sixthMonthHealthSitutation}
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showStanpaiAamatathaShishuDeleteModal}
        closeDeleteModel={closeStanpaiAamatathaShishuDeleteModal}
        deleteRecord={deleteStanpaiAamatathaShishuRow}
      />
      <StanpaiAamaTathaShishuSewaRegister
        onEditRow={stanpaiAamatathaShishuSewaEdit}
        tableData={stanpaiAamatathaShishuTableData}
        deleteRow={onDeleteStanpaiAamatathaShishuRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default StanpaiAamatathaShishuSewaForm;