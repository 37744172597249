import { Assessment, AssistWalker, BookOnline, Boy, Contacts, Delete, DirectionsWalk, ElderlyWoman, EmergencyShare, Girl, Healing, HolidayVillage, Home, HomeWork, LocalHospital, LocationCity, Medication, Padding, Person, Report, SelfImprovement, Settings, Vaccines, Woman } from "@mui/icons-material";
import { ROLE_AHMIS_ACUPUNCTURESEWA_GETAllACUPUNCTURESEWAID, ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERBYPAGE, ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARAN, ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE, ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION, ROLE_AHMIS_ESTHANAGARIK_SAVEJESTHANAGARIK, ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIBYPAGE, ROLE_AHMIS_JESTHANAGARIK_GETJESTHANAGARIKBYPAGE, ROLE_AHMIS_KSHARSUTRA_GETALLKSHARSUTRA, ROLE_AHMIS_MEDICINE_GETALLMEDICINEDISTRIBUTOR, ROLE_AHMIS_MULDARTA_GETALLMULDARTAREGISTRATIONS, ROLE_AHMIS_OUTPATIENT_GETALLOUTPATIENT, ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE, ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEBYDATERANGEANDPAGE, ROLE_AHMIS_PANCHAKARMA_GETALLPANCHAKARMA, ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYID, ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE, ROLE_AHMIS_THERAPEUTIC_GETALLTHERAPUTICREGISTRATION, ROLE_AHMIS_YOGSEWA_GETYOGSEWABYID } from "../../../utils/constants";

const REPORT_LINKS = [

];

export const SIDEBAR_LINKS = [
  {
    permissions: [ROLE_AHMIS_MULDARTA_GETALLMULDARTAREGISTRATIONS],
    id: 0,
    label: "गृहपृष्ठ",
    link: "/user/dashboard",
    iconComponent: <Home fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_MULDARTA_GETALLMULDARTAREGISTRATIONS],
    id: 1,
    label: "१.१ मूल दर्ता रजिष्टर",
    link: "/user/mul-dartaa",
    iconComponent: <Contacts fontSize="small" />
  },
  {
    permissions: ["ROLE_ADMIN"],
    id: 2,
    label: "Admin Home",
    link: "/admin/dashboard",
    iconComponent: <Home fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_OUTPATIENT_GETALLOUTPATIENT],
    id: 3,
    label: "१.३ बहिरङ्ग सेवा रजिष्टर",
    link: "/user/out-patient",
    iconComponent: <DirectionsWalk fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_MEDICINE_GETALLMEDICINEDISTRIBUTOR],
    id: 4,
    label: "१.५ औषधी वितरण",
    link: "/user/medicine-distribution",
    iconComponent: <Vaccines fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_PANCHAKARMA_GETALLPANCHAKARMA, ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEBYDATERANGEANDPAGE],
    id: 5,
    label: "२.१ पञ्चकर्म सेवा रजिष्टर",
    link: "/user/panchakarma-sewa",
    iconComponent: <Home fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION],
    id: 17,
    label: "शल्य चिकित्सा सेवा रजिष्टर",
    link: "/user/shalyachikitsa",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_KSHARSUTRA_GETALLKSHARSUTRA],
    id: 6,
    label: "२.२ क्षारसूत्र सेवा रजिष्टर",
    link: "/user/ksharsutra-sewa",
    iconComponent: <Boy fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_THERAPEUTIC_GETALLTHERAPUTICREGISTRATION],
    id: 7,
    label: "२.३ उपचारात्मक योग सेवा रजिष्टर",
    link: "/user/therapeutic-yoga-sewa",
    iconComponent: <SelfImprovement fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_JESTHANAGARIK_GETJESTHANAGARIKBYPAGE],
    id: 8,
    label: "२.४ जेष्ठ नागरिक सेवा रजिष्टर",
    link: "/user/jestha-nagarika",
    iconComponent: <ElderlyWoman fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE],
    id: 9,
    label: "२.५ स्तनपायी आमा तथा शिशु रजिष्टर",
    link: "/user/stanpai-sama-tatha-shishu",
    iconComponent: <Girl fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIBYPAGE],
    id: 10,
    label: "३.१ गाउँघर/शहरी क्लिनिक दर्ता रजिष्टर",
    link: "/user/orc-sewa",
    iconComponent: <HolidayVillage fontSize="small" />
  },
  {
    permissions: ["ROLE_USER"],
    id: 11,
    label: "३.२ गाउँघर/शहरी क्लिनिक जेष्ठ नागरिक सेवा रजिष्टर",
    link: "/user/gaughar-clinic-jestha-nagarik",
    iconComponent: <Home fontSize="small" />
  },
  {
    permissions: ["ROLE_USER"],
    id: 12,
    label: "३.४ गाउँघर/शहरी नसर्ने रोग व्यवस्थापन सेवा रजिष्टर",
    link: "/user/gausahar-clinic-nasarne-rog-vyavasthapan",
    iconComponent: <LocationCity fontSize="small" />
  },
  {
    permissions: ["ROLE_USER"],
    id: 13,
    label: "३.५ गाउँघर/शहरी क्लिनिक परामर्श सेवा रजिष्टर",
    link: "/user/gausahar-sahari-clinic-paramarsha-sewa",
    iconComponent: <HomeWork fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERBYPAGE],
    id: 14,
    label: "४.१ Admission Register",
    link: "/user/admission",
    iconComponent: <BookOnline fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE],
    id: 15,
    label: "४.२ Discharge",
    link: "/user/discharge",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARAN],
    id: 16,
    label: "वित्तिय विवरण ",
    link: "/user/bittayabivaran",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION],
    id: 17,
    label: "कर्मचारी विवरण ",
    link: "/user/karmacharibivaran",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION],
    id: 18,
    label: "नसर्ने रोग तथा मानसिक स्वास्थ्य सेवा",
    link: "/user/ncd",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_ACUPUNCTURESEWA_GETAllACUPUNCTURESEWAID],
    id: 19,
    label: "अकुपञ्चर सेवा रजिष्टर",
    link: "/user/acupuncture",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYID],
    id: 20,
    label: "फिजियोथेरापी सेवा रजिष्टर",
    link: "/user/physiotherapy",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_YOGSEWA_GETYOGSEWABYID],
    id: 21,
    label: "योग सेवा रजिष्टर",
    link: "/user/yog-sewa",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    permissions: [ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION],
    id: 21,
    label: "ज्येष्ठ नागरिक स्वास्थ्य सेवा कार्यक्रम रजिष्टर",
    link: "/user/jestha-nagrik-heath-sewa",
    iconComponent: <AssistWalker fontSize="small" />
  },
  {
    id: 17,
    label: "रिपोर्ट",
    permissions: [ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE],
    iconComponent: <Assessment fontSize="small" />,
    children: [
      {
        permissions: ["ROLE_USER"],
        id: 1,
        label: "मासिक प्रगती प्रतिवेदन",
        link: "/user/monthly-progress-report",
        iconComponent: <Report fontSize="small" />
      },
      {
        permissions: [ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE],
        id: 3,
        label: "पञ्चकर्म रिपोर्ट",
        link: "/user/pancha-karma-purba-karma-sewa-report",
        iconComponent: <Home fontSize="small" />
      },
      {
        permissions: [ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE],
        id: 3,
        label: "शल्य चिकित्सा",
        link: "/user/salyachikitsa-report",
        iconComponent: <Home fontSize="small" />
      },
      {
        permissions: [ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE],
        id: 3,
        label: "योग सेवा प्रतिवेदन",
        link: "/user/yog-sewa-report",
        iconComponent: <Home fontSize="small" />
      },
      {
        permissions: [ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE],
        id: 3,
        label: "फिजियोथेरापी प्रतिवेदन",
        link: "/user/physio-therapy-report",
        iconComponent: <Home fontSize="small" />
      },
      {
        permissions: [ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE],
        id: 3,
        label: "अकुपञ्चर सेवा प्रतिवेदन",
        link: "/user/accpunture-therapy-report",
        iconComponent: <Home fontSize="small" />
      },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 2,
      //   label: "Hospital Reports",
      //   link: "/user/hospital-reports",
      //   iconComponent: <Medication fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 4,
      //   label: "PanchakarmaPardanKarma Report",
      //   link: "/user/pancha-karma-pardan-karma-report",
      //   iconComponent: <Home fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 5,
      //   label: "ksharasutraSewa Report",
      //   link: "/user/ksharasutra-sewa-report",
      //   iconComponent: <Home fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 6,
      //   label: "TherapeuticYoga service Report",
      //   link: "/user/therapeutic-yoga-service-report",
      //   iconComponent: <SelfImprovement fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 7,
      //   label: "Jestha Nagrik Report",
      //   link: "/user/jestha-nagrik-report",
      //   iconComponent: <ElderlyWoman fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 8,
      //   label: "Breastfeeding-Maternal Report",
      //   link: "/user/breastfeeding-maternal-and-child-services-report",
      //   iconComponent: <Woman fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 9,
      //   label: "Village Ayurveda Clinic Report",
      //   link: "/user/village-ayurveda-clinic-report",
      //   iconComponent: <HolidayVillage fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 10,
      //   label: "Out Patient Report",
      //   link: "/user/out-patient-report",
      //   iconComponent: <Person fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 11,
      //   label: "Monthly Medicine Dispensing Report",
      //   link: "/user/monthly-medicine-dispensing-report",
      //   iconComponent: <Healing fontSize="small" />
      // },
      // {
      //   permissions: ["ROLE_USER"],
      //   id: 10,
      //   label: "In Patient Morbidity",
      //   link: "/user/in-patient-morbidity",
      //   iconComponent: <Person fontSize="small" />
      // },
    ]
  },
  {
    id: 18,
    label: "प्रणाली सेट-अप",
    permissions: [ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE],
    iconComponent: <Settings fontSize="small" />,
    children: [
      {
        permissions: [ROLE_AHMIS_OUTPATIENT_GETALLOUTPATIENT],
        id: 1,
        label: "Add Medicine",
        link: "/user/add-medicine",
        iconComponent: <LocalHospital fontSize="small" />
      },
      {
        permissions: ["ROLE_USER"],
        id: 2,
        label: "DHIS2 Setting",
        link: "/user/dhis-two-setup",
        iconComponent: <Person fontSize="small" />
      },
      {
        permissions: ["ROLE_USER"],
        id: 3,
        label: "स्वास्थ्यकर्मीको विवरण",
        link: "/user/staff-details",
        iconComponent: <Person fontSize="small" />
      },
    ]
  },
  {
    permissions: [ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE],
    id: 19,
    label: "Deleted Records",
    link: "/user/deleted-records",
    iconComponent: <Delete fontSize="small" />
  }
]