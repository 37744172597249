import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { useUserState } from "../context/userContext";
import { ROLE_ADMIN, ROLE_USER } from "../utils/constants";

export default function RouteWrapper({ component: Component, path: urlPath, isPrivate, isWrongLink, ...rest }) {
  let { userRole } = useUserState();

  if (isPrivate && !userRole) {
    return <Navigate to="/" />;
  } else if (!isPrivate && !isWrongLink && !urlPath.includes("/user-not-authorized") && (userRole === ROLE_USER)) {
    return <Navigate to="/user/dashboard" />;
  } else if (!isPrivate && !isWrongLink && !urlPath.includes("/user-not-authorized") && userRole === ROLE_ADMIN) {
    return <Navigate to="/admin/dashboard" />;
  } else {
    return <Component />;
  }
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isWrongLink: PropTypes.bool
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isWrongLink: false
};