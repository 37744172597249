import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const MedicineStockDetails = () => {

  const options = {
    series: [
      {
        name: "Medicine",
        data: [1000, 3000, 2000, 2500, 4500, 4000, 3000, 3300, 5000, 2200, 1000, 6000, 1200, 4500, 3500, 3000, 1400, 5000, 1300, 5500, 4000, 3300, 1500, 4800, 3300, 1200, 3000]
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    dataLabels: {
      formatter: (val) => {
        return val
      }
    },
    title: {
      text: "जम्मा Stocks मा भयको  औषधी  विवरण ",
      align: "center",
      style: {
        fontSize: "20px",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    xaxis: {
      categories: [
        "अविपतकर चुर्ण ५० ग्राम",
        "हिङग्वाष्टक चूर्ण ५० ग्राम",
        "महायोगराज गुग्गुल १० ग्राम",
        "सितोपलादि चूर्ण ५० ग्राम",
        "पुष्यानुग चूर्ण ५० ग्राम",
        "मृगमदासव १५ एम. एल",
        "सर्पगन्धाघन वटि २५ ग्राम",
        "निम्वादि चूर्ण ५० ग्राम",
        "गन्धक रसायन १० ग्राम",
        "खरिदादि वटी १० ग्राम",
        "षडविन्दु तेल १० एम. एल",
        "योगराज गुग्गुल २५ ग्राम",
        "महासुदर्शन चूर्ण ५० ग्राम",
        "महाशंख वटी चूर्ण १० ग्राम",
        "विल्वादी चूर्ण ५० ग्राम",
        "जात्यादी तेल ६० एम. एल.",
        "कैशोर गुग्गुल २५ ग्राम",
        "चन्द्रप्रभावटी २५ ग्राम",
        "त्रिफला चूर्ण ५० ग्राम",
        "अश्वगन्धा चूर्ण ५० ग्राम",
        "अश्वगन्धा चूर्ण १०० ग्राम",
        "अश्वगन्धा चूर्ण १०० ग्राम",
        "दशमुल तेल ६० एम. एल.",
        "शतावरी चूर्ण १०० ग्राम",
        "ज्वानो १०० ग्राम",
        "ज्वानो १०० ग्राम",
        "अन्य",
      ]
    },
    fill: {
      opacity: 1
    },
    colors: ["#80c7fd", "#008FFB"],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val
        }
      }
    },
    legend: {
      position: "top",
      horizontalAlign: "center"
    }
  };

  return (
    <>
      <Box mt={2}>
        <Card>
          <Chart
            options={options}
            series={options.series}
            type="bar"
            height={350} />;
        </Card>
      </Box>
    </>
  )
};

export default MedicineStockDetails;;