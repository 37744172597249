import { makeStyles } from "@mui/styles";

const styles = makeStyles(theme => ({
  modalHeader:
  {
    display: "flex",
    justifyContent: "space-between"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
export default styles;