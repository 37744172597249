import React from 'react';
import styles from "./style";
import { Box, Button, Container, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function NotAuthorized(props) {
  const navigate = useNavigate();
  const classes = styles();
  return (
    <Container maxWidth="lg" className={classes.root} disableGutters>
      <Box textAlign="center">
        <Box mb={3}>
          <Typography variant="h2">Sorry, You are not authorized to view this page.</Typography>
        </Box>
        <Button
          color="primary"
          onClick={() => {
            navigate("/");
          }}
          className={classes.button}
          startIcon={<ArrowBack />}
        >
          Back To Home
        </Button>
      </Box>
    </Container>
  )
};