import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/modal/CustomModal";
import AddMedicine from "../../users/components/register/add-medicine/AddMedicine";
import { useForm } from "react-hook-form";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AHMIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { Delete } from "@mui/icons-material";
import RegisterHeader from "../../../components/register-header/RegisterHeader";

const AddMedicineForm = () => {
  const [modalTitle, setModalTitle] = useState("औषधि विवरण थप्नुहोस्");
  const [showAddMedicine, setAddMedicine] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [openDeleteConformationModal, setOpenDeleteConformationModal] = useState(false);
  const [getTableData, setGetTableData] = useState(false);
  const [addMedicineDataTable, setAddMedicineDataTable] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [showDeleteAllMedicineConformationModal, setShowDeleteAllMedicineConformationModal] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  const openAddMedicineModal = () => {
    setAddMedicine(true);
  };

  const closeAddMedicineModal = () => {
    setAddMedicine(false);
  };

  const closeDeleteAllMedicineModal = () => {
    setShowDeleteAllMedicineConformationModal(false);
  }

  const submitAddMedicine = (data) => {
    AHMIS.post(API_URL.medicineList, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeAddMedicineModal()
          setGetTableData(true)
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getAddMedicineData = (fromDate, toDate, searchParam, pageFromParam) => {
    let url = `${API_URL.medicineList}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setAddMedicineDataTable(jsondata?.data?.objectList);
        setTotalSize(jsondata?.data?.size)
        setGetTableData(false);
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const deleteRow = () => {
    AHMIS.delete(API_URL.medicineList + "/" + deleteId).then(response => {
      if (response.data.type === SUCCESS) {
        setOpenDeleteConformationModal(false)
        setGetTableData(true);
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const onDeleteAddMedicineRow = (id) => {
    setOpenDeleteConformationModal(true)
    setDeleteId(id)
  }

  const closeDeleteConfirmationModal = () => {
    setOpenDeleteConformationModal(false)
  }

  const deleteAllMedicineRecord = () => {
    AHMIS.delete(API_URL.medicineList)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          closeDeleteAllMedicineModal();
          getTableData();
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam) => {
    setPage(0)
    getAddMedicineData(fromDate, toDate, searchParam, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="औषधि विवरण थप्नुहोस्"
          handleModalOpen={openAddMedicineModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getAddMedicineData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button color="secondary" variant="contained" onClick={() => setShowDeleteAllMedicineConformationModal(true)}><Delete fontSize="small" />Delete All</Button>
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showAddMedicine}
        onModalSubmit={handleSubmit(submitAddMedicine)}
        onModalClose={closeAddMedicineModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md>
            <TextField
              label="Medicine Unit"
              type="text"
              variant="outlined"
              size="small"
              name="medicineUnit"
              fullWidth
              {...register("medicineUnit", { required: true })}
            />
            {errors?.medicineUnit && errors.medicineUnit?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} md>
            <TextField
              label="Medicine Name"
              type="text"
              variant="outlined"
              placeholder="eg: Amoxicillin"
              size="small"
              name="medicineName"
              fullWidth
              {...register("medicineName", { required: true })}
            />
            {errors?.medicineName && errors.medicineName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} md>
            <TextField
              label="Dose"
              type="text"
              variant="outlined"
              placeholder="eg: 500mg"
              size="small"
              name="dose"
              fullWidth
              {...register("dose", { required: true })}
            />
            {errors?.dose && errors.medicineName?.dose === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        title="औषधि हटाउनुहोस्"
        onModalSubmit={() => deleteRow()}
        showModal={openDeleteConformationModal}
        onModalClose={closeDeleteConfirmationModal}
        submitButtonText="Delete"
      >
        <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित विवरण हटाउन चाहनुहुन्छ?</Typography>
        <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
      </CustomModal>
      <AddMedicine
        onDeleteRow={onDeleteAddMedicineRow}
        tableData={addMedicineDataTable}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
      <CustomModal
        title="सम्पूर्ण औषधि विवरण हटाउनुहोस्"
        onModalSubmit={() => deleteAllMedicineRecord()}
        showModal={showDeleteAllMedicineConformationModal}
        onModalClose={closeDeleteAllMedicineModal}
        submitButtonText="Delete"
      >
        <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ सम्पूर्ण औषधि विवरण हटाउन चाहनुहुन्छ?</Typography>
        <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
      </CustomModal>
    </>
  );
};

export default AddMedicineForm;