import React from "react";
import { AppMisc } from "../../../../misc/appMisc";
import { NO_RECORDS_FOUND } from "../../../../utils/constants";
import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Check, Close, Edit } from "@mui/icons-material";

export default function MunicipalityUserList(props) {

  function editRow(id) {
    props.editRow(id);
  }

  return (
    <TableContainer>
      <Table classes={{ root: "table-bordered" }} size="small" aria-label="Municipality Users">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>नाम</TableCell>
            <TableCell>ईमेल</TableCell>
            <TableCell>भूमिका</TableCell>
            <TableCell>प्रदेश</TableCell>
            <TableCell>जिल्ला</TableCell>
            <TableCell>फोन नं</TableCell>
            <TableCell>सक्रिय / निष्क्रिय</TableCell>
            <TableCell>Created Date</TableCell>
            {props?.tableData && props.tableData?.length !== 0 && props.showEditColumn && <TableCell>सम्पादन</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {props?.tableData && props?.tableData?.length > 0 ? (props?.tableData?.map(row => (

            <TableRow key={row.id} >
              <TableCell>{row.fullName}</TableCell>
              <TableCell>{row.userEmail}</TableCell>
              <TableCell>{AppMisc.getUserRole(row.role)}</TableCell>
              <TableCell>{row.province || "-"}</TableCell>
              <TableCell>{row.district || "-"}</TableCell>
              <TableCell>{row.phoneNumber}</TableCell>
              <TableCell>{row.active === false ? (<Chip size="small" color="secondary" icon={<Close />} />) : (<Chip color="primary" size="small" icon={<Check />} />)}</TableCell>
              <TableCell>{row.createdDate && AppMisc.getDateLabelFromMillisecond(row.createdDate)}</TableCell>
              {
                props.showEditColumn && <TableCell>
                  <IconButton onClick={() => editRow(row.id)}>
                    <Edit fontSize="small" />
                  </IconButton>
                </TableCell>
              }
            </TableRow>
          ))) : (
            <TableRow>
              <TableCell colSpan="100%" align="center" size="medium">{NO_RECORDS_FOUND}</TableCell>
            </TableRow>
          )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}