import { ADToBS, BSToAD } from "../AdToBsConverter/AdBsConverter";

export const replaceWithNepaliDigit = (number) => {
  var numbers = {
    0: '०',
    1: '१',
    2: '२',
    3: '३',
    4: '४',
    5: '५',
    6: '६',
    7: '७',
    8: '८',
    9: '९'
  };
  var arrNumNepali = number.toString().split('').map(function (char) {
    if (isNaN(parseInt(char))) {
      return char
    }
    return numbers[Number(char)];
  });
  return arrNumNepali.join('');
}

export const getDateMilliseconds = (date, dateType) => {
  dateType = dateType || "BS";
  if (dateType === "BS") {
    date = BSToAD(date);
  }
  return new Date(date).getTime();
}

export const getDateFromMilliseconds = (dateInMills, dateType, showTime) => {
  showTime = showTime || false;
  dateType = dateType || "BS";

  // initialize new Date object
  var dateObject = new Date(dateInMills);
  // year as 4 digits (YYYY)
  var year = dateObject.getFullYear();
  // month as 2 digits (MM)
  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  // date as 2 digits (DD)
  var date = ("0" + dateObject.getDate()).slice(-2);
  var yyMmDd = `${year}-${month}-${date}`;
  if (dateType !== "AD") {
    yyMmDd = ADToBS(yyMmDd);
  }
  return `${yyMmDd}`
}

export const getSeparatedDateFromBsDate = (BsDate) => {
  let splittedDate = ["-", "-", "-"];
  if (BsDate) {
    splittedDate = BsDate.split("-");
  }
  return {
    day: splittedDate[2],
    month: splittedDate[1],
    year: splittedDate[0]
  }
}

export const getSeparatedDateFromMilliseconds = (dateInMills) => {
  let splittedDate = ["-", "-", "-"];
  if (dateInMills) {
    splittedDate = getDateFromMilliseconds(dateInMills).split("-");
  }
  return {
    day: splittedDate[2],
    month: splittedDate[1],
    year: splittedDate[0]
  }
}