import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const OutPatientReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
        <Typography variant="body1" className="report-heading">७. बहिरंग सेवा विवरण</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Out-Patient-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={1}> क्र.सं. </TableCell>
              <TableCell rowSpan={1}>रोग</TableCell>
              <TableCell rowSpan={1}>महिला</TableCell>
              <TableCell rowSpan={1}>पुरुष</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>Jwar (Fever)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>Swash vikar (Respiratory disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.1</TableCell>
              <TableCell>Swash (Asthm and COAD)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.3</TableCell>
              <TableCell>others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>Amlapitta (APD)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4</TableCell>
              <TableCell>Atisar/Grahani (Diarrhoea/IBS)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4.1</TableCell>
              <TableCell>Atisar (Diarrhoea)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4.2</TableCell>
              <TableCell>Grahani (IBS,IBD,Ulcerative colitis, Crohn's disease, Sprue)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4.3</TableCell>
              <TableCell>Pravahika (Dysnetry)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4.4</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell>Udarroga (Abdominal disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6</TableCell>
              <TableCell>Prameha/Madhumeha</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6.1</TableCell>
              <TableCell>Prameha</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6.2</TableCell>
              <TableCell>Madhumeha (Diabetes mellitus)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>7</TableCell>
              <TableCell>Kamala</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>7.1</TableCell>
              <TableCell>Kosthashrita kamala (Non obstructive jaundice)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>7.2</TableCell>
              <TableCell>Shakhashrita kamala (Obstructive jaundice)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>8</TableCell>
              <TableCell>Pandu (Anaemia)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>9</TableCell>
              <TableCell>Hridaya roga (Cardiac disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>10</TableCell>
              <TableCell>Raktachap (Blood pressure disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>10.1</TableCell>
              <TableCell>Uchharaktachap (Hypertension)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>10.2</TableCell>
              <TableCell>Nyunaraktachap (Hypotension)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>11</TableCell>
              <TableCell>Shotha (Oedema)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>12</TableCell>
              <TableCell>Krimi (Worm infestation)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13</TableCell>
              <TableCell>Twak vikar (Skin disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13.1</TableCell>
              <TableCell>Ekkustha/Kitibha (Psoriasis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13.2</TableCell>
              <TableCell>Pama </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13.3</TableCell>
              <TableCell>Vyanga/Nyachha (Blemishes)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13.4</TableCell>
              <TableCell> Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>14</TableCell>
              <TableCell>Vrana (Wound/ulcer)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15</TableCell>
              <TableCell>Abhighataj vikar (Traumatic disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16</TableCell>
              <TableCell>Vata vyadhi (Skeleto-muscular and nervous system disease)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.1</TableCell>
              <TableCell>Manyastambha (Torticollis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.2</TableCell>
              <TableCell>Avabahuka (Frozen shoulder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.3</TableCell>
              <TableCell>Vishwachi (Cervical spodylosis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.4</TableCell>
              <TableCell>Katishula (Lumbago)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.5</TableCell>
              <TableCell>Gridhasi (Sciatica)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.6</TableCell>
              <TableCell>Sandhivata (Osteoarthritis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.7</TableCell>
              <TableCell>Angamarda (Bodyache)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.8</TableCell>
              <TableCell>Pakshaghat (Paralysis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.9</TableCell>
              <TableCell>Kampavata (Parkinson)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.10</TableCell>
              <TableCell>Mamsagata vata (Muscular dystrophy)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>16.11</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>17</TableCell>
              <TableCell>Amavata (Rheumatoid arthritis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18</TableCell>
              <TableCell>Twak vikar (Skin disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18</TableCell>
              <TableCell>Vatrakta (Gout)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>19</TableCell>
              <TableCell>Raktavikar (Blood disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>20</TableCell>
              <TableCell>Mutravikar (Urinary disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>20.1</TableCell>
              <TableCell>Mutrasmari (Renal calculi)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>20.2</TableCell>
              <TableCell>Mutrakrichhra (Dysuria)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>20.3</TableCell>
              <TableCell>Mutraghat (Retnetion/suppression of urine)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21</TableCell>
              <TableCell>Prasutivikar (Postpartum disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21.1</TableCell>
              <TableCell>Stanya vikar (Breast milk dosorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21.2</TableCell>
              <TableCell>Garbhashayachhyuta (Uterine prolapse)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21.3</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22</TableCell>
              <TableCell>Striroga (Gynaecological disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22.1</TableCell>
              <TableCell>Raktapradar (Menstrual problem)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22.2</TableCell>
              <TableCell>Swetapradar (Leucorrhoea)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22.3</TableCell>
              <TableCell>Kashtartawa (Dysmenorrhoea)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22.4</TableCell>
              <TableCell>Anartawa (Amenorrhoea)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22.5</TableCell>
              <TableCell>Yoniroga (…..)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>22.6</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23</TableCell>
              <TableCell>Gudavikar</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.1</TableCell>
              <TableCell>Arsha (Haemorrhoids)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.2</TableCell>
              <TableCell>Bhagandar (Fistula in ano)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.3</TableCell>
              <TableCell>Pilonidal sinus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.3</TableCell>
              <TableCell>Pilonidal sinus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.3</TableCell>
              <TableCell>Pilonidal sinus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.3</TableCell>
              <TableCell>Pilonidal sinus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.3</TableCell>
              <TableCell>Pilonidal sinus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.3</TableCell>
              <TableCell>Pilonidal sinus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.4</TableCell>
              <TableCell>Perianal wart</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.5</TableCell>
              <TableCell>Parikartika (Fissure)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>23.6</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24</TableCell>
              <TableCell>Netraroga (Ophthamological disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24.1</TableCell>
              <TableCell>Abhisyandi (Conjunctivitis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24.2</TableCell>
              <TableCell>Athimantha (Glaucoma)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24.3</TableCell>
              <TableCell>Timira (Cataract)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24.4</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25</TableCell>
              <TableCell>Karna-nasa-mukha-danta-kantha roga</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25.1</TableCell>
              <TableCell>Karna roga (Disease related to ear)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25.2</TableCell>
              <TableCell>Netraroga (Ophthamological disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24</TableCell>
              <TableCell>Nasa roga (Disease related to nose)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25.3</TableCell>
              <TableCell>Mukha roga (Oral disease)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25.4</TableCell>
              <TableCell>Danta roga(Dental disease)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>25.5</TableCell>
              <TableCell>Kantha roga (Disease related to throat)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>26</TableCell>
              <TableCell>Shiroroga (Disease related to head)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27</TableCell>
              <TableCell>Manasvikar (Psychological disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27.1</TableCell>
              <TableCell>Apasmar (Epilepsy)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27.2</TableCell>
              <TableCell>Unmad (Neurosis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27.3</TableCell>
              <TableCell>Atatwavinesh (Psychosis)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27.4</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>28</TableCell>
              <TableCell>Balroga (Paediatric disorder)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>29</TableCell>
              <TableCell>Jarajanya vikar (Geriatric problem)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30</TableCell>
              <TableCell>Anya/vividha</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30.1</TableCell>
              <TableCell>Granthi/Arbuda roga (Tumor/cancer)</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30.2</TableCell>
              <TableCell>Antashrabi granthi janya roga (Hormonal disorder) </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30.3</TableCell>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>नयाँ जम्मा</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>दोहोरिएको जम्मा</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default OutPatientReport;