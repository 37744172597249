import { LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_FISCAL_YEAR } from "../utils/constants/redux";

const reducer = (settingFiscalYear = null, action) => {
  switch (action.type) {
    case SAVE_FISCAL_YEAR:
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return settingFiscalYear;
  }
}

export default reducer;