
import { Box, Card } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';

const ChartDataByTotalStaffProvience = () => {
  const series = [{
    name: "Male",
    data: [700, 1100, 1500, 1600, 1100, 800, 900]
  }, {
    name: "Female",
    data: [600, 400, 1000, 600, 1100, 300, 600]
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 430
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'center',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    title: {
      text: "प्रदेश प्रकारअनुसार जम्मा  कर्मचारी ",
      align: "center",
      style: {
        fontSize: "15px",
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: ["कोशी प्रदेश", "मधेश प्रदेश", "बाग्मती प्रदेश", "गण्डकी प्रदेश", "लुम्बिनी प्रदेश", "कर्णाली प्रदेश", "सुदूरपश्चिम प्रदेश"],
    },
  };

  return (
    <>
      <Box mt={2}>
        <Card>
          <Chart options={options} series={series} type="bar" height={430} />
        </Card>
      </Box>
    </>
  );
};

export default ChartDataByTotalStaffProvience;
