import { Box, Card } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const TopMedicineName = () => {

  const options = {
    series: [
      {
        name: "Medicine",
        data: [1000, 2000, 1500, 1000, 1400, 1400, 1300, 1200, 1000]
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    dataLabels: {
      formatter: (val) => {
        return val
      }
    },
    title: {
      text: " Out of Stock हुन लागेको  औषधी",
      align: "center",
      style: {
        fontSize: "20px",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    xaxis: {
      categories: [
        "अविपतकर चुर्ण ५० ग्राम",
        "महायोगराज गुग्गुल १० ग्राम",
        "दशमुल तेल ६० एम. एल.",
        "सितोपलादि चूर्ण ५० ग्राम",
        "षडविन्दु तेल १० एम. एल",
        "कैशोर गुग्गुल २५ ग्राम",
        "त्रिफला चूर्ण ५० ग्राम",
        "ज्वानो १०० ग्राम",
        "हिङग्वाष्टक चूर्ण ५० ग्राम",
      ]
    },
    fill: {
      opacity: 1
    },
    colors: ["#80c7fd", "#008FFB"],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val
        }
      }
    },
    legend: {
      position: "top",
      horizontalAlign: "center"
    }
  };

  return (
    <>
      <Box mt={2}>
        <Card>
          <Chart
            options={options}
            series={options.series}
            type="bar"
            height={350} />;
        </Card>
      </Box>
    </>
  )
};

export default TopMedicineName;;