import { makeStyles } from "@mui/styles";

const styles = makeStyles(theme => ({
    reportHeaderContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        },
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    reportHeaderWrapper: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("xs")]: {
            display: "block"
        },
        alignItems: "center",
    },
    dateRangeContainer: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    selectContainer: {
        minWidth: "250px",
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
            minWidth: "320px"
        },
    },
}));
export default styles;