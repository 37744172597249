import { CONST_COLUMNS, REMEMBER_ME, USER_ROLE } from "../utils/constants/index";
import { Cookies } from "../utils/storage/cookies";
import { MUL_DARTA_NUMBERS_LIST } from "./constants/forms";
import { RESET_PAGE_NUMBER } from "./constants/redux";
import { LocalStorage } from "./storage/localStorage";
import { SessionStorage } from "./storage/sessionStorage";
import { BASE_URL, PROD_CONSTANTS } from "../api/api";
import { Box, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

export const AppUtils = {
  getUserRole() {
    return Cookies.readCookie(USER_ROLE);
  },
  removeUserRef() {
    Cookies.deleteCookie(USER_ROLE);
    Cookies.deleteCookie(REMEMBER_ME);
    SessionStorage.clear();
    LocalStorage.clear();
  },
  getUrlParam(key) {
    let pageUrl = window.location.search.substring(1);
    let urlParams = pageUrl.split("&");

    for (let i = 0; i < urlParams.length; i++) {
      let paramName = urlParams[i].split("=");

      if (paramName[0] === key) {
        return paramName[1] === undefined
          ? true
          : decodeURIComponent(paramName[1]);
      }
    }
  },
  replaceWithNepaliDigit(number) {
    var numbers = {
      0: '०',
      1: '१',
      2: '२',
      3: '३',
      4: '४',
      5: '५',
      6: '६',
      7: '७',
      8: '८',
      9: '९'
    };
    var arrNumNepali = number.toString().split('').map(function (char) {
      if (isNaN(parseInt(char))) {
        return char
      }
      return numbers[Number(char)];
    });
    return arrNumNepali.join('');
  },
  storeMulDartaaNumbersInSession(mulDartaaRecord) {
    SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, mulDartaaRecord);
  },
  celsiusToFahrenheit(celsius) {
    if (celsius === "" || celsius === undefined) return null;
    return (Number(celsius) * 1.8 + 32).toFixed(2)
  },
  fahrenheitToCelsius(fahrenheit) {
    if (fahrenheit === "" || fahrenheit === undefined) return null;
    return ((Number(fahrenheit) - 32) / 1.8).toFixed(2)
  },
  feetToMeter(feet) {
    if (feet === "" || feet === undefined) return null;
    return (Number(feet) / 3.281).toFixed(2)
  },
  meterToFeet(meter) {
    if (meter === "" || meter === undefined) return null;
    return (Number(meter) * 3.281).toFixed(2)
  },
  getCustomTableColumns({ columnsIdToBeRemoved = [], extraColumns = [], onEditRow = null } = {}) {
    let columns = [...CONST_COLUMNS, ...extraColumns]
    if (onEditRow) {
      let editColumn =
      {
        id: "Edit",
        name: "सम्पादन",
        cell: (row) => (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={() => onEditRow(row.id)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        )
      }
      columns.push(editColumn)
    }
    if (columnsIdToBeRemoved?.length !== 0) {
      for (let i = 0; i < columnsIdToBeRemoved?.length; i++) {
        columns = columns.filter((value) => value.id !== columnsIdToBeRemoved[i]);
      }
    }
    return columns;
  },
  resetPageNumber(setPage, dispatch) {
    setPage(0);
    dispatch({ type: RESET_PAGE_NUMBER })
  },
  getGoogleOAuthClientID() {
    if (BASE_URL === PROD_CONSTANTS.BASE_URL) {
      return process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID_PROD;
    }
    return process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID_DEV;
  }
};