import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import React from "react"

const AyurvedicTherapy = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}>
       <Box mt={4}>
       <Typography variant="body1" className="report-heading">B. Inpatient Morbidity (No. of Patients Discharged)</Typography>
       </Box>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Ayurvedic-Therapy">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}>Ayurvedic Therapy</TableCell>
              <TableCell colSpan={2}>Number of Therapies</TableCell>
              <TableCell rowSpan={2}>Complication</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Female</TableCell>
              <TableCell>Male</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>Panchakarma</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ksharsutra</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Therapeutic Yoga</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Others</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AyurvedicTherapy;