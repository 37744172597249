import { useForm } from "react-hook-form";
import { REQUIRED_FIELD } from "../../utils/constants";
import CustomModal from "../modal/CustomModal";
import { Grid, TextField } from "@mui/material";

export default function DeleteRegisterRecordConformation({ deleteRecord, ...props }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <>
      <CustomModal
        title="रेकर्ड हटाउनुहोस्।"
        onModalSubmit={handleSubmit(deleteRecord)}
        showModal={props.showDeleteModal}
        onModalClose={props.closeDeleteModel}
      >
        <Grid container spacing={2}>
          <TextField
            label="रेकर्ड हटाउनुको कारण"
            size="small"
            type="text"
            variant="outlined"
            name="deletedReason"
            fullWidth
            {...register("deletedReason", { required: true })}
          />
          {errors.deletedReason && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
      </CustomModal>
    </>
  )
}