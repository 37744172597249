import React, { useEffect, useState } from "react";
import AddUserModal from "../../../components/add-user/AddUserModal";
import AddAlertMessage from "../../../components/alert/Alert";
import { AppUtils } from "../../../utils/appUtils";
import { ERROR, ID, MUNICIPALITY_ROLES, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddHealthClient from "../components/add-health-client/AddHealthClient";
import UserList from "../components/user-list/UserList";
import HealthClientList from "./helpers/HealthClientList";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import AHMIS, { API_URL } from "../../../api/api";
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";

export default function MunicipalityHome(props) {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [healthClientTableData, setHealthClientTableData] = useState([]);
  const [userTableData, setUserTableData] = useState([]);
  const [openAddHealthClientModal, setOpenAddHealthClientModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [healthClientModalDefaultValues, setHealthClientModalDefaultValues] = useState();

  const handleAddHealthClientModalClose = () => {
    setHealthClientModalDefaultValues();
    setOpenAddHealthClientModal(false);
  }

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
    setModalDefaultValues({});
  }

  const handleAddUserModalSubmit = data => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      data.id = modalDefaultValues.id;
      data.palikaId = palikaId;
      data.email = data?.email?.toLowerCase();
      AHMIS.post(API_URL.user, data).then(response => {
        if (response?.data.type === SUCCESS) {
          setUserTableData(response.data.objectList);
          handleAddUserModalClose();
          getMunicipalityUserData();
        }
        AddAlertMessage({ type: response?.data?.type, message: response?.data.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      });
    }
  }

  const handleRowEdit = id => {
    AHMIS.get(API_URL.user + "/" + id).then(response => {
      setModalDefaultValues(response?.data.data);
      setOpenAddUserModal(true);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleHealthClientRowEdit = id => {
    AHMIS.get(API_URL.healthClient + "/" + id).then(response => {
      setHealthClientModalDefaultValues(response?.data);
      setOpenAddHealthClientModal(true);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  useEffect(() => {
    getMunicipalityUserData();
    getMunicipalityHealthClientData();
  }, []);

  const onHealthClientModalSubmit = data => {
    let palikaId = AppUtils.getUrlParam(ID);
    data.email = data?.email?.toLowerCase().trim();
    if (palikaId) {
      data.id = healthClientModalDefaultValues?.id;
      data.palikaId = palikaId;
      AHMIS.post(API_URL.healthClient, data).then(response => {
        if (response?.data.type === SUCCESS) {
          setHealthClientTableData(response?.data.objectList);
          handleAddHealthClientModalClose();
          getMunicipalityHealthClientData()
        }
        AddAlertMessage({ type: response?.data.type, message: response?.data.message });
      });
    }
  }

  const getMunicipalityUserData = () => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      AHMIS.get(API_URL.municipalityUsers + "/" + palikaId).then(response => {
        setUserTableData(response?.data.objectList);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
    }
  };

  const getMunicipalityHealthClientData = () => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      AHMIS.get(API_URL.municipalityHealthClients + "/" + palikaId).then(response => {
        setHealthClientTableData(response?.data);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
    }
  };

  return (
    <>
      <Box borderBottom={1}>
        <BreadCrumbs />
      </Box>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1} mt={2}>
          <Typography variant="h5">स्वास्थ्य व्यवस्थापन सूचना प्रणाली - सबै स्वास्थ्य संस्थाहरु</Typography>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddHealthClientModal(true) }}>नयाँ स्वास्थ्य संस्था थप्नुहोस्</Button>
        </Box>
        <Box mb={2}>
          <HealthClientList tableData={healthClientTableData} editRow={handleHealthClientRowEdit} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
          <Typography variant="h5">प्रयोगकर्ताहरू</Typography>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddUserModal(true) }}>नयाँ प्रयोगकर्ता थप्नुहोस्</Button>
        </Box>
        <Box>
          <UserList tableData={userTableData} showEditColumn editRow={handleRowEdit} />
        </Box>
        <AddHealthClient showAddHealthClientModal={openAddHealthClientModal} handleAddHealthClientModalClose={handleAddHealthClientModalClose} onHealthClientModalSubmit={onHealthClientModalSubmit} defaultValues={healthClientModalDefaultValues} />
        <AddUserModal userRoles={MUNICIPALITY_ROLES} showAddUserModal={openAddUserModal} handleAddUserModalClose={handleAddUserModalClose} onSubmit={handleAddUserModalSubmit.bind(this)} defaultValues={modalDefaultValues} />
      </Box>
    </>
  )
}